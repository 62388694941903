import React from 'react';
import { Day, Registry } from 'src/generated/graphql';
import { space100 } from '@ingka/variables/design-tokens';
import { LoadingBall } from '@ingka/loading';
import { useIntl, useT } from 'src/hooks/intl';
import { useHomeDelivery } from 'src/hooks/useHomeDelivery';
import {
  ActionList,
  ActionListItem,
  Checkbox,
  ChoiceItem,
  Loading,
} from 'src/skapa';
import { availableCountryCodeMap } from 'src/utils/countryCodes';
import { sortDaysByWeek } from 'src/utils/dates';
import styled from 'styled-components';
import { Errors } from '../ErrorMessage';
import { Body2 } from '../Typography';
import { Container } from './../styles';
import { DisableHomeDeliveryPrompt } from './DisableHomeDeliveryPrompt';

interface HomeDeliveryProps {
  registry: Registry;
}

const DetailsContainer = styled(Container)`
  padding: ${(props) => props.theme.space150} ${(props) => props.theme.space125};
  margin-bottom: ${(props) => props.theme.space250};
  border: 1px solid ${(props) => props.theme.colourNeutral3};
  border-radius: ${(props) => props.theme.radiusM};
  gap: ${(props) => props.theme.space150};
`;

const DayChoiseItem = styled(ChoiceItem)`
  min-height: ${(props) => props.theme.space125};
  button {
    padding: 0;
    justify-content: center;
  }
  span.igift-admin-choice-item__content {
    flex: 0 1 auto;
    text-align: center;
  }
  span.igift-admin-choice-item__title {
    font-size: ${(props) => props.theme.fontSize50};
  }
`;

const StyledActionList = styled(ActionList)`
  padding-left: 0;
  margin-top: ${(props) => props.theme.space100};
  margin-bottom: ${(props) => props.theme.space250};
`;

export const HomeDelivery: React.FC<HomeDeliveryProps> = ({
  registry,
}: HomeDeliveryProps) => {
  const {
    weekDays,
    selectedOrDisabled,
    checkboxes,
    capitalizeString,
    handleConfirmDisableClick,
    payload,
    loading,
    disabledLoading,
    errorsProps,
    showDisablePrompt,
    setShowDisablePrompt,
  } = useHomeDelivery(registry);

  const t = useT();
  const { country } = useIntl();

  return (
    <>
      <Errors {...errorsProps} />
      {loading && (
        <Loading>
          <LoadingBall />
        </Loading>
      )}
      {!loading && payload?.homeDelivery && (
        <>
          <StyledActionList id="gift-delivery">
            <ActionListItem
              label={t('registry.settings.giftDelivery')}
              caption={t(
                `registry.settings.tableHeader2.${
                  payload?.homeDelivery?.enabled ? 'zero' : 'one'
                }`
              )}
              control="switch"
              onChange={() => {
                setShowDisablePrompt(true);
              }}
              disabled={!payload?.homeDelivery?.enabled}
              controlProps={{
                subtle: true,
                checked: !!payload?.homeDelivery?.enabled,
                value: t(
                  `registry.settings.tableHeader2.${
                    payload?.homeDelivery?.enabled ? 'zero' : 'one'
                  }`
                ),
              }}
            />
          </StyledActionList>
          {payload?.homeDelivery?.deliveryAddress ? (
            <DetailsContainer display="flex" flexDirection="column">
              <div>
                <Body2>
                  {capitalizeString(
                    payload?.homeDelivery?.deliveryAddress.streetAddress1
                  )}
                </Body2>
                <Body2>
                  {capitalizeString(
                    payload?.homeDelivery?.deliveryAddress.city
                  )}
                  ,{' '}
                  {payload?.homeDelivery?.deliveryAddress.postalCode.toLocaleUpperCase()}
                </Body2>
                <Body2>
                  {availableCountryCodeMap[country]}{' '}
                  {payload?.homeDelivery?.deliveryAddress.mobileNumber}
                </Body2>
              </div>

              {payload?.homeDelivery?.slots.length > 0 && (
                <>
                  <Container
                    justifyContent="space-between"
                    display="flex"
                    style={{ gap: space100 }}
                  >
                    {sortDaysByWeek(weekDays, country.toLowerCase()).map(
                      (day) => {
                        return (
                          <DayChoiseItem
                            key={day}
                            id={day}
                            title={t(
                              `dayOfWeek.${day.slice(0, 2).toLowerCase()}`
                            )}
                            {...selectedOrDisabled(
                              payload?.homeDelivery?.slots[0]
                                .daysOfWeek as Day[],
                              day
                            )}
                          />
                        );
                      }
                    )}
                  </Container>
                  <Container
                    display="flex"
                    flexDirection="column"
                    style={{ gap: space100 }}
                  >
                    {checkboxes.map((checkbox, i) => {
                      return (
                        <Checkbox
                          key={i}
                          {...checkbox}
                          checked={payload?.homeDelivery?.slots[0].timesOfDay.includes(
                            checkbox.value
                          )}
                        />
                      );
                    })}
                  </Container>
                </>
              )}
            </DetailsContainer>
          ) : (
            <Body2>{t('registry.settings.noHomeDelivery')}</Body2>
          )}
        </>
      )}
      <DisableHomeDeliveryPrompt
        showDisablePrompt={showDisablePrompt}
        setShowDisablePrompt={setShowDisablePrompt}
        handleConfirmDisableClick={handleConfirmDisableClick}
        loading={loading}
        disabledLoading={disabledLoading}
      />
    </>
  );
};
