import { useTranslator } from '@eo-locale/react';
import { useCallback } from 'react';
import { hasBalancedInterpolation } from 'src/components/Search/utils';
import { useIntl } from './IntlContext';

export const useT = (): ReturnType<typeof useTranslator>['translate'] => {
  const t = useTranslator();
  const { showKeys } = useIntl();

  return useCallback(
    (translationId, ...args) => {
      const message = String(t.getMessageById(translationId));
      if (args.length && !hasBalancedInterpolation(message)) {
        console.error('Improperly formatted Phrase key', {
          translationId,
          message,
        });
        return translationId;
      }
      return showKeys
        ? `[${translationId}]`
        : t.translate(translationId, ...args);
    },
    [t, showKeys]
  );
};
