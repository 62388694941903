import React from 'react';
import { useT } from 'src/hooks/intl';
import { FullPageError } from 'src/components/HandleErrors/FullPageError';

const NotFoundPage: React.FC = () => {
  const t = useT();
  return (
    <FullPageError
      title={t('error.generic.title')}
      description={t('error.pageNotFound.description')}
    />
  );
};

export default NotFoundPage;
