import { useApolloClient } from '@apollo/client';
import { useIsAuthenticated } from '@azure/msal-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RETAIL_UNIT_KEY, getBrowserCountry, useIntl } from '../intl';
import { useLocalStorage } from '../useLocalStorage';
import { useMsalAccount } from './AuthProvider';
import { hasRoles, Role } from './Role';
import { UserContext } from './UserContext';

interface TokenClaims {
  roles?: Role[];
  exp: number;
  ctry: string;
  [key: string]: unknown;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const extractPayload = ({ roles = [], ctry }: TokenClaims) => {
  const markets = roles
    .filter((role) => role.startsWith('market:'))
    .map((marketRole: string) => marketRole.split(':')[1]);

  return {
    roles,
    markets,
    defaultCountry: ctry,
  };
};

interface IUserProviderProps {
  children: React.ReactNode;
}

export const UserProvider: React.FC<IUserProviderProps> = ({
  children,
}: IUserProviderProps) => {
  const client = useApolloClient();
  const account = useMsalAccount();
  const { setLocale } = useIntl();
  const isAuthenticated = useIsAuthenticated();
  const [retailUnit, setRetailUnit] = useState('');
  const [savedLocale] = useLocalStorage(RETAIL_UNIT_KEY, '');
  const [didRestore, setDidRestore] = useState<boolean>(false);

  // Get user data and expiration from token
  const state = useMemo(() => {
    if (!account) {
      return { markets: [], roles: [], defaultCountry: '' };
    }

    return extractPayload(account.idTokenClaims as TokenClaims);
  }, [account]);

  const changeRetailUnit = useCallback(
    async (retailUnitIn: string, language?: string) => {
      if (!retailUnitIn) {
        setRetailUnit('');
        await client.resetStore();
        return;
      }

      const retailUnit = retailUnitIn.toLowerCase();

      if (!hasRoles([`market:${retailUnit}` as Role], state.roles)) {
        throw new Error(
          `This user doesn't have access to this retailUnit: ${retailUnit}`
        );
      }

      await setLocale(retailUnit, language);
      setRetailUnit(retailUnit);
      await client.resetStore();
    },
    [client, setLocale, state.roles]
  );

  const hasAccess = (desiredMarket: string, availableRoles: Role[]) =>
    desiredMarket &&
    hasRoles([`market:${desiredMarket.toLowerCase()}` as Role], availableRoles);

  useEffect(() => {
    if (!didRestore && isAuthenticated) {
      const [language, country] = savedLocale.split('-');
      const browserCountry = getBrowserCountry();
      if (hasAccess(country, state.roles)) {
        // 1. Get market from local storage.
        changeRetailUnit(country, language);
      } else if (hasAccess(state.defaultCountry, state.roles)) {
        // 2. Get market from token response.
        changeRetailUnit(state.defaultCountry);
      } else if (hasAccess(browserCountry, state.roles)) {
        // 3. Get market from user's browser (derived from "Accept-Language" HTTP header).
        changeRetailUnit(browserCountry);
      } else {
        // 4. Unable to determine preferred market, pick any available one the user has access to.
        const randomMarket =
          state.markets[Math.floor(Math.random() * state.markets.length)];
        changeRetailUnit(randomMarket);
      }

      setDidRestore(true);
    }
  }, [
    didRestore,
    isAuthenticated,
    state,
    setLocale,
    savedLocale,
    changeRetailUnit,
  ]);

  return (
    <UserContext.Provider
      value={{
        ...state,
        name: account?.name || '',
        retailUnit,
        isAuthenticated,
        changeRetailUnit,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
