import React, { useState } from 'react';
import { Button } from 'src/skapa';
import { SEO } from 'src/components/SEO';
import styled from 'styled-components';
import { Crumbler } from '../components/Crumbler';
import { Content } from 'src/components/styles';
import { Heading } from 'src/components/Typography';
import { Body1 } from 'src/components/Typography';
import { SubPage } from 'src/components/SubPage';
import { DeletePopup } from 'src/components/Popups';
import { useIntl, useT } from 'src/hooks/intl';
import { Role } from 'src/hooks/auth';
import { RegistryTabs } from 'src/components/RegistryTabs';
import { useRegistry } from 'src/hooks/RegistryProvider';
import { cmpOrigin } from 'src/utils/settings';
import { Errors } from 'src/components/ErrorMessage';
import RoleTemplate from 'src/hooks/auth/RoleTemplate';
import incorrect from '@ingka/ssr-icon/paths/incorrect';
import trashCan from '@ingka/ssr-icon/paths/trash-can';
import linkOut from '@ingka/ssr-icon/paths/link-out';
import iconPeople from '@ingka/ssr-icon/paths/people';
import iconBox from '@ingka/ssr-icon/paths/box';
import { CoRegistrantsModal } from 'src/components/CoRegistrantsModal';
import { GiftDeliveryModal } from 'src/components/GiftDeliveryModal';
import { useMarketConfigQuery, Feature } from 'src/generated/graphql';
import { useBreakpoint } from '@ingka/viewport-hooks';
import { RegistryOverview } from 'src/components/RegistryOverview';

const RegistryPage: React.FC = () => {
  const t = useT();
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const { data } = useMarketConfigQuery();
  const features = data?.marketConfig.marketConfig.features;
  const isMobile = useBreakpoint('sm', 'md') === 'sm';
  const [coRegistrantsModalOpen, setCoRegistrantsModalOpen] =
    useState<boolean>(false);
  const [giftDeliveryModalOpen, setGiftDeliveryModalOpen] =
    useState<boolean>(false);
  const { registry, suspend, reOpen, loading, errorsProps } = useRegistry();
  const { country, language } = useIntl();

  const goToRegistry = () => {
    const url = `${cmpOrigin}/${country.toLowerCase()}/${language}/gift-registry/guest/?id=${
      registry?.shareId
    }`;

    if (window) {
      window.open(url, '_blank');
    }
  };

  return (
    <RoleTemplate approvedRoles={[Role.RegistryRead]} showErrorPage>
      <SubPage>
        <Body1>
          <SEO title={t('registries.title')} />
          <Crumbler crumbs={['/registries', '/registry']} />
          <Content>
            <Errors {...errorsProps} />
            <StyledHeading component="h1" variant="h1">
              {registry?.title || t('registries.defaultRegistryTitle')}
            </StyledHeading>
            <StyledHeading
              color="colourTextAndIcon2"
              component="h2"
              variant="h2"
            >
              {t(`event.${registry?.eventType || 'OTHER'}.type`)}
            </StyledHeading>
            {registry && <RegistryOverview registry={registry} />}

            <Buttons>
              <Button
                loading={loading}
                disabled={!registry?.shared || registry.suspended}
                ssrIcon={linkOut}
                small
                text={t('registry.buttons.view')}
                type="tertiary"
                onClick={goToRegistry}
                iconOnly={isMobile}
              />
              <RoleTemplate approvedRoles={[Role.RegistryWrite]}>
                <Button
                  loading={loading}
                  ssrIcon={iconPeople}
                  small
                  text={t('registry.settings.coRegistrants')}
                  type="tertiary"
                  onClick={() => setCoRegistrantsModalOpen(true)}
                  iconOnly={isMobile}
                />
                <Button
                  loading={loading}
                  ssrIcon={iconBox}
                  small
                  text={t('registry.settings.giftDelivery')}
                  type="tertiary"
                  onClick={() => setGiftDeliveryModalOpen(true)}
                  disabled={!features?.includes(Feature.ReceiverDelivery)}
                  iconOnly={isMobile}
                />
              </RoleTemplate>
              {registry?.suspended ? (
                <Button
                  loading={loading}
                  ssrIcon={incorrect}
                  small
                  text={t('registry.buttons.reopen')}
                  type="tertiary"
                  onClick={reOpen}
                  iconOnly={isMobile}
                />
              ) : (
                <RoleTemplate approvedRoles={[Role.RegistryWrite]}>
                  <Button
                    loading={loading}
                    ssrIcon={incorrect}
                    small
                    text={t('registry.buttons.suspend')}
                    type="tertiary"
                    onClick={suspend}
                    iconOnly={isMobile}
                  />
                </RoleTemplate>
              )}

              <RoleTemplate approvedRoles={[Role.RegistryWrite]}>
                <Button
                  loading={loading}
                  ssrIcon={trashCan}
                  small
                  text={t('registry.buttons.delete')}
                  type="tertiary"
                  onClick={() => setDeleteOpen(!deleteOpen)}
                  iconOnly={isMobile}
                />
              </RoleTemplate>
            </Buttons>
            <RegistryTabs registry={registry} />
          </Content>
        </Body1>
      </SubPage>
      <DeletePopup
        open={deleteOpen}
        registryId={registry?.id || ''}
        registryOwner={registry?.owner || ''}
        registryTitle={registry?.title || ''}
        setOpen={setDeleteOpen}
      />
      <CoRegistrantsModal
        open={coRegistrantsModalOpen}
        setOpen={setCoRegistrantsModalOpen}
        registry={registry}
      />
      <GiftDeliveryModal
        open={giftDeliveryModalOpen}
        setOpen={setGiftDeliveryModalOpen}
        registry={registry}
      />
    </RoleTemplate>
  );
};

export default RegistryPage;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0;
  margin: ${(props) => props.theme.space250} 0;
  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    justify-content: center;
    gap: ${(props) => props.theme.space400};
  }
`;

const StyledHeading = styled(Heading)`
  width: 100%;
  text-align: center;
`;
