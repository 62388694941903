function isDateFormatValid(date: string): boolean {
  return /^[12]\d{3}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(date);
}

export function isDateValid(date: string): boolean {
  if (!isDateFormatValid(date)) {
    return false;
  }
  const dateObj = new Date(date);

  if (isNaN(dateObj.getTime())) {
    return false;
  }
  return true;
}
export function isFamilyNrValid(familyNr: string): boolean {
  //Should have 19 digits and only numbers
  return /^\d{19}$/.test(familyNr);
}

// Detects an improperly formatted interpolation key.
// Prevents infinite loop with eo-locale.translate.
export function hasBalancedInterpolation(phraseKey: string): boolean {
  const LEFT = '{';
  const RIGHT = '}';
  const stack = [];

  for (const char of phraseKey) {
    if (char === LEFT) {
      stack.push(RIGHT);
    } else if (char === RIGHT && char !== stack.pop()) {
      return false;
    }
  }
  return stack.length === 0;
}
