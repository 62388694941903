import React from 'react';

import styled from 'styled-components';
import { useT } from 'src/hooks/intl';

type ItemGiftedLabelProps = {
  totalQuantity: number;
};

const ProductLabel = styled.div`
  display: flex;
  gap: ${(props) => props.theme.space50};
  align-items: center;
  position: absolute;
  z-index: 1;
  min-height: ${(props) => props.theme.space200};
  padding: ${(props) =>
    `${props.theme.space25} ${props.theme.space50} ${props.theme.space25} ${props.theme.space50}`};
  font-weight: 700;
  font-size: ${(props) => props.theme.fontSize50};
  line-height: ${(props) => props.theme.lineHeight1400Short};
  background-color: #97ec85;
  color: ${(props) => props.theme.colourTextAndIcon1};
`;

export const ItemGiftedLabel: React.FC<ItemGiftedLabelProps> = (
  props: ItemGiftedLabelProps
) => {
  const t = useT();
  const { totalQuantity } = props;

  return (
    <ProductLabel>
      <span>{t('productCard.purchased', { count: totalQuantity })}</span>
    </ProductLabel>
  );
};
export default ItemGiftedLabel;
