import React from 'react';
import { useHistory } from 'react-router-dom';
import { Hyperlink, Breadcrumb } from 'src/skapa';
import { useT } from 'src/hooks/intl';
import styled from 'styled-components';

type Page = '/registries' | '/registry' | '/log' | '/audit-log' | '/404';

const translationKeys: {
  [route in Page]: string;
} = {
  '/registries': 'crumbs.registries',
  '/registry': 'crumbs.registry',
  '/audit-log': 'crumbs.log',
  '/log': 'crumbs.log',
  '/404': 'crumbs.notFound',
};

type DynamicCrumb = {
  page: Page;
  search?: Record<string, string>;
  text?: string;
};

const StyledHyperlink = styled(Hyperlink)`
  position: relative;
`;

export type Crumb = DynamicCrumb | Page;

const getDef = (
  crumb: Crumb,
  t: ReturnType<typeof useT>
): { text: string; page: string; search: string } => {
  if (typeof crumb === 'string') {
    const textId = translationKeys[crumb];
    return {
      page: crumb,
      text: textId ? t(textId) : 'ERR',
      search: '',
    };
  }

  return {
    page: crumb.page,
    search: new URLSearchParams(crumb.search).toString(),
    text: crumb.text || t(translationKeys[crumb.page]),
  };
};

interface CrumblerProps {
  crumbs: Crumb[];
}

export const Crumbler: React.FC<CrumblerProps> = ({
  crumbs,
}: CrumblerProps) => {
  const { push } = useHistory();
  const t = useT();
  const handleOnClick =
    (page: string, search?: string): React.MouseEventHandler =>
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      event.stopPropagation();
      push(`${page}${search ? `?${search}` : ''}`);
    };
  return (
    <Breadcrumb>
      {crumbs.map((crumb) => {
        const { page, text, search } = getDef(crumb, t);

        return (
          <StyledHyperlink
            key={page}
            text={text}
            button
            onClick={handleOnClick(page, search)}
          />
        );
      })}
    </Breadcrumb>
  );
};
