import React, { useState } from 'react';
import { useT } from 'src/hooks/intl';
import styled from 'styled-components';
import {
  Button,
  Modal,
  ModalHeader,
  Sheets,
  Toast,
  Loading,
  ModalFooter,
} from 'src/skapa';
import { useErrors, Errors } from './ErrorMessage';
import { useCustomerDataLazyQuery } from 'src/generated/graphql';
import { splitPayload } from 'src/utils/handleUnions';
import { LoadingBall } from '@ingka/loading';
import copy from '@ingka/ssr-icon/paths/copy';

interface ILanguagesModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  partyUId: string;
}

export const IRRModal: React.FC<ILanguagesModalProps> = ({
  setOpen,
  open,
  partyUId,
}: ILanguagesModalProps) => {
  const t = useT();
  const [IRRText, setIRRText] = useState<string>('');
  const [showIRRToast, setShowIRRToast] = useState<boolean>(false);
  const defaultToastKey = 'copied.success';
  const [toastKey, setToastKey] = useState<string>(defaultToastKey);
  const [customerData, { data, loading }] = useCustomerDataLazyQuery({
    notifyOnNetworkStatusChange: true,
  });
  const { addError, errorsProps } = useErrors();

  React.useEffect(() => {
    if (loading) {
      return;
    }
    const [success, partialError] = splitPayload(
      'CustomerDataPayload',
      data?.customerData
    );

    if (success?.text) {
      setIRRText(success?.text);
    }
    if (partialError) {
      addError(t(`error.generic.${partialError}`));
    }
  }, [data, t, loading, addError]);

  const copyIRRText = async () => {
    try {
      await navigator.clipboard.writeText(IRRText);
      setToastKey(defaultToastKey);
      setShowIRRToast(true);
    } catch (err) {
      setToastKey('copied.error');
      setShowIRRToast(true);
      addError(t(`error.generic.${err}`));
    }
  };

  React.useEffect(() => {
    customerData({
      variables: {
        customerDataInput: {
          partyUId,
        },
      },
    });
  }, [customerData, open, partyUId]);

  return (
    <Modal
      visible={open}
      escapable={true}
      handleCloseBtn={() => setOpen(false)}
    >
      <Sheets
        footer={
          <ModalFooter>
            <Button
              loading={loading}
              ssrIcon={copy}
              text={t('copy')}
              type="primary"
              iconPosition="trailing"
              onClick={copyIRRText}
            />
          </ModalFooter>
        }
        header={<ModalHeader />}
      >
        <>
          <Toast
            isOpen={showIRRToast}
            text={t(toastKey)}
            onCloseRequest={() => setShowIRRToast(false)}
          />
          <Errors {...errorsProps} />
          {loading ? (
            <Loading>
              <LoadingBall />
            </Loading>
          ) : (
            <TextContainer>
              <div
                className="inner"
                dangerouslySetInnerHTML={{
                  __html: IRRText.replace(/\n/g, '<br />'),
                }}
              ></div>
            </TextContainer>
          )}
        </>
      </Sheets>
    </Modal>
  );
};

const TextContainer = styled.div`
  border-radius: ${(props) => props.theme.radiusM};
  color: ${(props) => props.theme.colourTextAndIcon5};
  overflow: hidden;
  background-color: ${(props) => props.theme.colourNeutral7};
  padding: ${(props) => props.theme.space150};
  height: fit-content;
  .inner {
    display: -webkit-box;
    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: pre-wrap;
  }
`;
