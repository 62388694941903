import React, { useState } from 'react';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  FieldPolicy,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { apiOrigin } from 'src/utils/settings';
import { setContext } from '@apollo/client/link/context';
import { GraphQLErrorState, useGraphQLErrors } from './GraphQLErrorsProvider';
import { TypedTypePolicies } from 'src/generated/helpers';
import introspectionResult from 'src/generated/possible-types';
import { acquireToken } from './auth';
import { getPreferredLocale } from './intl';
import { GraphQLError } from 'graphql/error/GraphQLError';

export const dateTimeTypePolicy: FieldPolicy<Date, string> = {
  merge: (_, incoming) => {
    if (incoming === null || incoming === undefined) {
      // It's important for these methods to return null if passed null
      return incoming;
    } else {
      return new Date(incoming as string | Date);
    }
  },
};
const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {
      registryLog: {
        keyArgs: (r) => r?.input.registryId,

        merge(existing, incoming, { args }) {
          if (!existing || !args?.input.nextPageCursor) {
            return incoming;
          }
          return {
            ...existing,
            ...incoming,
            events: [...existing.events, ...incoming.events],
          };
        },
      },
      registrySearch: {
        keyArgs: (r) => {
          const { nextPageCursor, ...rest } = r?.input;

          return JSON.stringify({
            ...r,
            input: rest,
          });
        },

        merge(existing, incoming, { args }) {
          if (!existing || !args?.input.nextPageCursor) {
            return incoming;
          }
          return {
            ...existing,
            ...incoming,
            items: [...existing.items, ...incoming.items],
          };
        },
      },
    },
  },
  GiftBag: {
    // A user only has one gift bag per registry
    keyFields: ['id'],
    fields: {
      purchaseDate: dateTimeTypePolicy,
    },
  },
  Registry: {
    // A user only has one gift bag per registry
    keyFields: ['id'],
    fields: {
      eventDate: dateTimeTypePolicy,
      updated: dateTimeTypePolicy,
      suspensionDate: dateTimeTypePolicy,
    },
  },
};

const cache = new InMemoryCache({
  typePolicies,
  possibleTypes: introspectionResult.possibleTypes,
});

const httpLink = createHttpLink({
  uri: apiOrigin,
});

const errorLink = (errors?: GraphQLErrorState) => {
  return onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      const formattedErrors = graphQLErrors as GraphQLError[];
      errors?.setGqlErrors &&
        errors?.setGqlErrors([...errors.gqlErrors, ...formattedErrors]);
    }

    if (networkError) {
      errors?.setErrors && errors?.setErrors([...errors.errors, networkError]);
    }
  });
};

const authLink = () =>
  setContext(async (_, { headers }) => {
    const tokens = await acquireToken();
    const locale = await getPreferredLocale();

    return {
      headers: {
        ...headers,
        authorization: `Bearer ${tokens.idToken}`,
        'Preferred-Locale': locale,
      },
    };
  });

const createApolloClient = (errors?: GraphQLErrorState) => {
  const error = errorLink(errors);
  const auth = authLink();

  const newClient = new ApolloClient({
    link: ApolloLink.from([auth, error, httpLink]),
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
      },
      query: {
        fetchPolicy: 'network-only',
      },
    },
  });

  return newClient;
};

interface IClientProviderProps {
  children: React.ReactNode;
}

export const ClientProvider: React.FC<IClientProviderProps> = ({
  children,
}: IClientProviderProps) => {
  const errors = useGraphQLErrors();
  const [client] = useState(() => createApolloClient(errors));

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
