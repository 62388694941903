import React from 'react';
import { useHistory } from 'react-router';
import { useT } from 'src/hooks/intl';
import { Button } from 'src/skapa';
import { FullPageError } from './FullPageError';

export const RegistryNotFound: React.FC = () => {
  const t = useT();
  const { push } = useHistory();
  return (
    <FullPageError
      title={t('error.generic.title')}
      description={t('error.registryNotFound.description')}
    >
      <Button
        type="primary"
        onClick={() => push('/registries')}
        text={t('error.registryNotFound.button')}
      />
    </FullPageError>
  );
};
