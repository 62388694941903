import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import RegistriesPage from './pages/registries';
import './styles/global.scss';
import NotFoundPage from './pages/404';
import RegistryPage from './pages/registry';
import AuditLogPage from './pages/audit-log';
import HomePage from './pages';
import { RegistryProvider } from './hooks/RegistryProvider';
import RegistrySearchInputProvider from './hooks/RegistrySearchInputProvider';
import RegistryStatsProvider from './hooks/RegistryStatsProvider';
import { RegistryNotFound } from './components/HandleErrors/RegistryNotFound';

const App: React.FC = () => {
  return (
    <RegistrySearchInputProvider>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route
          path="/registries"
          render={() => (
            <RegistryStatsProvider>
              <RegistriesPage />
            </RegistryStatsProvider>
          )}
        />
        <Route exact path="/registry" component={RegistryNotFound} />
        <Route
          path={'/registry/:slug'}
          render={(props: RouteComponentProps) => (
            <RegistryProvider props={props}>
              <RegistryPage />
            </RegistryProvider>
          )}
        />
        <Route path="/audit-log" component={AuditLogPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </RegistrySearchInputProvider>
  );
};

export default App;
