import React, { createContext, ReactNode, useContext } from 'react';
import { Stats, useRegistryStatsQuery } from 'src/generated/graphql';
import { splitPayload } from 'src/utils/handleUnions';

export interface IRegistryStatsProviderState {
  loading: boolean;
  mostPopularEvent: string | undefined;
  partialError: string | undefined;
  statsLast30: Stats;
  statsTotal: Stats;
}

const RegistryStatsContext = createContext<IRegistryStatsProviderState>(
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  undefined!
);

export const useRegistryStats = (): IRegistryStatsProviderState =>
  useContext(RegistryStatsContext);

interface IRegistryStatsProviderProps {
  children: ReactNode;
}

const RegistryStatsProvider: React.FC<IRegistryStatsProviderProps> = ({
  children,
}: IRegistryStatsProviderProps) => {
  const { data, loading } = useRegistryStatsQuery();
  const [payload, partialError] = splitPayload(
    'RegistryStatsPayload',
    data?.registryStats
  );

  const statsLast30 = payload?.statsLast30 as Stats;
  const statsTotal = payload?.statsTotal as Stats;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const popularEvents: { [key: string]: any } =
    statsTotal?.registries.createdByType || {};

  const mostPopularEvent =
    Object.keys(popularEvents).length === 0
      ? undefined
      : Object.keys(popularEvents).reduce((a, b) =>
          popularEvents[a] > popularEvents[b] ? a : b
        );

  return (
    <RegistryStatsContext.Provider
      value={{
        partialError,
        loading,
        mostPopularEvent,
        statsLast30: statsLast30,
        statsTotal: statsTotal,
      }}
    >
      {children}
    </RegistryStatsContext.Provider>
  );
};

export default RegistryStatsProvider;
