import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ErrorsProps, useErrors } from 'src/components/ErrorMessage';
import {
  CustomerInfo,
  RegistryInfo,
  RegistrySearchDocument,
  RegistrySearchInput,
  RegistrySearchQuery,
  RegistrySearchQueryVariables,
  useRegistrySearchLazyQuery,
} from 'src/generated/graphql';
import { splitPayload } from 'src/utils/handleUnions';
import { useT } from './intl';

export interface RegistrySearchInputState {
  loading: boolean;
  registries: RegistryInfo[];
  errorsProps?: ErrorsProps;
  search: (
    searchInput?: Exclude<RegistrySearchInput, 'nextPageCursor'>,
    customer?: CustomerInfo
  ) => void;
  resetSearch: () => void;
  loadMore: () => void;
  removeRegistryFromResults: (registryId: string) => void;
  hasMore: boolean;
  hasSearched: boolean;
  isReset: boolean;
  latestSearch?: {
    searchInput?: RegistrySearchInput;
    customer?: CustomerInfo;
  };
}

const defaultState: RegistrySearchInputState = {
  loading: false,
  registries: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  search: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetSearch: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  loadMore: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeRegistryFromResults: () => {},
  hasMore: false,
  hasSearched: false,
  isReset: false,
  latestSearch: undefined,
};

const RegistrySearchInputContext =
  createContext<RegistrySearchInputState>(defaultState);
export const useRegistrySearchInput = (): RegistrySearchInputState =>
  useContext(RegistrySearchInputContext);

interface IRegistrySearchInputProviderProps {
  children: React.ReactNode;
}

const RegistrySearchInputProvider: React.FC<
  IRegistrySearchInputProviderProps
> = ({ children }: IRegistrySearchInputProviderProps) => {
  const [
    registrySearchQuery,
    { data, loading, variables, called, fetchMore, client },
  ] = useRegistrySearchLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
  const [latestSearch, setLatestSearch] =
    useState<RegistrySearchInputState['latestSearch']>();
  const [isReset, setIsReset] = useState(false);
  const { errorsProps, addError } = useErrors();
  const t = useT();

  const [success, partialError] = splitPayload(
    'RegistrySearchResultPayload',
    data?.registrySearch
  );

  const search = useCallback(
    (
      searchInput: Exclude<RegistrySearchInput, 'nextPageCursor'> = {},
      customer?: CustomerInfo
    ) => {
      setIsReset(false);
      setLatestSearch({ searchInput, customer });
      registrySearchQuery({
        variables: {
          input: searchInput,
        },
      });
    },
    [registrySearchQuery]
  );

  const loadMore = useCallback(async () => {
    if (variables?.input && success?.nextPageCursor && fetchMore) {
      fetchMore({
        variables: {
          input: {
            ...variables.input,
            nextPageCursor: success.nextPageCursor,
          },
        },
      });
    } else {
      throw new Error('Cannot load more');
    }
  }, [variables, success, fetchMore]);

  const clearResults = useCallback(() => {
    if (success?.__typename && client) {
      client?.writeQuery<RegistrySearchQuery, RegistrySearchQueryVariables>({
        query: RegistrySearchDocument,
        variables,
        data: {
          registrySearch: {
            ...success,
            items: [],
          },
        },
      });
    }
  }, [success, variables, client]);

  const removeRegistryFromResults = useCallback(
    (registryId: string) => {
      if (success && client) {
        client?.writeQuery<RegistrySearchQuery, RegistrySearchQueryVariables>({
          query: RegistrySearchDocument,
          variables,
          data: {
            registrySearch: {
              ...success,
              items: success.items.filter((i) => i.id !== registryId),
            },
          },
        });
      }
    },
    [success, variables, client]
  );

  const resetSearch = useCallback(() => {
    setIsReset(true);
    setLatestSearch({});
    clearResults();
  }, [clearResults]);

  useEffect(() => {
    if (partialError) {
      addError(t(`error.generic.${partialError}`));
    }
  }, [partialError, addError, t]);

  return (
    <RegistrySearchInputContext.Provider
      value={{
        loading,
        search,
        resetSearch,
        registries: success?.items || [],
        loadMore,
        errorsProps,
        hasMore: !!success?.nextPageCursor,
        hasSearched: called,
        isReset,
        removeRegistryFromResults,
        latestSearch,
      }}
    >
      {children}
    </RegistrySearchInputContext.Provider>
  );
};

export default RegistrySearchInputProvider;
