import { space250 } from '@ingka/variables/design-tokens';
import React from 'react';
import { Registry } from 'src/generated/graphql';
import { useT } from 'src/hooks/intl';
import { Modal, ModalHeader, Sheets } from 'src/skapa';
import { HomeDelivery } from './RegistrySettings/HomeDelivery';
import { Title } from './styles';

type GiftDeliveryModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  registry: Registry;
};

export const GiftDeliveryModal: React.FC<GiftDeliveryModalProps> = ({
  setOpen,
  open,
  registry,
}: GiftDeliveryModalProps) => {
  const t = useT();

  return (
    <Modal visible={open} escapable handleCloseBtn={() => setOpen(false)}>
      <Sheets footer={null} header={<ModalHeader />}>
        <Title component="h2" variant="h2" marginBottom={space250}>
          {t('registry.settings.giftDelivery')}
        </Title>
        <HomeDelivery registry={registry} />
      </Sheets>
    </Modal>
  );
};
