import styled from 'styled-components';

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24rem;
`;

export const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme.space100};
  border: 1px solid ${(props) => props.theme.colourNeutral3};
  height: fit-content;
  border-radius: ${(props) => props.theme.radiusS};
  width: 100%;
  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    width: auto;
  }
`;

export const FlexStatsRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.space150};
`;

export const FlexStatColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space150};
`;
