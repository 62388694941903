import { Day } from 'src/generated/graphql';

export const getFirstDayOfWeek = (countryCode: string): 0 | 1 => {
  switch (countryCode.toLowerCase()) {
    case 'ca':
    case 'us':
      // Sunday
      return 0;

    default:
      // Monday
      return 1;
  }
};

export const sortDaysByWeek = (
  arrayOfWeekdays: Day[] = [],
  countryCode: string
): Day[] => {
  const firstDayOfWeek = getFirstDayOfWeek(countryCode);

  const array = [...arrayOfWeekdays];
  const order = [
    Day.Sunday,
    Day.Monday,
    Day.Tuesday,
    Day.Wednesday,
    Day.Thursday,
    Day.Friday,
    Day.Saturday,
  ];

  if (firstDayOfWeek === 1) {
    order.push(order.shift() as Day);
  }

  return array.sort((a, b) => {
    return order.indexOf(a) - order.indexOf(b);
  });
};
