import React, { useState } from 'react';
import { Registry } from 'src/generated/graphql';
import { useT } from 'src/hooks/intl';
import { Tab, TabPanel, Tabs } from 'src/skapa';
import { RegistryOrders } from './RegistryOrders';
import { ProductList } from './ProductList';
import { RegistryLog } from './RegistryLog';

interface IRegistryTabsProps {
  registry?: Registry;
}

export const RegistryTabs: React.FC<IRegistryTabsProps> = ({
  registry,
}: IRegistryTabsProps) => {
  const t = useT();
  const [activeTab, setActiveTab] = useState<string>('overview_panel');

  const onChanged = (tab: string) => {
    setActiveTab(tab);
  };

  // Tabs are disabled to prevent navigation while loading
  const tabs = [
    <Tab
      key="overview"
      text={t('registry.tabs.overview')}
      tabPanelId="overview_panel"
      disabled={!registry}
    />,
    <Tab
      key="orders"
      text={t('registry.tabs.purchaseOrders')}
      tabPanelId="orders_panel"
      disabled={!registry}
    />,
    <Tab
      key="log"
      text={t('registry.tabs.logActivities')}
      tabPanelId="log_panel"
      disabled={!registry}
    />,
  ];

  // Don't render any tab content if we don't have any registry
  // Prevents crashes if user is on for example order page and then switch retail unit.
  const tabPanels = registry
    ? [
        <TabPanel key="overviewPanel" tabPanelId="overview_panel">
          {registry && <ProductList items={registry?.wishlist} />}
        </TabPanel>,
        <TabPanel key="ordersPanel" tabPanelId="orders_panel">
          {activeTab === 'orders_panel' && <RegistryOrders />}
        </TabPanel>,
        <TabPanel key="logPanel" tabPanelId="log_panel">
          {activeTab === 'log_panel' && registry && (
            <RegistryLog registryId={registry?.id} />
          )}
        </TabPanel>,
      ]
    : [];

  return (
    <Tabs
      tabs={tabs}
      onTabChanged={onChanged}
      tabPanels={tabPanels}
      defaultActiveTab={activeTab}
    />
  );
};
