import React, { createContext, ReactNode, useContext, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { ErrorsProps, useErrors } from 'src/components/ErrorMessage';
import { RegistryNotFound } from 'src/components/HandleErrors/RegistryNotFound';
import {
  Registry,
  useGetRegistryQuery,
  useReopenRegistryMutation,
  useSuspendRegistryMutation,
} from 'src/generated/graphql';
import { splitPayload } from 'src/utils/handleUnions';
import { useIntl, useT } from './intl';

interface RegistryProviderState {
  registry: Registry;
  loading: boolean;
  errorsProps: ErrorsProps;
  reOpen: () => Promise<void>;
  suspend: () => Promise<void>;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const RegistryContext = createContext<RegistryProviderState>(undefined!);

export const useRegistry = (): RegistryProviderState => {
  return useContext(RegistryContext);
};

interface IRegistryProviderProps {
  children: ReactNode;
  props?: RouteComponentProps;
}

type RouteParams = {
  slug?: string;
} | null;

export const RegistryProvider: React.FC<IRegistryProviderProps> = ({
  children,
  props,
}: IRegistryProviderProps) => {
  let params: RouteParams = null;
  let registryId: string = '';

  if (props) {
    // Use the registryId provided from the url route /registry/:slug
    params = props.match.params;
    registryId = params.slug ?? '';
  }

  const { language } = useIntl();
  const { errorsProps, addError } = useErrors();
  const t = useT();
  const skip = !registryId;
  const { data: registryData, loading: registryLoading } = useGetRegistryQuery({
    variables: {
      input: {
        registryId: registryId || '',
      },
      languageCode: language,
    },
    skip,
  });

  const [registrySuccess, partialRegistryError] = splitPayload(
    'RegistryPayload',
    registryData?.registry
  );

  const registry = registrySuccess?.registry;

  const [
    suspendRegistryMutation,
    { data: registrySuspended, loading: suspendedLoading },
  ] = useSuspendRegistryMutation({
    variables: {
      input: {
        registryId: registryId || '',
      },
      languageCode: language,
    },
  });

  const [, partialSuspendError] = splitPayload(
    'SuspendRegistryPayload',
    registrySuspended?.suspendRegistry
  );
  const [
    reopenRegistryMutation,
    { data: reopenedRegistry, loading: reopenedLoading },
  ] = useReopenRegistryMutation({
    variables: {
      input: {
        registryId: registryId || '',
      },
      languageCode: language,
    },
  });

  const [, partialReopenError] = splitPayload(
    'ReopenRegistryPayload',
    reopenedRegistry?.reopenRegistry
  );

  const error =
    partialRegistryError || partialSuspendError || partialReopenError;

  useEffect(() => {
    if (error && error !== 'NotFoundError') {
      addError(t(`error.generic.${error}`));
    }
  }, [error, addError, t]);

  if (error === 'NotFoundError') {
    return <RegistryNotFound />;
  }

  const handleReopen = async () => {
    await reopenRegistryMutation();
  };

  const handleSuspend = async () => {
    await suspendRegistryMutation();
  };

  const loading = suspendedLoading || registryLoading || reopenedLoading;

  return (
    <RegistryContext.Provider
      value={{
        registry: registry as Registry,
        loading,
        reOpen: handleReopen,
        suspend: handleSuspend,
        errorsProps,
      }}
    >
      {children}
    </RegistryContext.Provider>
  );
};
