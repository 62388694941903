import React, { useEffect, useState } from 'react';
import {
  CustomerInfo,
  useCustomerIdentifierSearchLazyQuery,
} from 'src/generated/graphql';
import { useT } from 'src/hooks/intl';
import { CustomerIdentifierInputField } from './CustomerIdentifierInputField';
import { Errors, useErrors } from '../ErrorMessage';
import {
  Button,
  Choice,
  ChoiceItem,
  InlineMessage,
  Modal,
  ModalFooter,
  ModalHeader,
  Sheets,
} from 'src/skapa';
import { Container } from '../styles';
import { space100 } from '@ingka/variables/design-tokens';
import { Body2 } from '../Typography';
import { splitPayload } from 'src/utils/handleUnions';

interface ICustomerSearchModalProps {
  open: boolean;
  onClose: () => void;
  selectedCustomer: CustomerInfo | undefined;
  setSelectedCustomer: (c: CustomerInfo) => void;
}

export const CustomerSearchModal: React.FC<ICustomerSearchModalProps> = ({
  onClose,
  open,
  selectedCustomer,
  setSelectedCustomer,
}: ICustomerSearchModalProps) => {
  const t = useT();

  const [customers, setCustomers] = useState<CustomerInfo[]>([]);
  const { errorsProps, addError } = useErrors();
  const [customerIdentifier, setCustomerIdentifier] = useState<string>('');
  const [customerIdentifierSearch, { data, loading }] =
    useCustomerIdentifierSearchLazyQuery({
      notifyOnNetworkStatusChange: true,
    });
  const [showCustomers, setShowCustomers] = useState<boolean>(false);
  const [formIsValid, setFormValid] = useState<boolean>(false);

  useEffect(() => {
    if (loading) {
      return;
    }
    const [success, partialError] = splitPayload(
      'CustomerIdentifierSearchResultPayload',
      data?.customerIdentifierSearch
    );
    if (success?.items) {
      setCustomers(success.items);
      setShowCustomers(true);
    }
    if (partialError) {
      addError(t(`error.generic.${partialError}`));
    }
  }, [data, addError, t, loading]);

  const onReset = () => {
    setCustomerIdentifier('');
    setCustomers([]);
    setShowCustomers(false);
  };

  useEffect(() => {
    if (!selectedCustomer) {
      onReset();
    }
  }, [selectedCustomer]);

  const onSearch = () => {
    const adminInput = customerIdentifier.trim();
    if (adminInput && adminInput.length > 0) {
      setCustomerIdentifier(adminInput);
      setShowCustomers(false);
      customerIdentifierSearch({
        variables: {
          identifier: adminInput,
        },
      });
    }
  };

  return (
    <Modal
      visible={open}
      escapable
      handleCloseBtn={() => {
        onClose();
      }}
    >
      <Sheets
        header={
          <ModalHeader
            backBtnClick={
              showCustomers && customers.length > 0
                ? () => setShowCustomers(false)
                : undefined
            }
          />
        }
        footer={
          <>
            {showCustomers && customers.length > 0 ? null : (
              <ModalFooter>
                <Button
                  loading={loading}
                  type="secondary"
                  text={t('reset')}
                  disabled={!formIsValid}
                  onClick={onReset}
                />
                <Button
                  loading={loading}
                  disabled={!formIsValid}
                  type="primary"
                  text={t('search.button')}
                  onClick={onSearch}
                />
              </ModalFooter>
            )}
          </>
        }
      >
        {errorsProps && <Errors {...errorsProps} />}
        {showCustomers && customers.length > 0 ? (
          <>
            <Container
              marginBottom={space100}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Body2>
                {t('search.showing', {
                  showing: customers.length,
                  total: `${customers.length}${
                    customers.length === 20 ? '+' : ''
                  }`,
                })}
              </Body2>

              <Button
                type="primary"
                text={t('editSearch')}
                onClick={() => {
                  setShowCustomers(false);
                }}
                small
              />
            </Container>
            <CustomerSearchResults
              customers={customers}
              selected={selectedCustomer}
              onSelect={(customer) => {
                setSelectedCustomer(customer);
                onClose();
              }}
            />
          </>
        ) : (
          <>
            {showCustomers && customers.length === 0 && (
              <Container marginBottom={space100}>
                <InlineMessage
                  title={t('customerSearch.noResults')}
                  variant="negative"
                />
              </Container>
            )}
            <CustomerIdentifierInputField
              loading={loading}
              setFormValid={setFormValid}
              onSearch={onSearch}
              customerIdentifier={customerIdentifier}
              setCustomerIdentifier={setCustomerIdentifier}
            />
          </>
        )}
      </Sheets>
    </Modal>
  );
};

interface ICustomerResultProps {
  onSelect: (customer: CustomerInfo) => void;
  selected?: CustomerInfo;
  customers: CustomerInfo[];
}
const CustomerSearchResults: React.FC<ICustomerResultProps> = ({
  onSelect,
  selected,
  customers,
}: ICustomerResultProps) => {
  const t = useT();
  return (
    <Choice
      preselectedIndex={
        selected
          ? customers.findIndex((c) => c.partyUId === selected.partyUId)
          : undefined
      }
    >
      {customers.map((c: CustomerInfo) => {
        const customerInfoDetails = [
          { label: t('email'), value: c.email },
          {
            label: t('customerSearch.familyNr'),
            value: c.cardNumber,
          },
          { label: t('customerSearch.phoneNumber'), value: c.phone },
          {
            label: t('customerSearch.partyUId'),
            value: c.partyUId,
          },
        ];
        return (
          <ChoiceItem
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            id={c.partyUId!}
            key={c.partyUId}
            onClick={() => {
              onSelect(c);
            }}
            title={`${c.firstName} ${c.lastName}`}
            caption={
              <>
                {customerInfoDetails
                  .filter((x) => x.value)
                  .map((detail) => {
                    return (
                      <div>
                        {detail.label}: {detail.value}
                      </div>
                    );
                  })}
              </>
            }
          />
        );
      })}
    </Choice>
  );
};
