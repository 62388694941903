import React from 'react';
import { InputField } from 'src/skapa';
import cross from '@ingka/ssr-icon/paths/cross-small';

type BaseProps = React.ComponentProps<typeof InputField>;

interface ClearableInputFieldProps extends Omit<BaseProps, 'type'> {
  iconOnClick: NonNullable<BaseProps['iconOnClick']>;
  onClear: NonNullable<BaseProps['iconOnClick']>;
  type?: BaseProps['type'];
}

const ClearableInputField: React.FC<ClearableInputFieldProps> = (
  props: ClearableInputFieldProps
) => {
  const { iconOnClick, onClear, value, ssrIcon, ...restProps } = props;
  return (
    <InputField
      readOnly
      type="text"
      iconPosition="trailing"
      value={value}
      {...restProps}
      iconOnClick={(...args) => {
        if (value) {
          onClear(...args);
        } else {
          iconOnClick(...args);
        }
      }}
      ssrIcon={value ? cross : ssrIcon}
    />
  );
};

export default ClearableInputField;
