import React from 'react';
import { LoadingBall } from '@ingka/loading';
import { useT } from 'src/hooks/intl';
import { useRegistrySearchInput } from 'src/hooks/RegistrySearchInputProvider';
import { Loading } from 'src/skapa';
import { Heading, Label } from '../Typography';
import { RegistryResult } from './RegistryResult';
import EmptyState from '../EmptyState';
import { space200 } from '@ingka/variables/design-tokens';
import { Container } from '../styles';
import * as S from './styles';

export const RegistryResults: React.FC = () => {
  const t = useT();
  const { hasSearched, isReset, registries, hasMore, loadMore, loading } =
    useRegistrySearchInput();

  const noResults = hasSearched && !registries.length && !loading && !isReset;

  return (
    <S.RegistryResultsWrapper>
      {hasSearched && !isReset && (
        <S.ResultsHeadingWrapper>
          <Heading variant="h2">{t('search.results')}</Heading>
          <Label>
            {t('search.showing', {
              showing: registries.length,
              total: `${registries.length}${hasMore || loading ? '+' : ''}`,
            })}
          </Label>
        </S.ResultsHeadingWrapper>
      )}
      {registries.map((r) => (
        <RegistryResult key={r.id} registry={r} />
      ))}
      {loading && !registries.length && (
        <S.LoadingContainer>
          <Loading>
            <LoadingBall />
          </Loading>
        </S.LoadingContainer>
      )}
      {noResults && (
        <Container paddingTop={space200}>
          <EmptyState />
        </Container>
      )}
      {((hasMore && !isReset) || (loading && registries.length > 0)) && (
        <S.LoadMore
          small
          loading={loading}
          type="secondary"
          text={t('loadMore')}
          onClick={loadMore}
        />
      )}
    </S.RegistryResultsWrapper>
  );
};
