import React from 'react';
import { useIntl, useT, languageMap } from 'src/hooks/intl';
import {
  Modal,
  ModalHeader,
  Sheets,
  ChoiceItem,
  Choice,
  space250,
} from 'src/skapa';
import { Title } from '../styles';

interface ILanguagesModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const LanguagesModal: React.FC<ILanguagesModalProps> = ({
  setOpen,
  open,
}: ILanguagesModalProps) => {
  const t = useT();
  const { country, setLanguage, language } = useIntl();

  return (
    <Modal
      visible={open}
      escapable={true}
      handleCloseBtn={() => setOpen(false)}
    >
      <Sheets footer={null} header={<ModalHeader />}>
        <Title component="h2" variant="h2" marginBottom={space250}>
          {t('navigation.changeLanguage.title')}
        </Title>
        <Choice>
          {languageMap[country].map((l) => {
            return (
              <ChoiceItem
                selected={l === language}
                id={l}
                key={l}
                title={t(`languages.${l}`)}
                onClick={() => {
                  setLanguage(l);
                  setOpen(false);
                }}
              />
            );
          })}
        </Choice>
      </Sheets>
    </Modal>
  );
};
