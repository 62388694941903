export const isTypename = <T extends string>(typename: T) => <
  N extends GqlTyped
>(
  node: N
): node is Extract<N, GqlTyped<T>> => {
  return node?.__typename === typename;
};

type GqlTyped<T = string> = { __typename: T };

export type ExtractType<
  X extends GqlTyped,
  Y extends X['__typename']
> = Extract<X, GqlTyped<Y>>;

export type ExcludeType<
  X extends GqlTyped,
  Y extends X['__typename']
> = Exclude<X, GqlTyped<Y>>;

export type SplitType<X extends GqlTyped, Y extends X['__typename']> = [
  ExtractType<X, Y>,
  ExcludeType<X, Y>
];

export const splitType = <X extends GqlTyped, Y extends X['__typename']>(
  key: Y,
  node?: X
): SplitType<X, Y> => {
  if (node && isTypename<Y>(key)(node)) {
    return [node, {}] as SplitType<X, Y>;
  }

  return [{}, node] as SplitType<X, Y>;
};

export type SplitPayload<X extends GqlTyped, Y extends X['__typename']> = [
  ExtractType<X, Y> | undefined,
  ExcludeType<X, Y>['__typename'] | undefined
];

export const splitPayload = <X extends GqlTyped, Y extends X['__typename']>(
  key: Y,
  node?: X
): SplitPayload<X, Y> => {
  const [payload, errors] = splitType<X, Y>(key, node);

  const errorType = errors?.__typename || undefined;

  return [payload, errorType];
};
