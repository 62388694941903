import React from 'react';
import IngkaTabs, {
  TabPanel as IngkaTabPanel,
  Tab as IngkaTab,
  TabsProps,
  TabPanelProps,
  TabProps,
} from '@ingka/tabs';

export const Tab: React.FC<TabProps & { ref?: never }> = (props) => (
  <IngkaTab {...props} prefix="igift-admin-" />
);

export const TabPanel: React.FC<TabPanelProps & { ref?: never }> = (props) => (
  <IngkaTabPanel {...props} prefix="igift-admin-" />
);

export const Tabs: React.FC<TabsProps> = (props) => (
  <IngkaTabs {...props} prefix="igift-admin-" />
);
