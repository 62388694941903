import React, { useCallback, useEffect, useState } from 'react';
import { Heading, Label } from 'src/components/Typography';
import { Body1 } from 'src/components/Typography';
import { Content, Container } from 'src/components/styles';

import { Crumbler } from '../components/Crumbler';
import { SubPage } from 'src/components/SubPage';
import { splitPayload } from 'src/utils/handleUnions';
import {
  AuditLogEventFragment,
  AuditLogInput,
  EntityType,
  LogFiltering,
  Maybe,
  useAuditLogLazyQuery,
  AuditLogQueryVariables,
  AuditLogDocument,
  AuditLogQuery,
} from 'src/generated/graphql';
import { AuditLogSearch } from 'src/components/Search/AuditLogSearch';
import { AuditLogResults } from 'src/components/SearchResults/AuditLogResults';
import { useIntl, useT } from 'src/hooks/intl';
import RoleTemplate from 'src/hooks/auth/RoleTemplate';
import { Role } from 'src/hooks/auth';
import { LoadMore } from 'src/components/SearchResults/styles';
import { useErrors } from 'src/components/ErrorMessage';
import { SEO } from 'src/components/SEO';
import { space400, space200, space150 } from '@ingka/variables/design-tokens';
import { Toggle } from 'src/skapa';

const useAuditLog = () => {
  const { language } = useIntl();

  const [
    adminLogQuery,
    { data, loading, variables, called: hasSearched, client },
  ] = useAuditLogLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [searchResults, setSearchResults] = useState<AuditLogEventFragment[]>(
    []
  );

  const { errorsProps, addError } = useErrors();
  const t = useT();

  const [success, partialError] = splitPayload(
    'AuditLogPayload',
    data?.auditLog
  );

  const search = useCallback(
    (searchInput: Exclude<AuditLogInput, 'nextPageCursor'>) => {
      adminLogQuery({
        variables: {
          input: searchInput,
          languageCode: language,
          shouldFetchId: true,
        },
      });

      // Clear cached pages
      setSearchResults([]);
    },
    [adminLogQuery, language]
  );

  const onChangeFilter = useCallback(
    (filter: Partial<LogFiltering>) => {
      search({ filtering: { ...variables?.input.filtering, ...filter } });
    },
    [search, variables]
  );

  const loadMore = useCallback(() => {
    if (variables?.input && success?.nextPageCursor) {
      adminLogQuery({
        variables: {
          ...variables,
          input: {
            ...variables?.input,
            nextPageCursor: success.nextPageCursor,
          },
        },
      });
    } else {
      throw new Error('Cannot load more');
    }
  }, [variables, success, adminLogQuery]);

  const clearResults = useCallback(() => {
    if (success?.__typename && client) {
      client?.writeQuery<AuditLogQuery, AuditLogQueryVariables>({
        query: AuditLogDocument,
        variables,
        data: {
          auditLog: {
            ...success,
            events: [],
          },
        },
      });
    }
  }, [success, client, variables]);

  useEffect(() => {
    if (success?.events && !loading) {
      if (success?.events.length) {
        setSearchResults((currentItems) => [
          ...currentItems,
          ...success.events,
        ]);
      } else {
        setSearchResults([]);
      }
    }
  }, [loading, success]);

  useEffect(() => {
    if (partialError) {
      addError(t(`error.generic.${partialError}`));
    }
  }, [partialError, addError, t]);

  return {
    addError,
    loading,
    search,
    events: searchResults,
    totalEvents: success?.totalEvents || 0,
    loadMore,
    errorsProps,
    hasMore: !!success?.nextPageCursor,
    hasSearched,
    onChangeFilter,
    clearResults,
  };
};

const AuditLogPage: React.FC = () => {
  const t = useT();
  const {
    addError,
    errorsProps,
    hasSearched,
    loading,
    search,
    events,
    totalEvents,
    onChangeFilter,
    hasMore,
    loadMore,
    clearResults,
  } = useAuditLog();

  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(
    undefined
  );

  const [entityTypeIndex, setEntityTypeIndex] = useState<0 | 1 | 2>(0);

  const buttons = [
    { text: t('log.entityTypes.all') },
    { text: t('log.entityTypes.GIFTBAG') },
    { text: t('log.entityTypes.REGISTRY') },
  ];

  return (
    <RoleTemplate approvedRoles={[Role.AuditLogRead]} showErrorPage>
      <SubPage>
        <SEO title={t('log.title')} />
        <Body1>
          <Crumbler crumbs={['/audit-log']} />
          <Content>
            <AuditLogSearch
              addError={addError}
              errorsProps={errorsProps}
              loading={loading}
              selectedUserId={selectedUserId}
              setSelectedUserId={setSelectedUserId}
              onSearch={search}
              clearResults={clearResults}
            />
            {hasSearched && (
              <>
                <Container
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                  marginTop={space400}
                  marginBottom={space200}
                >
                  <Heading variant="h2">{t('search.results')}</Heading>
                  <Label color="colourTextAndIcon1">
                    {t('search.showing', {
                      showing: events.length || 0,
                      total: totalEvents,
                    })}
                  </Label>
                </Container>

                <Container marginBottom={space150}>
                  <Toggle
                    buttons={buttons}
                    activeIndex={entityTypeIndex}
                    onClick={(_, index: 0 | 1 | 2) => {
                      setEntityTypeIndex(index);

                      let entityType: Maybe<EntityType> = null;
                      if (index === 1) {
                        entityType = EntityType.Giftbag;
                      } else if (index === 2) {
                        entityType = EntityType.Registry;
                      }

                      onChangeFilter({
                        entityType,
                      });
                    }}
                  />
                </Container>
              </>
            )}
            <AuditLogResults
              loading={loading}
              events={events}
              hasSearched={hasSearched}
              onSearchByUserId={(userId) => {
                onChangeFilter({ userId });
                setSelectedUserId(userId);
              }}
            />
            {(hasMore || (loading && events.length > 0)) && (
              <LoadMore
                small
                loading={loading}
                type="secondary"
                text={t('loadMore')}
                onClick={loadMore}
              />
            )}
          </Content>
        </Body1>
      </SubPage>
    </RoleTemplate>
  );
};

export default AuditLogPage;
