import React from 'react';
import { getImages, ImageKind } from 'src/utils/getImages';
import { getSrcset } from 'src/utils/getSrcset';
import { Image } from 'src/generated/graphql';
import { IMAGE_SIZES } from 'src/utils/imageSizes';
import * as S from './styles';
import { ProductEmptyImage } from './ProductEmptyImage';
import ItemGiftedLabel from './ItemGiftedLabel';

interface IProductImageProps {
  images: Array<Image>;
  defaultImage: ImageKind;
  active: boolean;
  isGifted: boolean;
  totalQuantity: number;
}

const ProductImage: React.FC<IProductImageProps> = (
  props: IProductImageProps
) => {
  const { images, defaultImage, active, isGifted, totalQuantity } = props;

  const imageRoom =
    getSrcset(images, 'FUNCTIONAL_PRODUCT_IMAGE') ||
    getSrcset(images, 'QUALITY_PRODUCT_IMAGE') ||
    getSrcset(images, 'INSPIRATIONAL_IMAGE') ||
    getSrcset(images, 'CONTEXT_PRODUCT_IMAGE');

  const imageProduct = getSrcset(images, 'MAIN_PRODUCT_IMAGE');
  const altText = images[0]?.alt || '';

  const { mainImage, secondaryImage } = getImages(
    defaultImage,
    imageProduct,
    imageRoom
  );

  return (
    <S.ImageWrapper>
      {isGifted && <ItemGiftedLabel totalQuantity={totalQuantity} />}
      {mainImage ? (
        <S.FirstAspectRatioImage
          $active={!active || !secondaryImage}
          alt={altText}
          ratio="square"
          sizes={IMAGE_SIZES}
          src={mainImage.lowQ}
          srcSet={mainImage.srcSet}
        />
      ) : (
        <ProductEmptyImage />
      )}
      {secondaryImage && (
        <S.SecondAspectRatioImage
          $active={active}
          alt={altText}
          ratio="square"
          sizes={IMAGE_SIZES}
          src={secondaryImage.lowQ}
          srcSet={secondaryImage.srcSet}
        />
      )}
    </S.ImageWrapper>
  );
};

export default ProductImage;
