import styled from 'styled-components';
import { Button, Carousel } from 'src/skapa';
import { Body2 } from '../Typography';

export const LoadMore = styled(Button)`
  margin: ${(props) => props.theme.space200} auto;
`;

export const RegistryResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    margin-top: ${(props) => props.theme.space150};
  }
`;

export const EditDetailsContainer = styled(Body2)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${(props) => props.theme.space50};
`;

export const TableHeadColumn = styled(Body2)`
  font-weight: ${(props) => props.theme.fontWeightBold};
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: ${(props) => props.theme.space300};
`;

export const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
`;

//RegistryResult

export const EventType = styled(Body2)`
  text-transform: uppercase;
`;

export const ResultsHeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: ${(props) => props.theme.space200};
`;

export const StyledCarousel = styled(Carousel)`
  .igift-admin-carousel-slide {
    width: 100%;
  }
`;
