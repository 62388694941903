import React, { ReactNode, useCallback, useState } from 'react';
import { forceMasterTranslations } from '../../utils/settings';
import { TranslationsProvider } from '@eo-locale/react';

import { setLocalStorage } from '../useLocalStorage';
import {
  defaultCountries,
  languageMap,
  loadLocale,
  masterMessages,
  Messages,
} from './locales';
import { IntlContext } from './IntlContext';
import { useShowKeys } from './useShowKeys';
import { useTimeago } from './useTimeago';
import { makeVar } from '@apollo/client';

export interface ILocale {
  language: string;
  messages: Messages;
}

const masterTranslations = {
  language: 'en-XZ',
  messages: masterMessages,
};

export const RETAIL_UNIT_KEY = 'ru';

interface IntlProviderProps {
  children: ReactNode;
}

// This used to make our apollo client send the correct preferred locale
// Think of it as a global state variable which
// can be read `preferredLocale()` or set `preferredLocale('en-XZ')`
// Read more: https://www.apollographql.com/docs/react/api/cache/InMemoryCache/#makevar
const preferredLocaleVar = makeVar('en-XZ');

function waitFor(conditionFunction: () => boolean) {
  const poll = (resolve: (value: void | PromiseLike<void>) => void) => {
    if (conditionFunction()) resolve();
    else setTimeout(() => poll(resolve), 200);
  };
  return new Promise(poll);
}

export async function getPreferredLocale(): Promise<string> {
  /** preferredLocaleVar is set in setLocale.
   * Unfortunately sometimes when we send a request as soon as
   * the page loads it is not set yet by setLocale
   * and returns its default value, which is en-XZ.
   * This causes a 403 error since en-XZ is not a valid locale.
   * This function waits for preferredLocaleVar() to be set
   * which prevents a request with preferred-locale = 'en-XZ'.
   **/
  await waitFor(() => preferredLocaleVar() !== 'en-XZ');
  return preferredLocaleVar();
}

export const IntlProvider: React.FC<IntlProviderProps> = ({
  children,
}: IntlProviderProps) => {
  const showKeys = useShowKeys();

  const [translations, setTranslations] = useState<ILocale[]>([
    masterTranslations,
  ]);

  const [locale, setCurrentLocale] = useState(masterTranslations.language);
  const [language, country] = locale.split('-');

  useTimeago(locale);

  const setLocale = useCallback(
    async (countryIn: string, languageIn?: string) => {
      const country = countryIn.toUpperCase();
      if (!languageMap[country]) {
        throw new Error(`Locale does not exist for ${country}`);
      }

      const language = languageIn || languageMap[country][0];

      if (!languageMap[country].includes(language)) {
        throw new Error(`Locale does not exist for ${language}`);
      }

      const locale = `${language}-${country}`;

      if (!translations.some(({ language }) => language === locale)) {
        const messages = await loadLocale(locale);

        setTranslations((translations) => [
          ...translations,
          { language: locale, messages },
        ]);
      }

      preferredLocaleVar(locale);
      setCurrentLocale(locale);
      setLocalStorage(RETAIL_UNIT_KEY, locale);
    },
    [translations]
  );

  const setLanguage = useCallback(
    async (language: string) => {
      await setLocale(country, language);
    },
    [setLocale, country]
  );

  const setCountry = useCallback(
    async (country: string) => {
      await setLocale(country);
    },
    [setLocale]
  );

  return (
    <IntlContext.Provider
      value={{
        language,
        country,
        locale,
        countries: defaultCountries,
        showKeys,
        setLanguage,
        setCountry,
        setLocale,
      }}
    >
      <TranslationsProvider
        language={
          forceMasterTranslations ? masterTranslations.language : locale
        }
        locales={translations}
      >
        {children}
      </TranslationsProvider>
    </IntlContext.Provider>
  );
};
