import React from 'react';

import IngkaChoice, {
  ChoiceProps,
  ChoiceItem as IngkaChoiceItem,
  ChoiceItemProps,
} from '@ingka/choice';

export const Choice: React.FC<ChoiceProps> = (props) => (
  <IngkaChoice {...props} prefix="igift-admin-" />
);

export const ChoiceItem: React.FC<ChoiceItemProps> = (props) => (
  <IngkaChoiceItem {...props} prefix="igift-admin-" />
);
