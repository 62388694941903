import React from 'react';
import { Summary } from '../Summary';
import { Title, Container } from '../styles';
import { RegistryInfo } from 'src/generated/graphql';
import { useHistory } from 'react-router';
import { useIntl, useT } from 'src/hooks/intl';
import * as S from './styles';
import { Body2, Heading } from '../Typography';

interface IRegistryResultProps {
  registry: RegistryInfo;
}
export const RegistryResult: React.FC<IRegistryResultProps> = ({
  registry: { id, title, eventType, eventDate },
}: IRegistryResultProps) => {
  const { push } = useHistory();
  const t = useT();
  const { locale } = useIntl();

  return (
    <Summary
      onClick={() => push(`/registry/${id}`)}
      headerContent={
        <Container
          display="flex"
          height="100%"
          width="100%"
          textAlign="left"
          alignItems="center"
        >
          <Container width="40%">
            <Title component="h2" variant="h4">
              {title || t('registries.defaultRegistryTitle')}
            </Title>
            <S.EventType>{t(`event.${eventType}.type`)}</S.EventType>
          </Container>
          <Container width="40%">
            <Heading component="h2" variant="h4">
              {eventDate ? new Date(eventDate).toLocaleDateString(locale) : '-'}
            </Heading>
            <Body2>{t(`registrySearch.eventDate`)}</Body2>
          </Container>
        </Container>
      }
    />
  );
};
