import React, { useMemo } from 'react';
import { GiftBag, GiftBagState } from 'src/generated/graphql';
import { useIntl, useT } from 'src/hooks/intl';
import { Tooltip } from 'src/skapa';
import { getGiftsCount } from './utils';
import { Body2 } from '../Typography/styles';
import * as S from './styles';

type RegistryOrderTableRowProps = {
  giftBag: GiftBag;
  onEditOrder: (e: MouseEvent | KeyboardEvent | TouchEvent) => void;
};

export const RegistryOrderTableRow: React.FC<RegistryOrderTableRowProps> = ({
  giftBag,
  onEditOrder,
}: RegistryOrderTableRowProps) => {
  const t = useT();
  const { locale } = useIntl();

  const giftsCount = useMemo(() => {
    return getGiftsCount(giftBag.gifts);
  }, [giftBag.gifts]);

  const ToolTippedText = ({
    text = '',
    length = 10,
  }: {
    text: string;
    length?: number;
  }) => {
    return (
      <Tooltip tooltipText={text} position="bottom">
        <span>{`${text.substring(0, Math.min(length, text.length))}...`}</span>
      </Tooltip>
    );
  };

  return (
    <tr>
      <td>
        <Body2>
          {giftBag?.giverName && (
            <ToolTippedText length={20} text={giftBag?.giverName} />
          )}
        </Body2>
      </td>
      <td>
        <Body2>
          {giftBag?.email && (
            <ToolTippedText length={20} text={giftBag?.email} />
          )}
        </Body2>
      </td>
      <td>
        <Body2>
          {giftBag.orderNumber
            ? giftBag.orderNumber
            : giftBag.state === GiftBagState.PurchasedOffline &&
              t('order.offlinePurchase')}
        </Body2>
      </td>
      <td>
        <Body2>
          {giftBag.purchaseDate
            ? giftBag.purchaseDate.toLocaleDateString(locale)
            : undefined}
        </Body2>
      </td>
      <td>
        <Body2>{giftsCount}</Body2>
      </td>
      <td>
        <Body2>
          <S.StyledHyperLink
            text={t('order.editLink')}
            button
            subtle
            onClick={onEditOrder}
          />
        </Body2>
      </td>
    </tr>
  );
};
