import styled, { css } from 'styled-components';
import { AspectRatioImage } from 'src/skapa';
import { Search as SearchComponent } from 'src/skapa';

export const Search = styled(SearchComponent)`
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    width: 23.5rem;
  }
`;

export const ImageWrapper = styled.div`
  flex: 50%;
  max-width: 28.125rem;
  position: relative;
  margin-bottom: ${(props) => props.theme.space75};
`;

export const FirstAspectRatioImage = styled(AspectRatioImage)<{
  $active: boolean;
}>`
  background: ${(props) => props.theme.colourNeutral2};
  opacity: ${(props) => (props.$active ? 1 : 0)};
`;

export const SecondAspectRatioImage = styled(FirstAspectRatioImage)`
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
`;

const productDivider = css`
  content: '';
  border-top: 1px solid ${(props) => props.theme.colourNeutral3};
  top: 0;
  left: 0;
  position: absolute;
  display: block;
`;

export const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${(props) => props.theme.space100};

  @media (min-width: ${(props) =>
      props.theme.breakpoints[1]}) and (max-width: ${(props) =>
      props.theme.breakpoints[2]}) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    grid-template-columns: repeat(6, 1fr);
  }
`;

export const ProductCardContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: ${(props) => props.theme.space200};
  padding-bottom: ${(props) => props.theme.space200};

  :first-child:nth-child(1):after {
    display: none;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    padding-bottom: ${(props) => props.theme.space300};
    padding-top: ${(props) => props.theme.space300};
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    :nth-child(2n + 1):after {
      width: calc(200% + ${(props) => props.theme.space100} * 1);
      ${productDivider};
    }
  }

  @media (min-width: ${(props) =>
      props.theme.breakpoints[1]}) and (max-width: ${(props) =>
      props.theme.breakpoints[2]}) {
    :nth-child(4n + 1):after {
      width: calc(400% + ${(props) => props.theme.space100} * 3);
      ${productDivider};
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    :nth-child(6n + 1):after {
      width: calc(600% + ${(props) => props.theme.space100} * 5);
      ${productDivider};
    }
  }
`;

export const EmptyImageContainer = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
`;

export const EmptyImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colourNeutral2};
`;
