import React from 'react';
import { SEO } from 'src/components/SEO';
import { Container, Content } from 'src/components/styles';
import { Body1, Heading } from 'src/components/Typography';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import { loginRequest } from 'src/hooks/auth';
import styled from 'styled-components';
import { Button } from 'src/skapa';
import { Redirect } from 'react-router';
import { useT } from 'src/hooks/intl';

const HomePage: React.FC = () => {
  const { instance } = useMsal();
  const t = useT();

  return (
    <Body1>
      <SEO />
      <Content>
        <Container marginBottom={6}>
          <Heading component="h1" variant="h1">
            {t('title')}
          </Heading>
          <Heading color="colourTextAndIcon2" component="h2" variant="h2">
            {t('home.subtitle')}
          </Heading>
        </Container>
        <AuthenticatedTemplate>
          <Redirect to={'/registries'} />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Body1 small> {t('home.signInHint')}</Body1>
          <Body1>
            <LoginButton
              small
              type="primary"
              text={t('signIn')}
              onClick={() => instance.loginRedirect(loginRequest)}
            />
          </Body1>
        </UnauthenticatedTemplate>
      </Content>
    </Body1>
  );
};

export default HomePage;

export const LoginButton = styled(Button)`
  margin-top: ${(props) => props.theme.space125};
`;
