import React from 'react';
import TimeAgo from 'timeago-react';
import { NavLink } from 'react-router-dom';
import infoCircle from '@ingka/ssr-icon/paths/information';
import { useIntl, useT } from 'src/hooks/intl';
import {
  Action,
  AuditLogEventFragment,
  EntityType,
  UserType,
  AuditField,
} from '../../generated/graphql';
import { Hyperlink, Tooltip } from 'src/skapa';
import { Body2, ProductTitle } from '../Typography/styles';
import * as S from './styles';
import { convertLocale } from 'src/hooks/intl/useTimeago';

interface IAuditLogResultProps {
  event: AuditLogEventFragment;
  showRegistry: boolean;
  showUserId: boolean;
  onSearchByUserId?: (userId: string) => void;
}

export const AuditLogResult: React.FC<IAuditLogResultProps> = ({
  event: { action, entity, timestamp, user, fields, items, registryTitle },
  showRegistry,
  showUserId,
  onSearchByUserId,
}: IAuditLogResultProps) => {
  const { locale } = useIntl();
  const t = useT();
  const registryDeleted =
    action === Action.Deleted && entity.type === EntityType.Registry;

  let userType = 'log.userTypes.customer.receiver';
  if (user.type === UserType.Personnel) {
    userType = `log.userTypes.${UserType.Personnel}`;
  } else if (entity.type === EntityType.Giftbag) {
    userType = 'log.userTypes.customer.giver';
  }

  return (
    <tr>
      <td>
        <Body2>{t(userType)}</Body2>
      </td>
      {showUserId && (
        <td>
          {user.id && (
            <S.EditDetailsContainer>
              <Tooltip tooltipText={user.id} position="bottom">
                {onSearchByUserId ? (
                  <Hyperlink
                    onClick={() => onSearchByUserId(user.id as string)}
                  >
                    {`${user.id.substring(0, Math.min(18, user.id.length))}...`}
                  </Hyperlink>
                ) : (
                  <span>{`${user.id.substring(
                    0,
                    Math.min(18, user.id.length)
                  )}...`}</span>
                )}
              </Tooltip>
            </S.EditDetailsContainer>
          )}
        </td>
      )}
      <td>
        <Body2>{t(`log.actions.${action}`)}</Body2>
      </td>
      <td>
        <Body2>{t(`log.entityTypes.${entity.type}`)}</Body2>
      </td>
      <td>
        <AuditLogDetails
          {...{ action, entity, timestamp, user, fields, items }}
        />
      </td>

      {showRegistry && (
        <td>
          <Body2>
            {!registryDeleted && (
              <NavLink
                className="igift-admin-link"
                key={entity.registryId}
                to={`/registry/${entity.registryId}`}
                activeStyle={{
                  textDecoration: 'underline',
                }}
              >
                {registryTitle || t('registries.defaultRegistryTitle')}
              </NavLink>
            )}
          </Body2>
        </td>
      )}

      <td>
        <Body2>
          <TimeAgo datetime={timestamp} locale={convertLocale(locale)} />
        </Body2>
      </td>
      <td>
        <Body2>{new Date(timestamp).toLocaleString(locale)}</Body2>
      </td>
    </tr>
  );
};

const AuditLogDetails: React.FC<AuditLogEventFragment> = ({
  action,
  entity,
  items,
  fields,
  user,
}: AuditLogEventFragment) => {
  const t = useT();
  const { locale } = useIntl();

  const imageOrDate = (field: AuditField) => {
    let value = field.value;
    if (field.name === 'eventDate') {
      value = value ? new Date(value).toLocaleDateString(locale) : '';
    }
    return t(`log.fields.${field.name}`, {
      value,
    });
  };

  return (
    <>
      {(action === Action.ItemsChangedQuantity ||
        action === Action.ItemsAdded) && (
        <S.QuantityContainer>
          <Body2>{`${items[0]?.quantity} x`}&nbsp;</Body2>
          <ProductTitle>
            {items[0]?.productName || items[0]?.itemNo}
          </ProductTitle>
        </S.QuantityContainer>
      )}

      {action === Action.ItemsRemoved && (
        <ProductTitle>{items[0]?.productName || items[0]?.itemNo}</ProductTitle>
      )}
      {action === Action.Deleted && entity.type === EntityType.Registry && (
        <S.EditDetailsContainer>
          <Tooltip tooltipText={entity.registryId} position="bottom">
            <span>
              {`${entity.registryId.substring(
                0,
                Math.min(18, entity.registryId.length)
              )}...`}
            </span>
          </Tooltip>
        </S.EditDetailsContainer>
      )}
      {action === Action.Edited && (
        <S.EditDetailsContainer>
          {(fields[0]?.name === 'stockImage' ||
            fields[0]?.name === 'eventDate') && <>{imageOrDate(fields[0])}</>}
          {(fields[0]?.name === 'title' ||
            fields[0]?.name === 'description') && (
            <>
              {t(`log.fields.${fields[0]?.name}`)}
              <Tooltip
                ssrIcon={infoCircle}
                toggle={true}
                tooltipText={fields[0]?.value || ''}
                tooltipButtonLabel={t(`log.fields.${fields[0]?.name}`)}
                position="bottom"
              />
            </>
          )}
        </S.EditDetailsContainer>
      )}
      {action === Action.CoOwnerRemoved && (
        <S.EditDetailsContainer>
          {fields[0]?.name === 'co-owner' && (
            <>
              {t(`log.fields.${fields[0]?.name}`)}
              <Tooltip
                ssrIcon={infoCircle}
                toggle={true}
                tooltipText={fields[0]?.value || ''}
                tooltipButtonLabel={t(`log.fields.${fields[0]?.name}`)}
                position="bottom"
              />
            </>
          )}
        </S.EditDetailsContainer>
      )}
    </>
  );
};
