import React, { ReactNode } from 'react';
import { SpaceProps } from 'styled-system';
import { MainTheme } from 'src/styles/themes';

import {
  H1,
  H2,
  H3,
  H4,
  Label,
  Body1 as Text1,
  Body2 as Text2,
  ProductDescription,
  ProductDescription2,
  ProductTitle,
} from './styles';

type SupportedHeadings = 'h1' | 'h2' | 'h3' | 'h4';

interface IHeadingProps {
  variant: SupportedHeadings;
  component?: SupportedHeadings;
  small?: boolean;
  color?: string;
  className?: string;
  children: ReactNode;
}

export const Heading: React.FC<SpaceProps<MainTheme> & IHeadingProps> = ({
  variant,
  component = variant,
  children,
  ...props
}: IHeadingProps) => {
  switch (variant) {
    case 'h4':
      return (
        <H4 as={component} {...props}>
          {children}
        </H4>
      );
    case 'h3':
      return (
        <H3 as={component} {...props}>
          {children}
        </H3>
      );
    case 'h2':
      return (
        <H2 as={component} {...props}>
          {children}
        </H2>
      );
    default:
      return (
        <H1 as={component} {...props}>
          {children}
        </H1>
      );
  }
};

type SupportedTags = 'span' | 'div' | 'p';

interface IBody1Props {
  small?: boolean;
  className?: string;
  component?: SupportedTags;
  color?: string;
  children: ReactNode;
}

export const Body1: React.FC<SpaceProps<MainTheme> & IBody1Props> = ({
  component = 'div',
  children,
  ...props
}: IBody1Props) => (
  <Text1 as={component} {...props}>
    {children}
  </Text1>
);

interface IBody2Props {
  className?: string;
  component?: SupportedTags;
  color?: string;
  children: ReactNode;
}

export const Body2: React.FC<SpaceProps<MainTheme> & IBody2Props> = ({
  component = 'div',
  children,
  ...props
}: IBody2Props) => (
  <Text2 as={component} {...props}>
    {children}
  </Text2>
);

export { Label, ProductDescription, ProductTitle, ProductDescription2 };
