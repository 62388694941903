import styled from 'styled-components';
import { Button, Carousel, Search as SearchComponent } from 'src/skapa';
import { Body2 } from '../Typography';

export const Search = styled(SearchComponent)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.space100};

  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    width: 23.5rem;
    margin-bottom: 0;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: ${(props) => props.theme.space300};
`;

export const LoadMore = styled(Button)`
  margin: ${(props) => props.theme.space200} auto;
`;

export const TableHeadColumn = styled(Body2)`
  font-weight: ${(props) => props.theme.fontWeightBold};
`;

export const StyledCarousel = styled(Carousel)`
  .igift-admin-carousel-slide {
    width: 100%;
  }
`;
