import React from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useT } from 'src/hooks/intl';
interface ISubPageProps {
  children: React.ReactNode;
}

export const SubPage: React.FC<ISubPageProps> = ({
  children,
}: ISubPageProps) => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const t = useT();
  return (
    <>
      {isAuthenticated && inProgress === 'none' && <>{children}</>}
      {!isAuthenticated && inProgress !== 'none' && (
        <span>{t('auth.loading')}</span>
      )}
    </>
  );
};
