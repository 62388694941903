import React from 'react';
import { Helmet } from 'react-helmet';
import { useT } from 'src/hooks/intl';

interface SEOProps {
  title?: string;
}

export const SEO: React.FC<SEOProps> = ({ title = '' }: SEOProps) => {
  const t = useT();
  return (
    <Helmet
      title={title || t('seo.defaultPageTitle')}
      titleTemplate={title ? `%s | ${t('seo.defaultPageTitle')}` : ''}
    />
  );
};
