import { useCallback, useEffect, useState } from 'react';

export const getStorageKey = (key: string): string => `igift-${key}-admin`;

export const setLocalStorage = <T = unknown>(
  key: string,
  newValue: T
): void => {
  const storageKey = getStorageKey(key);
  localStorage.setItem(storageKey, JSON.stringify(newValue));
};

export const getLocalStorage = <T = unknown>(
  key: string,
  defaultValue: T
): T => {
  const storageKey = getStorageKey(key);
  const storedValue = localStorage.getItem(storageKey);

  if (storedValue === null) {
    return defaultValue;
  }

  let result;
  try {
    result = JSON.parse(storedValue);
  } catch (_e) {
    localStorage.removeItem(storageKey);
  }
  return result;
};

export const useLocalStorage = <T>(
  key: string,
  defaultValue: T
): [T, (newValue: T) => void] => {
  const [value, setValueState] = useState(() => {
    return getLocalStorage(key, defaultValue);
  });

  const setValue = useCallback(
    (newValue: T) => {
      setValueState(newValue);
      setLocalStorage(key, newValue);
    },
    [key]
  );

  useEffect(() => {
    setValueState(getLocalStorage(key, defaultValue));
  }, [key, defaultValue]);

  return [value, setValue];
};
