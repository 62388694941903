import React from 'react';

import IngkaSelect, {
  SelectProps,
  Option as IngkaOption,
  OptionProps,
} from '@ingka/select';

export const Select: React.FC<SelectProps> = (props: SelectProps) => (
  <IngkaSelect {...props} prefix="igift-admin-">
    {props.children}
  </IngkaSelect>
);

export const Option: React.FC<OptionProps> = (props: OptionProps) => (
  <IngkaOption {...props} prefix="igift-admin-">
    {props.children}
  </IngkaOption>
);
