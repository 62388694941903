import React from 'react';
import { Button, Modal, ModalFooter, Prompt, ModalHeader } from 'src/skapa';
import { Body2 } from '../Typography';
import { space100 } from '@ingka/variables/design-tokens';
import { useT } from 'src/hooks/intl';

interface IDisableHomDeliveryPrompt {
  showDisablePrompt: boolean;
  setShowDisablePrompt: (x: boolean) => void;
  handleConfirmDisableClick: () => void;
  loading: boolean;
  disabledLoading: boolean;
}

export const DisableHomeDeliveryPrompt: React.FC<IDisableHomDeliveryPrompt> = ({
  showDisablePrompt,
  setShowDisablePrompt,
  handleConfirmDisableClick,
  loading,
  disabledLoading,
}: IDisableHomDeliveryPrompt) => {
  const t = useT();
  return (
    <Modal
      visible={showDisablePrompt}
      handleCloseBtn={() => setShowDisablePrompt(false)}
    >
      <Prompt
        title={t('registry.settings.disableDeliveryPrompt.title')}
        footer={
          <ModalFooter>
            <Button
              text={t('confirm')}
              type="primary"
              onClick={handleConfirmDisableClick}
              loading={loading || disabledLoading}
            />
          </ModalFooter>
        }
        header={<ModalHeader />}
      >
        <Body2 component="p" marginTop={space100}>
          {t('registry.settings.disableDeliveryPrompt.content')}
        </Body2>
      </Prompt>
    </Modal>
  );
};
