import {
  AuthenticationResult,
  Configuration,
  EventMessage,
  EventType,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from '@azure/msal-browser';
import {
  authority,
  clientId,
  logOutUri,
  redirectUri,
  tenantId,
} from '../../utils/settings';

// Msal Configurations
const config: Configuration = {
  auth: {
    authority: `${authority}/${tenantId}`,
    clientId,
    redirectUri,
    postLogoutRedirectUri: logOutUri,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};

// Authentication Parameters
export const loginRequest = {
  scopes: ['User.Read', 'profile', 'openid', 'offline_access'],
};

export const msalInstance = new PublicClientApplication(config);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

export const acquireToken = (): Promise<AuthenticationResult> =>
  msalInstance
    .acquireTokenSilent({
      ...loginRequest,
    })
    .catch((error) => {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        msalInstance.acquireTokenRedirect(loginRequest);
        return Promise.reject(error);
      }

      console.error('Failed to acquire MSAL token', error);
      return Promise.reject(error);
    });
