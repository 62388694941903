import React from 'react';

import IngkaModal, { ModalProps } from '@ingka/modal';

const Modal: React.FC<ModalProps> = (props: ModalProps) => (
  <IngkaModal {...props} prefix="igift-admin-">
    {props.children}
  </IngkaModal>
);

export default Modal;
