export const forceMasterTranslations =
  window.igift.forceMasterTranslations === 'true';
export const showTranslationKeys: boolean =
  window.igift.showTranslationKeys === 'true';

export const customerAdminUrls = {
  dev: 'https://customeradmin.test.customer.ingka.com',
  prod: 'https://customeradmin.customer.ingka.com',
};

export const igiftContactEmail = 'dl.igift.all.se@ingka.com';

export const {
  apiOrigin,
  cmpOrigin,
  authority,
  clientId,
  tenantId,
  redirectUri,
  logOutUri,
} = window.igift;
