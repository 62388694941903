import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './hooks/auth';
import { ClientProvider } from './hooks/ClientProvider';
import GraphQLErrorsProvider from './hooks/GraphQLErrorsProvider';
import { ThemeProvider } from 'styled-components';
import { main } from './styles/themes';
import PageContainer from './components/PageContainer';
import { IntlProvider } from './hooks/intl';
import NotificationBanner from './components/NotificationBanner';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <IntlProvider>
        <ThemeProvider theme={main}>
          <GraphQLErrorsProvider>
            <ClientProvider>
              <AuthProvider>
                <NotificationBanner />
                <PageContainer>
                  <App />
                </PageContainer>
              </AuthProvider>
            </ClientProvider>
          </GraphQLErrorsProvider>
        </ThemeProvider>
      </IntlProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
