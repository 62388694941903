import React from 'react';

import { Prompt as IngkaPrompt, PromptProps, ModalBody } from '@ingka/modal';

const Prompt: React.FC<PromptProps> = (props: PromptProps) => (
  <IngkaPrompt {...props} prefix="igift-admin-">
    <ModalBody>{props.children}</ModalBody>
  </IngkaPrompt>
);

export default Prompt;
