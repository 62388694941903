import React, { useEffect, useMemo, useState } from 'react';
import { space300 } from '@ingka/variables/design-tokens';
import { useT } from 'src/hooks/intl';
import { LoadingBall } from '@ingka/loading';
import { useDebounce } from 'src/hooks/useDebounce';
import EmptyState from '../EmptyState';
import { RegistryOrderTable } from './RegistryOrderTable';
import { RegistryOrderTableRow } from './RegistryOrderTableRow';
import * as S from './styles';
import { GiftBag } from 'src/generated/graphql';
import { DeleteGiftBagModal } from './DeleteGiftBagModal';
import { Errors, useErrors } from '../ErrorMessage';
import { Loading } from 'src/skapa';
import { Container } from '../styles';
import useGiftBags from './useGiftBags';
import { EditOrderModal } from './EditOrderModal';

export const RegistryOrders: React.FC = () => {
  const [query, setQuery] = useState<string>('');
  const searchText = useDebounce(query, 1000);
  const { errorsProps, addError } = useErrors();
  const [editOrderModalOpen, setEditOrderModalOpen] = useState<boolean>(false);
  const [giftBag, setGiftBag] = useState<GiftBag>();

  const [deleteGiftBag, setDeleteGiftBag] = useState<{
    isModalOpen: boolean;
    giftBagId: string | undefined;
  }>({ isModalOpen: false, giftBagId: undefined });

  const t = useT();
  const { giftBags, error, loading } = useGiftBags();

  useEffect(() => {
    if (error) {
      addError(t(`error.generic.${error}`));
    }
  }, [error, addError, t]);

  const filteredResults = useMemo(
    () => filterResults(giftBags, searchText),
    [giftBags, searchText]
  );

  useEffect(() => {
    if (giftBag) {
      const current = filteredResults.find((x) => x.id === giftBag.id);
      setGiftBag(current);
    }
  }, [filteredResults, giftBag]);

  if (loading) {
    return (
      <Container margin={space300}>
        <Loading>
          <LoadingBall />
        </Loading>
      </Container>
    );
  }

  const noResults = !filteredResults.length;

  return (
    <>
      {errorsProps && <Errors {...errorsProps} />}
      {!noResults && (
        <S.Search
          id="orderSearch"
          placeholder={t('search.nameOrOrderNum')}
          onChange={(e) => setQuery(e.target.value)}
          onClear={() => setQuery('')}
        />
      )}

      {!noResults && (
        <RegistryOrderTable>
          {filteredResults.map((giftBag: GiftBag) => {
            return (
              <RegistryOrderTableRow
                key={giftBag.id}
                giftBag={giftBag}
                onEditOrder={(e: { preventDefault: () => void }) => {
                  e.preventDefault();
                  setGiftBag(giftBag);
                  setEditOrderModalOpen(true);
                }}
              />
            );
          })}
        </RegistryOrderTable>
      )}

      {noResults && <EmptyState />}

      {giftBag && (
        <EditOrderModal
          open={editOrderModalOpen}
          giftBag={giftBag}
          setOpen={setEditOrderModalOpen}
          onDeleteGiftBag={() => {
            setDeleteGiftBag({
              isModalOpen: true,
              giftBagId: giftBag.id,
            });
          }}
        />
      )}
      <DeleteGiftBagModal
        giftBagId={deleteGiftBag.giftBagId || ''}
        visible={deleteGiftBag.isModalOpen}
        giftBags={giftBags}
        onDeleteSuccess={() => setEditOrderModalOpen(false)}
        onClose={() =>
          setDeleteGiftBag({
            isModalOpen: false,
            giftBagId: undefined,
          })
        }
      />
    </>
  );
};

const filterResults = (items: GiftBag[], searchText: string) => {
  if (items.length > 0 && searchText.length > 0) {
    return items
      .slice()
      .filter(
        (i) =>
          (i.giverName
            ? i.giverName?.toLowerCase().indexOf(searchText.toLowerCase()) > -1
            : i) || i.id.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      );
  }
  return items;
};
