import React from 'react';
import { useUser } from 'src/hooks/auth';
import { useIntl, useT } from 'src/hooks/intl';
import {
  Modal,
  Sheets,
  ModalHeader,
  ChoiceItem,
  Choice,
  space250,
} from 'src/skapa';
import { Title } from '../styles';

interface ICountriesModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const CountriesModal: React.FC<ICountriesModalProps> = ({
  setOpen,
  open,
}: ICountriesModalProps) => {
  const t = useT();
  const { markets, isAuthenticated, changeRetailUnit } = useUser();
  const { setCountry, countries, country } = useIntl();

  const availableCountries = (isAuthenticated ? markets : countries).sort();

  return (
    <Modal
      visible={open}
      escapable={true}
      handleCloseBtn={() => setOpen(false)}
    >
      <Sheets footer={null} header={<ModalHeader />}>
        <Title component="h2" variant="h2" marginBottom={space250}>
          {t('navigation.changeCountry.title')}
        </Title>
        <Choice>
          {availableCountries
            .map((c) => c.toUpperCase())
            .filter((c) => c !== 'XZ')
            .map((c: string) => {
              return (
                <ChoiceItem
                  selected={c === country}
                  id={c}
                  key={c}
                  title={`${c} - ` + t(`countries.${c}`)}
                  onClick={() => {
                    if (isAuthenticated) {
                      changeRetailUnit(c);
                    } else {
                      setCountry(c);
                    }
                    setOpen(false);
                  }}
                />
              );
            })}
        </Choice>
      </Sheets>
    </Modal>
  );
};
