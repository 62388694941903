import { space200 } from '@ingka/variables/design-tokens';
import React, { useMemo, useState } from 'react';
import { RegistryItem } from 'src/generated/graphql';
import { useT } from 'src/hooks/intl';
import { useDebounce } from 'src/hooks/useDebounce';
import EmptyState from '../EmptyState';
import { Container } from '../styles';
import { ProductCard } from './ProductCard';
import * as S from './styles';

interface IProductListProps {
  items: RegistryItem[];
}

export const ProductList: React.FC<IProductListProps> = ({
  items,
}: IProductListProps) => {
  const [query, setQuery] = useState<string>('');
  const searchText = useDebounce(query, 1000);
  const t = useT();
  const filteredResults = useMemo(
    () => filterResults(items, searchText),
    [searchText, items]
  );

  const noResults = !filteredResults.length;

  return (
    <>
      <S.Search
        placeholder={t('search.itemName')}
        id="productSearch"
        onChange={(e) => setQuery(e.target.value)}
        onClear={() => setQuery('')}
      />
      {noResults && (
        <Container paddingTop={space200}>
          <EmptyState />
        </Container>
      )}
      <S.ProductsGrid>
        {filteredResults.map((listItem) => {
          return (
            <ProductCard
              listItem={listItem}
              mainImage="Product"
              key={listItem.itemNo}
            />
          );
        })}
      </S.ProductsGrid>
    </>
  );
};

const filterResults = (items: RegistryItem[], searchText: string) => {
  if (items.length > 0 && searchText.length > 0) {
    return items
      .slice()
      .filter(
        (i) =>
          i.product?.productName &&
          i.product?.productName
            ?.toLowerCase()
            .indexOf(searchText.toLowerCase()) > -1
      );
  }

  return items;
};
