import styled, { css, StyledProps } from 'styled-components';
import { space } from 'styled-system';

const color = (props: StyledProps<{ color?: string }>) => css`
  color: ${props.color
    ? // Not friends with this type 😶
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      props.theme[props.color]
    : props.theme.colourNeutral7};
`;

export const Label = styled('label').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['color'].includes(prop) && defaultValidatorFn(prop),
})<{ color?: string }>`
  font-style: normal;
  font-weight: normal;
  font-size: ${(props) => props.theme.fontSize50};
  line-height: ${(props) => props.theme.lineHeights[0]}rem;
  ${color}
`;

export const Body2 = styled('div').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['color'].includes(prop) && defaultValidatorFn(prop),
})<{ color?: string }>`
  font-style: normal;
  font-weight: normal;
  font-size: ${(props) => props.theme.fontSize75};
  line-height: ${(props) => props.theme.lineHeights[2]}rem;
  letter-spacing: 0;
  ${color}
  ${space}
`;

export const Body1 = styled('div').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['color'].includes(prop) && defaultValidatorFn(prop),
})<{ color?: string; small?: boolean }>`
  font-style: normal;
  font-weight: normal;
  font-size: ${(props) => props.theme.fontSize100};
  line-height: ${(props) => props.theme.lineHeights[3]}rem;
  letter-spacing: 0;
  ${color}
  ${space}

  ${(props) =>
    props.small &&
    `
    font-size: ${props.theme.fontSize75};
    line-height: ${props.theme.lineHeights[2]}rem;
  `}
`;

export const ProductDescription = styled('div').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['color'].includes(prop) && defaultValidatorFn(prop),
})<{ color?: string }>`
  font-style: normal;
  font-weight: normal;
  font-size: ${(props) => props.theme.fontSize100};
  line-height: ${(props) => props.theme.lineHeights[2]}rem;
  ${color}
`;

export const ProductDescription2 = styled('div').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['color', 'marginBottom'].includes(prop) && defaultValidatorFn(prop),
})<{ color?: string; marginBottom?: string }>`
  font-style: normal;
  word-wrap: break-word;
  font-weight: ${(props) => props.theme.fontWeightRegular};
  font-size: ${(props) => props.theme.fontSize75};
  line-height: ${(props) => props.theme.lineHeight75Short};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '0rem'};
  ${color}
`;

export const H1 = styled('h1').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['color'].includes(prop) && defaultValidatorFn(prop),
})<{ color?: string; small?: boolean }>`
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSize800};
  line-height: ${(props) => props.theme.lineHeights[5]}rem;
  letter-spacing: 0;
  ${color}
  ${space}

  ${(props) =>
    props.small &&
    `
    font-size: ${props.theme.fontSize500};
    line-height:  ${props.theme.lineHeights[4]}rem;
    letter-spacing: -0.12;
  `}
`;

export const H2 = styled('h2').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['color'].includes(prop) && defaultValidatorFn(prop),
})<{ color?: string; small?: boolean }>`
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSize400};
  line-height: ${(props) => props.theme.lineHeights[4]}rem;
  letter-spacing: 0;
  ${color}
  ${space}

  ${(props) =>
    props.small &&
    `
    font-size: ${props.theme.fontSize200};
    line-height: ${props.theme.lineHeights[3]}rem;;
  `}
`;

export const H3 = styled('h3').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['color'].includes(prop) && defaultValidatorFn(prop),
})`
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSize100};
  line-height: ${(props) => props.theme.lineHeights[2]}rem;
  letter-spacing: 0;
  ${color}
  ${space}
`;
export const H4 = styled('h4').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['color'].includes(prop) && defaultValidatorFn(prop),
})<{ color?: string }>`
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSize75};
  line-height: ${(props) => props.theme.lineHeights[1]}rem;
  letter-spacing: 0;
  ${color}
  ${space}
`;

export const ProductTitle = styled('div').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['color'].includes(prop) && defaultValidatorFn(prop),
})<{ color?: string }>`
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-size: ${(props) => props.theme.fontSize75};
  line-height: ${(props) => props.theme.lineHeight75Short};
  text-transform: uppercase;
  ${color}
`;
