import { useEffect } from 'react';
import { register, LocaleFunc } from 'timeago.js';

const loadTimeagoLocale = async (languageCode: string): Promise<LocaleFunc> => {
  let language;

  try {
    language = await import(`timeago.js/lib/lang/${languageCode}.js`);
  } catch (error) {
    // Known unsupported markets:
    // sk (Slovakia)
    // hr (Croatia)
    // sl (Slovenia)
    console.error(
      `Missing language import for ${languageCode}. Defaulting to en_US`,
      error
    );
    language = await import('timeago.js/lib/lang/en_US.js');
  }
  return language.default;
};

// timeago.js provides translations in ~45 languages
export const convertLocale = (locale: string): string => {
  const [lang] = locale.split('-');

  switch (lang) {
    case 'en':
      return 'en_US';
    case 'no':
      return 'nb_NO';
    case 'pt':
      return 'pt_BR';
    default:
      return lang;
  }
};

export const useTimeago = (locale: string): void => {
  useEffect(() => {
    const timeagoLocale = convertLocale(locale);

    (async () => {
      const localeFunc = await loadTimeagoLocale(timeagoLocale);

      register(timeagoLocale, localeFunc);
    })();
  }, [locale]);
};
