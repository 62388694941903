import React from 'react';
import { space100, space550 } from '@ingka/variables/design-tokens';
import { useT } from 'src/hooks/intl';
import { Container } from './styles';
import { Heading } from './Typography';
import { Body2 } from './Typography/styles';

interface IEmptyStateProps {
  className?: string;
}

const EmptyState: React.FC<IEmptyStateProps> = ({
  className,
}: IEmptyStateProps) => {
  const t = useT();

  return (
    <Container width="100%" paddingBottom={space550} className={className}>
      <Heading color="colourTextAndIcon1" variant="h2">
        {t('search.emptyResults.title')}
      </Heading>
      <Container marginTop={space100}>
        <Body2>{t('search.emptyResults.info1')}</Body2>
        <Body2>{t('search.emptyResults.info2')}</Body2>
      </Container>
    </Container>
  );
};

export default EmptyState;
