import React from 'react';
import styled from 'styled-components';
import { useGraphQLErrors } from 'src/hooks/GraphQLErrorsProvider';
import { GraphQLError } from 'graphql/error';
import { ServerError, ServerParseError } from '@apollo/client';
import { FullPageError } from './FullPageError';
import { useT } from 'src/hooks/intl';

const List = styled.ul`
  display: inline-flex;
  flex-direction: column;
  text-align: left;
  bacgrkound-color: red;
  font-size: ${(props) => props.theme.fontSize75};
`;

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.space125};
  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    padding: ${(props) => props.theme.space200};
  }
  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    padding: ${(props) => props.theme.space300};
  }
`;

export const GqlError: React.FC = () => {
  const { errors, gqlErrors } = useGraphQLErrors();
  const t = useT();

  return (
    <PageContainer>
      <FullPageError
        title={t('error.generic.title')}
        description={t('error.unrecoverable.description')}
      >
        <List>
          {gqlErrors.map((error: GraphQLError, i: number) => (
            <React.Fragment key={`err_${i}_gql`}>
              <li>{error.extensions?.code}</li>
              <li>{error.message}</li>
              <li>{error.path?.map((p) => p).join(', ')}</li>
            </React.Fragment>
          ))}
          {errors.map(
            (error: Error | ServerError | ServerParseError, i: number) => (
              <React.Fragment key={`err_${i}`}>
                <li>{error.name}</li>
                <li>{error.message}</li>
                <li>{error.stack}</li>
              </React.Fragment>
            )
          )}
        </List>
      </FullPageError>
    </PageContainer>
  );
};
