import React, { useEffect, ReactNode, useState } from 'react';
import chevronRight from '@ingka/ssr-icon/paths/chevron-right';
import chevronLeft from '@ingka/ssr-icon/paths/chevron-left';
import chevronDoubleRight from '@ingka/ssr-icon/paths/chevron-double-right';
import chevronDoubleLeft from '@ingka/ssr-icon/paths/chevron-double-left';
import DatePickerComponent, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FormField, InputField, Loading, Button } from 'src/skapa';
import { Body1 } from '../Typography';
import styled from 'styled-components';
import { useIntl, useT } from 'src/hooks/intl';
import { LoadingBall } from '@ingka/loading';
import './_date_picker.scss';
import { handleKeyboardEvent } from 'src/utils/keyboardEvents';
import { onRegisterLocale, getValidation } from './helpers';

const monthKeys = [
  'calendar.month.january',
  'calendar.month.february',
  'calendar.month.march',
  'calendar.month.april',
  'calendar.month.may',
  'calendar.month.june',
  'calendar.month.july',
  'calendar.month.august',
  'calendar.month.september',
  'calendar.month.october',
  'calendar.month.november',
  'calendar.month.december',
];

interface OwnProps {
  label: string;
  date: Date;
  locale: string;
}

type DatePickerProps = OwnProps & ReactDatePickerProps;

export const DatePicker: React.FC<DatePickerProps> = (
  props: DatePickerProps
) => {
  const { date, label } = props;
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    /* This locale decides which day is the first day of the week
      and provides needed translations.
    */
    (async () => {
      const localeModule = await onRegisterLocale(props.locale);
      if (localeModule) {
        registerLocale(props.locale, localeModule.default);
        setLoaded(true);
      } else {
        throw new Error('Unable to register locale: ' + props.locale);
      }
    })();
  }, [props.locale]);

  if (!loaded) {
    return (
      <Loading>
        <LoadingBall />
      </Loading>
    );
  }

  return (
    <DatePickerComponent
      calendarContainer={(props) => <CustomContainer {...props} />}
      customInput={<CustomInput label={label} date={date} />}
      dayClassName={() => 'igift-calendar-day'}
      calendarClassName={
        props.inline ? 'igift-datepicker inline' : 'igift-datepicker'
      }
      wrapperClassName="igift-datepicker-input-wrapper"
      renderCustomHeader={(props) => <CustomHeader {...props} />}
      {...props}
    />
  );
};

const CustomInput = (props: {
  label?: string;
  value?: string;
  date?: Date;
  onClick?: () => void;
  onChange?: () => void;
  onFocus?: () => void;
}) => {
  const t = useT();
  const { label, onChange, onClick, value, onFocus, date } = props;
  const { locale } = useIntl();
  const { isValid, msgKey } = getValidation(value || '', date || new Date());

  return (
    <FormField
      validation={{ msg: msgKey ? t(msgKey) : '', id: msgKey }}
      shouldValidate={Boolean(value)}
      valid={isValid}
    >
      <InputField
        id="input-date"
        label={label}
        type="string"
        value={value ? new Date(value).toLocaleDateString(locale) : undefined}
        onChange={onChange}
        onFocus={onFocus}
        onClick={onClick}
        onKeyDown={onClick && handleKeyboardEvent(['enter', 'space'], onClick)}
      />
    </FormField>
  );
};

interface ICustomContainerProps {
  className?: string;
  children?: ReactNode;
}

const CustomContainer: React.FC<ICustomContainerProps> = ({
  className,
  children,
}: ICustomContainerProps) => {
  return (
    <CustomContainerWrapper className={className}>
      <div>{children}</div>
    </CustomContainerWrapper>
  );
};

type ICustomHeaderProps = {
  date: Date;
  customHeaderCount: number;
  decreaseMonth(): void;
  increaseMonth(): void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  decreaseYear(): void;
  increaseYear(): void;
  prevYearButtonDisabled: boolean;
  nextYearButtonDisabled: boolean;
};

const CustomHeader: React.FC<ICustomHeaderProps> = ({
  date,
  decreaseMonth,
  increaseMonth,
  decreaseYear,
  increaseYear,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  prevYearButtonDisabled,
  nextYearButtonDisabled,
}: ICustomHeaderProps) => {
  const t = useT();
  const year = date.getFullYear();
  const month = t(monthKeys[date.getMonth()]);

  return (
    <CustomHeaderWrapper>
      <ArrowButton
        aria-label={t('calendar.year.prev')}
        disabled={prevYearButtonDisabled}
        iconOnly
        inverseTheme
        small
        ssrIcon={chevronDoubleLeft}
        type="primary"
        xsmallIconOnly
        onClick={decreaseYear}
      />
      <ArrowButton
        aria-label={t('calendar.month.prev')}
        disabled={prevMonthButtonDisabled}
        iconOnly
        inverseTheme
        small
        ssrIcon={chevronLeft}
        type="primary"
        xsmallIconOnly
        onClick={decreaseMonth}
      />
      <Selection color="colourTextAndIcon1">
        {t('calendar.date', { year: year, month: month })}
      </Selection>
      <ArrowButton
        aria-label={t('calendar.month.next')}
        disabled={nextMonthButtonDisabled}
        iconOnly
        inverseTheme
        small
        ssrIcon={chevronRight}
        type="primary"
        xsmallIconOnly
        onClick={increaseMonth}
      />
      <ArrowButton
        aria-label={t('calendar.year.next')}
        disabled={nextYearButtonDisabled}
        iconOnly
        inverseTheme
        small
        ssrIcon={chevronDoubleRight}
        type="primary"
        xsmallIconOnly
        onClick={increaseYear}
      />
    </CustomHeaderWrapper>
  );
};

const CustomContainerWrapper = styled.div`
  display: flex;
  border: none;
`;

const CustomHeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: ${(props) => props.theme.space150} 0
    ${(props) => props.theme.space150} 0;
`;

const ArrowButton = styled(Button)`
  span {
    color: ${(props) => props.theme.colourNeutral7};
  }
  &[disabled] {
    span {
      color: ${(props) => props.theme.colourNeutral5};
      cursor: not-allowed;
    }
  }
`;

const Selection = styled(Body1)`
  width: 7.45rem;
`;
