import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ReactComponent as Logo } from '../../images/logo.svg';
import globe from '@ingka/ssr-icon/paths/globe';
import text from '@ingka/ssr-icon/paths/text';
import accountIcon from '@ingka/ssr-icon/paths/person';
import { Button } from 'src/skapa';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import { loginRequest, useUser } from 'src/hooks/auth';
import { ProfileModal } from './ProfileModal';
import { Role } from 'src/hooks/auth';
import { useIntl, useT } from 'src/hooks/intl';
import { LanguagesModal } from './LanguagesModal';
import { CountriesModal } from './CountriesModal';
import RoleTemplate from 'src/hooks/auth/RoleTemplate';
import { useBreakpoint } from '@ingka/viewport-hooks';

export const Navigation: React.FC = () => {
  const t = useT();
  const { language, country } = useIntl();
  const { instance } = useMsal();
  const { name } = useUser();
  const [profileOpen, setProfileOpen] = useState<boolean>(false);
  const [languagesOpen, setLanguagesOpen] = useState<boolean>(false);
  const [countriesOpen, setCountriesOpen] = useState<boolean>(false);
  const isMobile = useBreakpoint('sm', 'md') === 'sm';

  return (
    <>
      <NavigationContainer>
        <NavigationLinksContainer>
          <Logo className="logo" />
          <RoleTemplate approvedRoles={[Role.RegistryRead]}>
            <NavLink
              to={{
                pathname: '/registries',
                state: { clearRegistrySearch: true },
              }}
              activeStyle={{
                textDecoration: 'underline',
              }}
            >
              {t('crumbs.registries')}
            </NavLink>
          </RoleTemplate>
          <RoleTemplate approvedRoles={[Role.AuditLogRead]}>
            <NavLink
              to="/audit-log"
              activeStyle={{
                textDecoration: 'underline',
              }}
            >
              {t('crumbs.log')}
            </NavLink>
          </RoleTemplate>
        </NavigationLinksContainer>
        <NavigationButtonsContainer>
          <Button
            small
            ssrIcon={globe}
            text={t(`countries.${country}`)}
            type="secondary"
            onClick={() => setCountriesOpen(!countriesOpen)}
            iconOnly={isMobile}
          />
          <Button
            small
            ssrIcon={text}
            text={t(`languages.${language}`)}
            type="secondary"
            onClick={() => setLanguagesOpen(!languagesOpen)}
            iconOnly={isMobile}
          />
          <UnauthenticatedTemplate>
            <Button
              small
              ssrIcon={accountIcon}
              text={t('signIn')}
              type="primary"
              onClick={() => instance.loginRedirect(loginRequest)}
              iconOnly={isMobile}
            />
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <Button
              small
              ssrIcon={accountIcon}
              text={name}
              type="secondary"
              onClick={() => setProfileOpen(!profileOpen)}
              iconOnly={isMobile}
            />
          </AuthenticatedTemplate>
        </NavigationButtonsContainer>
      </NavigationContainer>
      <AuthenticatedTemplate>
        <ProfileModal open={profileOpen} setOpen={setProfileOpen} />
      </AuthenticatedTemplate>
      <LanguagesModal open={languagesOpen} setOpen={setLanguagesOpen} />
      <CountriesModal open={countriesOpen} setOpen={setCountriesOpen} />
    </>
  );
};

const NavigationLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: ${(props) => props.theme.space550};

  a,
  a:link,
  a:visited {
    font-size: ${(props) => props.theme.fontSize75};
    line-height: ${(props) => props.theme.space300};
    color: ${(props) => props.theme.colourNeutral7};
    text-decoration: none;
    display: block;
    padding-top: ${(props) => props.theme.space50};
    padding-bottom: ${(props) => props.theme.space50};
    padding-left: ${(props) => props.theme.space100};
    padding-right: ${(props) => props.theme.space100};
    font-weight: ${(props) => props.theme.fontWeightBold};
  }
  .logo {
    margin-right: ${(props) => props.theme.space400};
  }
`;

const NavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    flex-direction: row;
  }
`;

const NavigationButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  button {
    margin-right: ${(props) => props.theme.space100};
    height: 40px;
  }

  button:last-child {
    margin-right: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    justify-content: flex-end;
  }
`;
