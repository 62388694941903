import React from 'react';

import IngkaHyperlink, { HyperlinkProps } from '@ingka/hyperlink';

export const Hyperlink: React.FC<
  Omit<HyperlinkProps, 'ref'> & {
    ref?: React.Ref<HTMLAnchorElement>;
  }
  // eslint-disable-next-line react/display-name
> = React.forwardRef((props, ref) => (
  <IngkaHyperlink {...props} prefix="igift-admin-" ref={ref} />
));
