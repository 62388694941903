import React from 'react';
import { SSRIcon } from 'src/skapa';
import brokenImage from '@ingka/ssr-icon/paths/image-strikethrough';
import * as S from './styles';

export const ProductEmptyImage: React.FC = () => {
  return (
    <S.EmptyImageContainer>
      <S.EmptyImage>
        <SSRIcon paths={brokenImage} viewBox="0 0 34 34" />
      </S.EmptyImage>
    </S.EmptyImageContainer>
  );
};
