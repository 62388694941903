import React from 'react';
import { InputField } from 'src/skapa';
import { useT } from 'src/hooks/intl';
import { handleKeyboardEvent } from 'src/utils/keyboardEvents';

import { CustomerSearchContainer } from './index';

interface ICustomerIdentifierInputFieldProps {
  loading: boolean;
  setFormValid: (v: boolean) => void;
  onSearch: () => void;
  customerIdentifier: string;
  setCustomerIdentifier: (identifier: string) => void;
}

export const CustomerIdentifierInputField: React.FC<
  ICustomerIdentifierInputFieldProps
> = ({
  loading,
  setFormValid,
  onSearch,
  customerIdentifier,
  setCustomerIdentifier,
}: ICustomerIdentifierInputFieldProps) => {
  const t = useT();
  const MAX_EMAIL_LENGTH = 320; // theoretical max email char limit: {64}@{255}

  const customerIdentifierLabel = [
    t('email'),
    t('customerSearch.mobile'),
    t('customerSearch.partyUId'),
    t('customerSearch.familyNr'),
  ].join(', ');

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newIdentifier = e.target.value;
    if (newIdentifier.length <= MAX_EMAIL_LENGTH) {
      setFormValid(newIdentifier.trim().length > 0);
      setCustomerIdentifier(newIdentifier);
    }
  };

  return (
    <CustomerSearchContainer>
      <InputField
        disabled={loading}
        type="text"
        id="customerIdentifier"
        label={customerIdentifierLabel}
        req
        value={customerIdentifier}
        onChange={onChange}
        onKeyDown={handleKeyboardEvent(['enter'], onSearch)}
      />
    </CustomerSearchContainer>
  );
};
