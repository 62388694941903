import React from 'react';

import IngkaActionList, {
  ActionListProps,
  ActionListItem as IngkaActionListItem,
  ActionListItemProps,
} from '@ingka/action-list';

export const ActionList: React.FC<ActionListProps> = (props) => (
  <IngkaActionList {...props} prefix="igift-admin-" />
);

export const ActionListItem: React.FC<ActionListItemProps> = (props) => (
  <IngkaActionListItem {...props} prefix="igift-admin-" />
);
