import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import React from 'react';
import { loginRequest } from 'src/hooks/auth';
import { useT } from 'src/hooks/intl';
import { LoginButton } from '../../pages';
import { Modal, Sheets, ModalHeader, ModalFooter } from 'src/skapa';

interface IProfileModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const ProfileModal: React.FC<IProfileModalProps> = ({
  setOpen,
  open,
}: IProfileModalProps) => {
  const t = useT();
  const { instance } = useMsal();

  return (
    <Modal
      visible={open}
      escapable={true}
      handleCloseBtn={() => setOpen(false)}
    >
      <Sheets
        footer={
          <ModalFooter>
            <LoginButton
              text={t('navigation.change')}
              type="secondary"
              onClick={() =>
                instance.loginRedirect({
                  ...loginRequest,
                  prompt: 'select_account',
                })
              }
            />
            <AuthenticatedTemplate>
              <LoginButton
                text={t('signOut')}
                type="primary"
                onClick={() => instance.logout()}
              />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <LoginButton
                text={t('signOut')}
                type="primary"
                onClick={() => instance.loginRedirect(loginRequest)}
              />
            </UnauthenticatedTemplate>
          </ModalFooter>
        }
        header={<ModalHeader />}
      ></Sheets>
    </Modal>
  );
};
