import { useCallback, useEffect, useState } from 'react';
import { splitPayload } from 'src/utils/handleUnions';
import {
  CustomerInfo,
  useCustomerIdentifierSearchLazyQuery,
} from 'src/generated/graphql';

interface UseUserProps {
  loadingCustomerSearch: boolean;
  selectedCustomer: CustomerInfo | undefined;
  setSelectedCustomer: (c: CustomerInfo | undefined) => void;
}

function useUser({
  selectedUserId,
  onError,
}: {
  selectedUserId: string | undefined;
  onError: (err: string) => void;
}): UseUserProps {
  const [selectedCustomer, setSelectedCustomer] = useState<
    CustomerInfo | undefined
  >();

  const [customerIdentifierSearch, { loading: loadingCustomerSearch }] =
    useCustomerIdentifierSearchLazyQuery({
      onCompleted: (data) => {
        const [success, partialError] = splitPayload(
          'CustomerIdentifierSearchResultPayload',
          data?.customerIdentifierSearch
        );
        if (success?.items) {
          const [user] = success.items;
          if (user) {
            setSelectedCustomer(user);
          }
        }
        if (partialError) {
          onError(`error.generic.${partialError}`);
        }
      },
    });

  const getCustomerNameById = useCallback(
    (userId: string) => {
      customerIdentifierSearch({
        variables: {
          identifier: userId,
        },
      });
    },
    [customerIdentifierSearch]
  );

  useEffect(() => {
    if (!selectedUserId) return;
    getCustomerNameById(selectedUserId);
  }, [selectedUserId, getCustomerNameById]);

  return {
    loadingCustomerSearch,
    selectedCustomer,
    setSelectedCustomer,
  };
}

export default useUser;
