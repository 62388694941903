import React from 'react';
import { ErrorMessage } from './ErrorMessage';
import { InlineMessage as Component } from 'src/skapa';
import styled from 'styled-components';

const InlineMessage = styled(Component)`
  margin-bottom: ${(props) => props.theme.space150};
`;

export interface ErrorsProps {
  errors: ErrorMessage[];
  dismissError: (errorId: string) => void;
}

export const Errors: React.FC<ErrorsProps> = ({
  errors,
  dismissError,
}: ErrorsProps) => {
  if (errors.length === 0) {
    return null;
  }

  return (
    <ErrorContainer>
      {errors.map((e) => (
        <InlineMessage
          key={e.id}
          title={e.message}
          variant="negative"
          dismissable
          onDismissClick={() => dismissError(e.id)}
        />
      ))}
    </ErrorContainer>
  );
};

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${(props) => props.theme.space150} 0;
`;
