import styled from 'styled-components';

export * from './RegistrySearch';

export const SearchContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  width: 100%;
  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    max-width: 24rem;
  }
`;

interface ISearchInputsProps {
  showAdvanced: boolean;
}

export const SearchButtonContainer = styled.div<ISearchInputsProps>`
  ${(props) =>
    props.showAdvanced
      ? `margin-top: 0`
      : `margin-top: ${props.theme.space125}`};
  > * {
    margin-top: ${(props) => props.theme.space125};
  }
`;

export const SearchInputs = styled.div<ISearchInputsProps>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space125};
`;

export const CustomerSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: ${(props) => props.theme.space125};
`;

export const valueOrUndefined = (value?: string): string | undefined => {
  return value && value.length > 0 ? value : undefined;
};

export const ResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space125};
`;
