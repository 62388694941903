import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useRemoveRegistryMutation } from 'src/generated/graphql';
import { useT } from 'src/hooks/intl';
import { useRegistrySearchInput } from 'src/hooks/RegistrySearchInputProvider';
import { splitPayload } from 'src/utils/handleUnions';
import { Errors, useErrors } from '../ErrorMessage';
import { Button, Checkbox } from 'src/skapa';
import { ModalFooter, Prompt, Modal, FormField, ModalHeader } from 'src/skapa';

interface IDeletePopupProps {
  open: boolean;
  registryId: string;
  registryOwner: string;
  setOpen: (open: boolean) => void;
  registryTitle: string;
}

export const DeletePopup: React.FC<IDeletePopupProps> = ({
  open,
  registryId,
  setOpen,
  registryTitle,
}: IDeletePopupProps) => {
  const t = useT();
  const [removeRegistryMutation, { loading: loadingRegistry }] =
    useRemoveRegistryMutation();
  const { push } = useHistory();
  const [deleteChecked, setDeleteChecked] = useState<boolean>(false);
  const [shouldValidate, setShouldValidate] = useState<boolean>(false);
  const { removeRegistryFromResults } = useRegistrySearchInput();
  const { errorsProps, addError } = useErrors();

  const handleRemove = async () => {
    setShouldValidate(true);
    if (!deleteChecked) {
      return;
    }
    const { data } = await removeRegistryMutation({
      variables: {
        input: { registryId: registryId },
      },
      awaitRefetchQueries: true,
    });

    const [success, partialError] = splitPayload(
      'RemoveRegistryPayload',
      data?.removeRegistry
    );

    if (partialError) {
      addError(t(`error.generic.${partialError}`));
    }

    if (success?.success) {
      removeRegistryFromResults(registryId);

      close();
      push(`/registries`);
    }
  };

  const close = () => {
    setShouldValidate(false);
    setDeleteChecked(false);
    setOpen(false);
  };

  const checkBoxChange = () => {
    setShouldValidate(true);
    setDeleteChecked(!deleteChecked);
  };

  return (
    <Modal visible={open} escapable handleCloseBtn={close}>
      <Prompt
        title={t('registry.delete.heading')}
        header={<ModalHeader />}
        footer={
          <ModalFooter>
            <Button
              loading={loadingRegistry}
              text={t('registry.delete.button')}
              type="danger"
              onClick={handleRemove}
            />
          </ModalFooter>
        }
      >
        <div>
          {errorsProps && <Errors {...errorsProps} />}
          <p>
            {t('registry.delete.content', {
              registryTitle,
            })}
          </p>

          <FormField
            shouldValidate={shouldValidate}
            valid={deleteChecked}
            validation={{
              msg: t('registry.delete.checkBoxError'),
              id: 'delete-registry',
            }}
          >
            <Checkbox
              label={t('registry.delete.label')}
              id="delete-registry"
              onChange={checkBoxChange}
              value="delete-registry"
            />
          </FormField>
        </div>
      </Prompt>
    </Modal>
  );
};
