import { PriceProps } from '@ingka/price';

export enum CURRENCY_SYMBOLS {
  EURO = '€',
  DOLLAR_SIGN = '$',
  SWEDISH_KRONA = ':-',
  DANISH_KRONA = '.-',
  KRONE = ',-',
  CZECH_KORUNA = 'Kč',
  BRITISH_POUND = '£',
  HUNGARIAN_FORINT = 'Ft',
  JAPANESE_YEN = '¥',
  SOUTH_KOREAN_WON = '₩',
  SERBIAN_DINAR = 'RSD',
  ROMANIAN_LEU = 'lei',
  SWISS_FRANC = 'CHF',
  EMPTY = '',
}

export enum DECIMAL_SIGNS {
  COMMA = ',',
  DOT = '.',
  EMPTY = '',
}

export enum THOUSAND_SEPARATORS {
  COMMA = ',',
  DOT = '.',
  SPACE = ' ',
  APOSTROPHE = '’',
}

export type PriceFormatProps = Pick<
  PriceProps,
  | 'currencyLabel'
  | 'currencyPosition'
  | 'decimalValue'
  | 'decimalSign'
  | 'integerValue'
> & { currencyLabel: CURRENCY_SYMBOLS };
