import React from 'react';

import { LoadingBall } from '@ingka/loading';
import EmptyState from '../EmptyState';
import { AuditLogEventFragment } from '../../generated/graphql';
import { AuditLogResult } from './AuditLogResult';
import { useT } from 'src/hooks/intl';
import { Table, TableBody, TableHeader, Loading } from 'src/skapa';
import * as S from './styles';

interface IAuditLogResultsProps {
  events: AuditLogEventFragment[];
  loading: boolean;
  hasSearched: boolean;
  onSearchByUserId: (userId: string) => void;
}
export const AuditLogResults: React.FC<IAuditLogResultsProps> = ({
  events,
  loading,
  hasSearched,
  onSearchByUserId,
}: IAuditLogResultsProps) => {
  const t = useT();

  const noResults = hasSearched && !events.length && !loading;

  if (loading && !events.length) {
    return (
      <S.LoadingContainer>
        <Loading>
          <LoadingBall />
        </Loading>
      </S.LoadingContainer>
    );
  }

  return (
    <>
      {!noResults && (
        <S.StyledCarousel>
          <Table inset fullWidth>
            {hasSearched && (
              <TableHeader sticky>
                <tr>
                  <th>
                    <S.TableHeadColumn>
                      {t('log.headings.userType')}
                    </S.TableHeadColumn>
                  </th>
                  <th style={{ zIndex: 1 }}>
                    <S.TableHeadColumn>
                      {t('log.headings.userId')}
                    </S.TableHeadColumn>
                  </th>
                  <th>
                    <S.TableHeadColumn>
                      {t('log.headings.interaction')}
                    </S.TableHeadColumn>
                  </th>
                  <th>
                    <S.TableHeadColumn>
                      {t('log.headings.source')}
                    </S.TableHeadColumn>
                  </th>
                  <th>
                    <S.TableHeadColumn>
                      {t('log.headings.details')}
                    </S.TableHeadColumn>
                  </th>
                  <th>
                    <S.TableHeadColumn>
                      {t('log.headings.registry')}
                    </S.TableHeadColumn>
                  </th>
                  <th>
                    <S.TableHeadColumn>
                      {t('log.headings.time')}
                    </S.TableHeadColumn>
                  </th>
                  <th>
                    <S.TableHeadColumn>
                      {t('log.headings.timeStamp')}
                    </S.TableHeadColumn>
                  </th>
                </tr>
              </TableHeader>
            )}
            <TableBody striped>
              {events.map((le, i: number) => (
                <AuditLogResult
                  key={`${i}_logEvent`}
                  event={le}
                  showRegistry={true}
                  showUserId={true}
                  onSearchByUserId={onSearchByUserId}
                />
              ))}
            </TableBody>
          </Table>
        </S.StyledCarousel>
      )}
      {noResults && <EmptyState />}
    </>
  );
};
