import React from 'react';
import IngkaTable, {
  TableProps,
  TableHeader as IngkaTableHeader,
  TableBody as IngkaTableBody,
  TableBodyProps,
  TableHeaderProps,
} from '@ingka/table';

export const Table: React.FC<TableProps> = (props: TableProps) => (
  <IngkaTable {...props} prefix="igift-admin-" />
);

export const TableHeader: React.FC<TableHeaderProps> = (
  props: TableHeaderProps
) => <IngkaTableHeader {...props} prefix="igift-admin-" />;

export const TableBody: React.FC<TableBodyProps> = (props: TableBodyProps) => (
  <IngkaTableBody {...props} prefix="igift-admin-" />
);
