import { useState } from 'react';
import { useLocation } from 'react-router';
import { showTranslationKeys } from 'src/utils/settings';

export const useShowKeys = (): boolean => {
  const { search } = useLocation();

  const [showKeys] = useState(() => {
    const urlSearchParams = new URLSearchParams(search);
    const translationMode = urlSearchParams.get('translationMode');

    return showTranslationKeys || translationMode === 'keys';
  });

  return showKeys;
};
