import React from 'react';

import { Sheets as IngkaSheets, SheetsProps, ModalBody } from '@ingka/modal';

const Sheets: React.FC<SheetsProps> = (props: SheetsProps) => (
  <IngkaSheets {...props} prefix="igift-admin-">
    <ModalBody>{props.children}</ModalBody>
  </IngkaSheets>
);

export default Sheets;
