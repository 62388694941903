import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { GraphQLError } from 'graphql/error/GraphQLError';
import { ServerError, ServerParseError } from '@apollo/client';
import { GqlError } from 'src/components/HandleErrors';
export interface GraphQLErrorState {
  errors: (Error | ServerError | ServerParseError)[];
  gqlErrors: GraphQLError[];
  setGqlErrors?: (errors: GraphQLError[]) => void;
  setErrors?: (errors: (Error | ServerError | ServerParseError)[]) => void;
  closeErrors?: () => void;
}

const defaultState: GraphQLErrorState = { errors: [], gqlErrors: [] };

export const GraphQLErrorsContext = createContext<GraphQLErrorState>(
  defaultState
);

export const useGraphQLErrors = (): GraphQLErrorState =>
  useContext(GraphQLErrorsContext);

interface GraphQLErrorsProviderProps {
  children: ReactNode;
}

const GraphQLErrorsProvider: React.FC<GraphQLErrorsProviderProps> = ({
  children,
}: GraphQLErrorsProviderProps) => {
  const [state, setState] = useState<GraphQLErrorState>(defaultState);

  const onSetErrors = useCallback(
    (errors: (Error | ServerError | ServerParseError)[]) => {
      setState({
        ...state,
        errors,
      });
    },
    [state]
  );
  const onSetGqlErrors = useCallback(
    (gqlErrors: GraphQLError[]) => {
      setState({
        ...state,
        gqlErrors,
      });
    },
    [state]
  );
  const onCloseErrors = useCallback(() => {
    setState({
      ...state,
      gqlErrors: [],
      errors: [],
    });
  }, [state]);

  const showErrors = state.gqlErrors.length > 0 || state.errors.length > 0;

  return (
    <GraphQLErrorsContext.Provider
      value={{
        errors: state.errors,
        gqlErrors: state.gqlErrors,
        setGqlErrors: onSetGqlErrors,
        setErrors: onSetErrors,
        closeErrors: onCloseErrors,
      }}
    >
      {showErrors ? <GqlError /> : children}
    </GraphQLErrorsContext.Provider>
  );
};

export default GraphQLErrorsProvider;
