import React from 'react';
import { space100 } from '@ingka/variables/design-tokens';
import { useRemoveGiftBagItemsMutation } from 'src/generated/graphql';
import { splitPayload } from 'src/utils/handleUnions';
import { useRegistry } from 'src/hooks/RegistryProvider';
import { useIntl, useT } from 'src/hooks/intl';
import { Errors, useErrors } from '../ErrorMessage';
import { Button, ModalFooter, Prompt, Modal, ModalHeader } from 'src/skapa';
import { Body2 } from '../Typography';

interface IDeleteItemModalProps {
  itemNo: string;
  giftBagId: string;
  visible: boolean;
  onClose: () => void;
}

export const DeleteItemModal: React.FC<IDeleteItemModalProps> = ({
  giftBagId,
  itemNo,
  visible,
  onClose,
}: IDeleteItemModalProps) => {
  const t = useT();
  const { errorsProps, addError } = useErrors();
  const { registry } = useRegistry();
  const { language } = useIntl();
  const [removeGiftBagItemsMutation, { loading }] =
    useRemoveGiftBagItemsMutation();

  const onRemove = async () => {
    const { data } = await removeGiftBagItemsMutation({
      variables: {
        removeGiftBagItemsInput: {
          giftBagId,
          itemNos: [itemNo],
          registryId: registry.id,
        },
        languageCode: language,
      },
    });

    const [, partialError] = splitPayload(
      'RemoveGiftBagItemsPayload',
      data?.removeGiftBagItems
    );

    if (partialError) {
      addError(t(`error.generic.${partialError}`));
    } else {
      onClose();
    }
  };

  return (
    <Modal visible={visible} escapable={true} handleCloseBtn={onClose}>
      <Prompt
        title={t('order.deleteGiftBagItemModal.title')}
        header={<ModalHeader />}
        footer={
          <ModalFooter>
            <Button
              loading={loading}
              text={t('order.deleteGiftBagItemModal.confirmButton')}
              type="danger"
              disabled={!registry}
              onClick={onRemove}
            ></Button>
          </ModalFooter>
        }
      >
        <Body2 paddingTop={space100}>
          {t('order.deleteGiftBagItemModal.content')}
        </Body2>
        {errorsProps && <Errors {...errorsProps} />}
      </Prompt>
    </Modal>
  );
};
