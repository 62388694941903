import React from 'react';
import { SEO } from 'src/components/SEO';
import { Container, Content } from 'src/components/styles';
import { Body2, Heading } from 'src/components/Typography';
import { space100, space250 } from '@ingka/variables';

import { RETAIL_UNIT_KEY, useT } from 'src/hooks/intl';
import { Crumbler } from '../Crumbler';
import { getStorageKey } from 'src/hooks/useLocalStorage';

interface FullPageErrorProps {
  title: string;
  description: string;
  children?: React.ReactNode;
}

export const FullPageError: React.FC<FullPageErrorProps> = ({
  children,
  title,
  description,
}: FullPageErrorProps) => {
  const t = useT();

  localStorage.removeItem(getStorageKey(RETAIL_UNIT_KEY));
  return (
    <>
      <SEO title={`404 - ${t('error.generic.title')}`} />
      <Crumbler crumbs={['/registries', '/404']} />
      <Content>
        <Heading variant="h1">{title}</Heading>
        <Body2 marginTop={space100}>{description}</Body2>
        <Container marginTop={space250}>{children}</Container>
      </Content>
    </>
  );
};
