import { AccountInfo } from '@azure/msal-browser';
import { MsalProvider, useMsal } from '@azure/msal-react';
import React from 'react';
import { msalInstance } from './msal';
import { UserProvider } from './UserProvider';

interface IAuthProviderProps {
  children: React.ReactNode;
}

export const useMsalAccount = (): AccountInfo | null => {
  const { instance } = useMsal();
  return instance.getActiveAccount();
};

export const AuthProvider: React.FC<IAuthProviderProps> = ({
  children,
}: IAuthProviderProps) => {
  return (
    <MsalProvider instance={msalInstance}>
      <UserProvider>{children}</UserProvider>
    </MsalProvider>
  );
};
