import { useCallback, useState } from 'react';
import { useT } from 'src/hooks/intl';
import { ErrorMessage } from './ErrorMessage';
import { ErrorsProps } from './Errors';

interface UseErrors {
  hasErrors: boolean;
  errorsProps: ErrorsProps;
  addError: (message: string, variables?: { [key: string]: string }) => void;
  clearErrors: () => void;
}

export const useErrors = (): UseErrors => {
  const [errors, setErrors] = useState<ErrorMessage[]>([]);
  const t = useT();

  const dismissError = useCallback(
    (errorId: string) =>
      setErrors((errors) => errors.filter(({ id }) => errorId !== id)),
    []
  );

  const clearErrors = useCallback(() => setErrors([]), []);

  const addError = useCallback(
    (errorKey: string, variables?: { [key: string]: string }) => {
      setErrors((errors) => {
        return [
          {
            id: `${errorKey}_${Date.now()}`,
            message: t(errorKey, variables),
          },
          ...errors,
        ];
      });
    },
    [t]
  );

  return {
    hasErrors: errors.length > 0,
    errorsProps: {
      errors,
      dismissError,
    },
    addError,
    clearErrors,
  };
};
