import React, { useCallback, useState } from 'react';
import { Button } from 'src/skapa';
import searchBrowse from '@ingka/ssr-icon/paths/magnifying-glass-list';
import { AuditLogInput, LogFiltering } from '../../generated/graphql';
import { SearchInputs, SearchButtonContainer, SearchContent } from '.';
import { CustomerSearchModal } from './CustomerSearchModal';
import { useT } from 'src/hooks/intl';
import { DateInputField } from './DateInputField';
import { Errors, ErrorsProps } from '../ErrorMessage';
import useUser from './useUser';
import ClearableInputField from '../ClearableInputField';

interface IAuditLogSearchProps {
  errorsProps?: ErrorsProps;
  loading: boolean;
  selectedUserId: string | undefined;
  setSelectedUserId: (userId: string | undefined) => void;
  addError: (message: string, variables?: { [key: string]: string }) => void;
  onSearch: (input: AuditLogInput) => void;
  clearResults: () => void;
}

export const AuditLogSearch: React.FC<IAuditLogSearchProps> = ({
  errorsProps,
  loading,
  selectedUserId,
  setSelectedUserId,
  addError,
  onSearch,
  clearResults,
}: IAuditLogSearchProps) => {
  const t = useT();

  const [findCustomerModalOpen, setFindCustomerModalOpen] =
    useState<boolean>(false);

  const [inputState, setInputState] = useState<LogFiltering>();

  const { selectedCustomer, setSelectedCustomer } = useUser({
    selectedUserId,
    onError: useCallback(
      (err) => {
        addError(t(err));
      },
      [addError, t]
    ),
  });

  const onReset = () => {
    setInputState(undefined);
    setSelectedCustomer(undefined);
    setSelectedUserId(undefined);
    clearResults();
  };

  const onLogSearch = (): void => {
    const id = selectedCustomer?.partyUId;
    const input: AuditLogInput = {
      filtering: {
        ...inputState,
        userId: id || selectedUserId,
      },
    };

    onSearch(input);
  };

  return (
    <>
      <SearchContent>
        <SearchInputs showAdvanced={false}>
          {errorsProps && <Errors {...errorsProps} />}
          <DateInputField
            fieldName={t('auditLogSearch.startDate')}
            value={inputState?.dateRange?.startDate || null}
            setValue={(date: Date | null) => {
              setInputState({
                ...inputState,
                dateRange: {
                  ...inputState?.dateRange,
                  startDate: date || undefined,
                },
              });
            }}
            disabled={loading}
            onKeyDown={onLogSearch}
            showInline={false}
          />
          <DateInputField
            fieldName={t('auditLogSearch.endDate')}
            value={inputState?.dateRange?.endDate || null}
            setValue={(date: Date | null) => {
              setInputState({
                ...inputState,
                dateRange: {
                  ...inputState?.dateRange,
                  endDate: date || undefined,
                },
              });
            }}
            disabled={loading}
            onKeyDown={onLogSearch}
            showInline={false}
          />

          <ClearableInputField
            id="search-customer"
            label={t('auditLogSearch.customer')}
            value={
              selectedCustomer
                ? `${selectedCustomer.firstName} ${selectedCustomer.lastName}`
                : selectedUserId || ''
            }
            onClick={() => {
              setFindCustomerModalOpen(true);
            }}
            iconOnClick={() => {
              setFindCustomerModalOpen(true);
            }}
            onClear={() => {
              setSelectedUserId(undefined);
              setSelectedCustomer(undefined);
            }}
            ssrIcon={searchBrowse}
          />
        </SearchInputs>
        <SearchButtonContainer showAdvanced={false}>
          <Button
            loading={loading}
            fluid
            text={t('search.button')}
            type="primary"
            onClick={() => onLogSearch()}
          />

          <Button
            fluid
            text={t('reset')}
            type="secondary"
            onClick={onReset}
            disabled={!inputState && !selectedUserId && !selectedCustomer}
          />
        </SearchButtonContainer>
      </SearchContent>

      <CustomerSearchModal
        open={findCustomerModalOpen}
        onClose={() => {
          setFindCustomerModalOpen(false);
        }}
        setSelectedCustomer={setSelectedCustomer}
        selectedCustomer={selectedCustomer}
      />
    </>
  );
};
