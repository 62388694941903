import styled from 'styled-components';

export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  background: ${(props) => props.theme.colourNeutral1};
  border-bottom: 1px solid ${(props) => props.theme.colourNeutral3};
`;

export const IconWrapper = styled.div<{ expanded: boolean }>`
  width: 12.5rem;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  svg {
    ${(props) => (props.expanded ? 'transform: rotate(180deg)' : '')}
  }
`;

export const SummaryHeadingContainer = styled.button`
  height: 5.625rem;
  width: 100%;
  background-color: ${(props) => props.theme.colourNeutral1};
  padding: 0;
  border: 2px solid transparent;
  cursor: pointer;
  display: flex;

  :focus-visible {
    border: 2px solid ${(props) => props.theme.colourNeutral6};
    border-radius: ${(props) => props.theme.radiusS};
  }
`;

export const SummaryContentWrapper = styled.div<{
  $open: boolean;
  height: string | undefined;
}>`
  overflow: hidden;
  transition: max-height ${(props) => props.theme.durationStandardWeb} linear;
  max-height: 0;

  ${(props) =>
    props.$open &&
    `
    transition: max-height ${props.theme.durationStandardWeb} linear;
    max-height: ${props.height || '50rem'};
  `};
`;

export const SummaryContent = styled.div`
  height: auto;
  display: inline-block;
  width: 100%;
`;
