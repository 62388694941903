import React, { useEffect } from 'react';
import { useRegistryStats } from 'src/hooks/RegistryStatsProvider';
import { Loading } from 'src/skapa';
import * as S from './styles';
import { LoadingBall } from '@ingka/loading';
import { useIntl, useT } from 'src/hooks/intl';
import { Body2, Heading } from '../Typography';
import { space100 } from '@ingka/variables/design-tokens';
import { Errors, useErrors } from '../ErrorMessage';
import { Container } from '../styles';

export const RegistryStats: React.FC = () => {
  const t = useT();
  const { locale } = useIntl();
  const { loading, mostPopularEvent, partialError, statsLast30, statsTotal } =
    useRegistryStats();

  const { errorsProps, addError } = useErrors();

  useEffect(() => {
    if (partialError) {
      addError(t(`error.stats.${partialError}`));
    }
  }, [partialError, addError, t]);

  if (loading) {
    return (
      <S.LoadingContainer>
        <Loading>
          <LoadingBall />
        </Loading>
      </S.LoadingContainer>
    );
  }

  if (partialError) {
    return <Errors {...errorsProps} />;
  }

  const lastUpdated = new Date(statsTotal?.lastUpdated);

  return (
    <>
      {statsLast30 && statsTotal && (
        <S.StatsContainer>
          <S.FlexStatsRow>
            <S.FlexStatColumn>
              <Container>
                <Heading component="h2" variant="h2" color="colourTextAndIcon1">
                  {statsTotal.registries.created}
                </Heading>
                <Body2 color="colourTextAndIcon2">
                  {t('stats.totalGiftRegistriesCreated')}
                </Body2>
              </Container>
              <Container>
                <Heading component="h2" variant="h2" color="colourTextAndIcon1">
                  {statsTotal.giftBags.purchased}
                </Heading>
                <Body2 color="colourTextAndIcon2">
                  {t('stats.totalGiftBagsCompleted')}
                </Body2>
              </Container>
            </S.FlexStatColumn>

            <S.FlexStatColumn>
              <Container>
                <Heading component="h2" variant="h2" color="colourTextAndIcon1">
                  {statsLast30.registries.created}
                </Heading>
                <Body2 color="colourTextAndIcon2">
                  {t('stats.last30Days')}
                </Body2>
              </Container>
              <Container>
                <Heading component="h2" variant="h2" color="colourTextAndIcon1">
                  {mostPopularEvent ? t(`event.${mostPopularEvent}.type`) : '-'}
                </Heading>
                <Body2 color="colourTextAndIcon2">
                  {t('stats.mostPopularEventType')}
                </Body2>
              </Container>
            </S.FlexStatColumn>
          </S.FlexStatsRow>

          {Boolean(lastUpdated.valueOf()) && ( //make sure date exist
            <Body2 color="colourTextAndIcon3" marginTop={space100}>
              {t('stats.lastUpdated', {
                date: lastUpdated.toLocaleDateString(locale),
                time: lastUpdated.toLocaleTimeString(locale),
              })}
            </Body2>
          )}
        </S.StatsContainer>
      )}
    </>
  );
};
