import React, { useContext } from 'react';
import { Role } from './Role';

export interface UserProviderState {
  markets: string[];
  roles: Role[];
  name?: string;
  retailUnit: string;
  isAuthenticated: boolean;
  changeRetailUnit: (retailUnit: string) => Promise<void>;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const UserContext = React.createContext<UserProviderState>(undefined!);

export const useUser = (): UserProviderState => useContext(UserContext);
