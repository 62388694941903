export type EventKey =
  | 'enter'
  | 'escape'
  | 'space'
  | 'backspace'
  | 'arrpwup'
  | 'arrowdown'
  | 'arrowleft'
  | 'arrowright'
  | 'arrowdelete'
  | 'tab';

export const handleKeyboardEvent = (
  eventKeys: EventKey[],
  callback: () => void
): React.KeyboardEventHandler => (event: React.KeyboardEvent<Element>) => {
  const key = event.key?.toLowerCase();
  const eventKey = toEventKey(key);

  if (eventKeys.includes(eventKey)) {
    callback();
  }
};

const toEventKey = (key: string): EventKey => {
  switch (key) {
    case ' ':
      return 'space';
    default:
      return key as EventKey;
  }
};
