import React from 'react';

import {
  ModalHeader as IngkaModalHeader,
  ModalBody as IngkaModalBody,
  ModalFooter as IngkaModalFooter,
  ModalHeaderProps,
  ModalBodyProps,
  ModalFooterProps,
} from '@ingka/modal';
import Modal from './Modal';
import Prompt from './Prompt';
import Sheets from './Sheets';
import Theatre from './Theatre';

export const ModalBody: React.FC<ModalBodyProps> = (props: ModalBodyProps) => (
  <IngkaModalBody {...props} prefix="igift-admin-" />
);
export const ModalFooter: React.FC<ModalFooterProps> = (
  props: ModalFooterProps
) => <IngkaModalFooter {...props} prefix="igift-admin-" />;

export const ModalHeader: React.FC<ModalHeaderProps> = (
  props: ModalHeaderProps
) => <IngkaModalHeader {...props} prefix="igift-admin-" />;

export { Modal, Prompt, Sheets, Theatre };
