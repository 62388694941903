import preval from 'preval.macro';
import enXZ from '../../locales/en-XZ.json';

export const masterMessages = enXZ;
export type Messages = typeof masterMessages;

const availableLocales: string[] = preval`
  const fs = require('fs');
  const fileNames = fs.readdirSync('src/locales/');
  const locales = fileNames.map((file) => file.replace('.json', ''));

  module.exports = locales;
`;

export const languageMap = availableLocales.reduce<Record<string, string[]>>(
  (acc, locale) => {
    const [lang, country] = locale.split('-');

    return {
      ...acc,
      [country]: (acc[country] ?? []).concat(lang),
    };
  },
  {}
);

export const defaultCountries = Object.keys(languageMap);

export const loadLocale = async (locale: string): Promise<Messages> => {
  return (await import(`../../locales/${locale}.json`)).default;
};

export const getBrowserCountry = () => {
  let locale = navigator.language;
  if (navigator.languages && navigator.languages.length) {
    // User has a preference saved in their browser
    locale = navigator.languages[0];
  }
  if (locale) {
    const country = locale.split('-')[1] ?? '';
    return country.toLowerCase();
  }
  return '';
};
