import { useEffect, useState } from 'react';
import { useErrors } from 'src/components/ErrorMessage';
import {
  Day,
  Registry,
  TimeOfDay,
  useDisableHomeDeliveryMutation,
  useHomeDeliveryQuery,
} from 'src/generated/graphql';
import { splitPayload } from 'src/utils/handleUnions';
import { useT } from './intl';

export const useHomeDelivery = (registry: Registry) => {
  const [showDisablePrompt, setShowDisablePrompt] = useState<boolean>(false);
  const t = useT();
  const { addError, clearErrors, errorsProps } = useErrors();
  const { data, loading, refetch } = useHomeDeliveryQuery({
    variables: {
      homeDeliveryInput: {
        registryId: registry?.id,
      },
    },
    onCompleted: () => setShowDisablePrompt(false),
  });

  const [payload, errorKind] = splitPayload(
    'HomeDeliveryPayload',
    data?.homeDelivery
  );

  const [disableHomeDelivery, { loading: disabledLoading }] =
    useDisableHomeDeliveryMutation({
      variables: {
        disableHomeDeliveryInput: {
          registryId: registry?.id,
        },
      },
      onCompleted: (res) => {
        clearErrors();
        const [payload, errorKind] = splitPayload(
          'DisableHomeDeliveryPayload',
          res?.disableHomeDelivery
        );

        if (payload) {
          refetch();
        }

        if (errorKind) {
          addError(t(`error.generic.${errorKind}`));
          setShowDisablePrompt(false);
        }
      },
    });

  const handleConfirmDisableClick = () => {
    disableHomeDelivery();
  };

  const capitalizeString = (str: string) => {
    const strLowerCase = str.toLowerCase();
    return strLowerCase.charAt(0).toUpperCase() + strLowerCase.slice(1);
  };

  const checkboxes = [
    {
      label: t(`registry.settings.${TimeOfDay.Morning.toLowerCase()}`),
      id: '1',
      prefix: 'igift-',
      value: TimeOfDay.Morning,
      disabled: true,
    },
    {
      label: t(`registry.settings.${TimeOfDay.Afternoon.toLowerCase()}`),
      id: '2',
      prefix: 'igift-',
      value: TimeOfDay.Afternoon,
      disabled: true,
    },
    {
      label: t(`registry.settings.${TimeOfDay.Evening.toLowerCase()}`),
      id: '3',
      prefix: 'igift-',
      value: TimeOfDay.Evening,
      disabled: true,
    },
  ];

  useEffect(() => {
    if (errorKind) {
      addError(t(`error.generic.${errorKind}`));
    }
  }, [errorKind, addError, t]);

  const weekDays = [
    Day.Sunday,
    Day.Monday,
    Day.Tuesday,
    Day.Wednesday,
    Day.Thursday,
    Day.Friday,
    Day.Saturday,
  ];

  const selectedOrDisabled = (selectedDaysOfWeek: Day[], day: Day) =>
    selectedDaysOfWeek.includes(day) ? { selected: true } : { disabled: true };

  return {
    weekDays,
    selectedOrDisabled,
    checkboxes,
    capitalizeString,
    handleConfirmDisableClick,
    payload,
    loading,
    disabledLoading,
    errorsProps,
    showDisablePrompt,
    setShowDisablePrompt,
  };
};
