import React from 'react';
import { space100 } from '@ingka/variables/design-tokens';
import {
  GiftBag,
  PurchasedGiftBagsDocument,
  PurchasedGiftBagsQuery,
  PurchasedGiftBagsQueryVariables,
  useDeleteGiftBagMutation,
} from 'src/generated/graphql';
import { splitPayload } from 'src/utils/handleUnions';
import { useRegistry } from 'src/hooks/RegistryProvider';
import { useIntl, useT } from 'src/hooks/intl';
import { Errors, useErrors } from '../ErrorMessage';
import { Button, ModalFooter, Prompt, Modal, ModalHeader } from 'src/skapa';
import { Body2 } from '../Typography';

interface IDeleteGiftBagModalProps {
  giftBagId: string;
  visible: boolean;
  onClose: () => void;
  giftBags: GiftBag[];
  onDeleteSuccess?: () => void;
}

export const DeleteGiftBagModal: React.FC<IDeleteGiftBagModalProps> = ({
  giftBagId,
  visible,
  onClose,
  giftBags,
  onDeleteSuccess,
}: IDeleteGiftBagModalProps) => {
  const t = useT();
  const { errorsProps, addError } = useErrors();
  const { registry } = useRegistry();
  const { language } = useIntl();
  const [deleteGiftBagMutation, { loading }] = useDeleteGiftBagMutation();

  const onDeleteGiftBag = async () => {
    const { data } = await deleteGiftBagMutation({
      variables: {
        deleteGiftBagInput: {
          giftBagId,
          registryId: registry.id,
        },
      },
      update(cache, result) {
        const [payload] = splitPayload(
          'DeleteGiftBagPayload',
          result.data?.deleteGiftBag
        );

        if (payload?.__typename === 'DeleteGiftBagPayload') {
          cache.writeQuery<
            PurchasedGiftBagsQuery,
            PurchasedGiftBagsQueryVariables
          >({
            query: PurchasedGiftBagsDocument,
            variables: {
              input: { registryId: registry.id },
              languageCode: language,
            },
            data: {
              purchasedGiftBags: {
                __typename: 'PurchasedGiftBagsPayload',
                giftBags: giftBags.filter((g) => g.id !== giftBagId),
              },
            },
          });
        }
      },
    });

    const [, partialError] = splitPayload(
      'DeleteGiftBagPayload',
      data?.deleteGiftBag
    );

    if (partialError) {
      addError(t(`error.generic.${partialError}`));
    } else {
      if (onDeleteSuccess) {
        onDeleteSuccess();
      }
      onClose();
    }
  };

  return (
    <Modal visible={visible} escapable={true} handleCloseBtn={onClose}>
      <Prompt
        title={t('order.deleteGiftBagModal.heading')}
        header={<ModalHeader />}
        footer={
          <ModalFooter>
            <Button
              loading={loading}
              text={t('order.deleteGiftBagModal.button')}
              type="danger"
              disabled={!registry}
              onClick={onDeleteGiftBag}
            />
          </ModalFooter>
        }
      >
        <Body2 paddingTop={space100}>
          {t('order.deleteGiftBagModal.body')}
        </Body2>
        {errorsProps && <Errors {...errorsProps} />}
      </Prompt>
    </Modal>
  );
};
