import React, { useState } from 'react';
import calendar from '@ingka/ssr-icon/paths/calendar';
import { DateModal } from './DateModal';
import { useIntl } from 'src/hooks/intl';
import { handleKeyboardEvent } from 'src/utils/keyboardEvents';
import { DatePicker } from '../DatePicker';
import styled from 'styled-components';
import ClearableInputField from '../ClearableInputField';

interface IDateInputFieldProps {
  fieldName: string;
  value: Date | null;
  setValue: (value: Date | null) => void;
  onKeyDown: () => void;
  showInline: boolean;
  disabled?: boolean;
}

export const DateInputField: React.FC<IDateInputFieldProps> = ({
  fieldName,
  value,
  setValue,
  onKeyDown,
  disabled,
  showInline,
}: IDateInputFieldProps) => {
  const { locale } = useIntl();
  const [pickerOpen, setPickerOpen] = useState<boolean>(false);

  return (
    <DateInputFieldWrapper>
      <ClearableInputField
        disabled={disabled}
        id={`${fieldName}`}
        label={fieldName}
        value={value ? value.toLocaleDateString(locale) : ''}
        onKeyDown={handleKeyboardEvent(['enter'], onKeyDown)}
        onClick={() => setPickerOpen(!pickerOpen)}
        iconOnClick={() => setPickerOpen(!pickerOpen)}
        onClear={() => setValue(null)}
        ssrIcon={calendar}
      />

      {showInline && pickerOpen && (
        <>
          <DatePicker
            locale={locale}
            popperModifiers={{
              flip: {
                behavior: 'clockwise',
              },
            }}
            inline
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            date={value!}
            label={fieldName}
            selected={value}
            open={true}
            onChange={(date: Date) => {
              setValue(date);
              setPickerOpen(false);
            }}
          />
        </>
      )}
      {!showInline && pickerOpen && (
        <DateModal
          title={fieldName}
          open={pickerOpen}
          setOpen={setPickerOpen}
          inputDate={value}
          setInputDate={(date: Date | null) => {
            setValue(date);
          }}
        />
      )}
    </DateInputFieldWrapper>
  );
};
const DateInputFieldWrapper = styled.div`
  position: relative;
`;
