import styled from 'styled-components';
import { Heading, Body2 } from './Typography';
import {
  space,
  color,
  layout,
  border,
  background,
  flexbox,
  typography,
  SpaceProps,
  ColorProps,
  LayoutProps,
  BorderProps,
  BackgroundProps,
  FlexboxProps,
  TypographyProps,
} from 'styled-system';

import { MainTheme } from '../styles/themes';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;

  > * {
    margin: 0;
  }
`;

export const Title = styled(Heading)`
  white-space: nowrap;
  max-width: 12.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Subtitle = styled(Body2)`
  text-align: left;
  white-space: nowrap;
  color: ${(props) => props.theme.colourNeutral6};
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  flex-grow: 3;
  flex-basis: 0;
`;
export const DescriptionWrapperInner = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 25rem;
  justify-content: space-between;
  align-items: flex-start;
  gap: ${(props) => props.theme.space100};
`;

export const Content = styled.div`
  padding-top: ${(props) => props.theme.space300};
  width: 100%;
  h1,
  h2 {
    margin: 0;
  }
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['display', 'width'].includes(prop) && defaultValidatorFn(prop),
})<
  SpaceProps<MainTheme> &
    ColorProps<MainTheme> &
    LayoutProps<MainTheme> &
    BorderProps<MainTheme> &
    BackgroundProps<MainTheme> &
    FlexboxProps<MainTheme> &
    TypographyProps<MainTheme>
>(
  {
    boxSizing: 'border-box',
  },
  flexbox,
  space,
  color,
  layout,
  border,
  background,
  typography
);
