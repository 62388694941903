export enum Role {
  MarketCA = 'market:ca',
  MarketCH = 'market:ch',
  MarketSE = 'market:se',
  RegistryRead = 'registry:read',
  RegistryWrite = 'registry:write',
  AuditLogRead = 'audit:read',
}

export const hasRoles = (
  requiredRoles: Role[],
  userRoles?: Role[]
): boolean => {
  return userRoles
    ? requiredRoles.every((r: Role) => userRoles.includes(r))
    : false;
};
