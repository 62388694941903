import React, { useState } from 'react';
import { DatePicker } from '../DatePicker';
import { useIntl, useT } from 'src/hooks/intl';
import { Button, Modal, Sheets, ModalHeader, ModalFooter } from 'src/skapa';
import styled from 'styled-components';

interface IDateModalProps {
  title: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  inputDate: Date | null;
  setInputDate: (newDate: Date | null) => void;
}

export const DateModal: React.FC<IDateModalProps> = ({
  title,
  open,
  setOpen,
  inputDate,
  setInputDate,
}: IDateModalProps) => {
  const [date, setDate] = useState<Date | null>(inputDate);
  const t = useT();
  const { locale } = useIntl();

  return (
    <Modal
      visible={open}
      escapable={true}
      handleCloseBtn={() => setOpen(false)}
    >
      <Sheets
        footer={
          <ModalFooter>
            <Button
              type="primary"
              data-pw="save-date"
              disabled={!date}
              onClick={() => {
                setInputDate(date);
                setOpen(false);
              }}
            >
              {t('select')}
            </Button>
          </ModalFooter>
        }
        header={<ModalHeader />}
      >
        <DatePickerContainer>
          <DatePicker
            locale={locale}
            popperModifiers={{
              flip: {
                behavior: 'clockwise',
              },
            }}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            date={date!}
            label={title}
            selected={date}
            open={true}
            onChange={(date: Date) => {
              setDate(date);
            }}
          />
        </DatePickerContainer>
      </Sheets>
    </Modal>
  );
};

const DatePickerContainer = styled.div`
  .igift-datepicker-input-wrapper {
    width: 100%;
  }
`;
