import React from 'react';

import IngkaCarousel, { CarouselProps } from '@ingka/carousel';
import {
  OverflowCarousel as IngkaOverflowCarousel,
  OverflowCarouselProps,
} from '@ingka/carousel';

export const Carousel: React.FC<CarouselProps> = (props) => (
  <IngkaCarousel {...props} prefix="igift-admin-" />
);

export const OverflowCarousel: React.FC<OverflowCarouselProps> = (props) => (
  <IngkaOverflowCarousel {...props} prefix="igift-admin-" />
);
