import React from 'react';
import { RegistrySearch } from 'src/components/Search';
import { RegistryStats } from 'src/components/RegistryStats';
import { SEO } from 'src/components/SEO';
import { Crumbler } from '../components/Crumbler';
import { Content } from 'src/components/styles';
import { Body1 } from 'src/components/Typography';
import { SubPage } from 'src/components/SubPage';
import { RegistryResults } from 'src/components/SearchResults';
import { useT } from 'src/hooks/intl';
import RoleTemplate from 'src/hooks/auth/RoleTemplate';
import { Role } from 'src/hooks/auth';
import styled from 'styled-components';

const SearchRegistriesContent = styled(Content)`
  flex-direction: row;
  padding-top: 0;
  gap: ${(props) => props.theme.space250};
  flex-wrap: wrap;
  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    flex-wrap: nowrap;
    gap: ${(props) => props.theme.space750};
  }
  align-items: center;
`;

const RegistriesPage: React.FC = () => {
  const t = useT();

  return (
    <RoleTemplate approvedRoles={[Role.RegistryRead]} showErrorPage>
      <SubPage>
        <Body1>
          <SEO title={t('registries.title')} />
          <Crumbler crumbs={['/registries']} />
          <Content>
            <SearchRegistriesContent>
              <RegistrySearch />
              <RegistryStats />
            </SearchRegistriesContent>
            <RegistryResults />
          </Content>
        </Body1>
      </SubPage>
    </RoleTemplate>
  );
};

export default RegistriesPage;
