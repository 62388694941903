import React, { useMemo } from 'react';
import { GiftBagItem, Measurement } from 'src/generated/graphql';
import { useIntl, useT } from 'src/hooks/intl';
import { useRegistry } from 'src/hooks/RegistryProvider';
import { AspectRatioBox, Hyperlink, Image, QuantityStepper } from 'src/skapa';
import { Container } from '../styles';
import { ProductDescription2, ProductTitle, Body2 } from '../Typography';
import styled from 'styled-components';

interface IGiftItem {
  gift: GiftBagItem;
  giftBagId: string;
  onDeleteGiftBagItem: (itemNo: string) => void;
  onQuantityChange: (quantity: number) => void;
}

const GiftItemContainer = styled(Container)`
  padding: ${(props) => props.theme.space250} 0;
  border-bottom: 1px solid ${(props) => props.theme.colourNeutral3};
  gap: ${(props) => props.theme.space100};
`;

const ActionsContainer = styled(Container)`
  padding-top: ${(props) => props.theme.space150};
  gap: ${(props) => props.theme.space100};
`;

export const GiftItem: React.FC<IGiftItem> = ({
  gift,
  onDeleteGiftBagItem,
  onQuantityChange,
}: IGiftItem) => {
  const t = useT();
  const { registry } = useRegistry();
  const { country } = useIntl();

  const registryItem = registry.wishlist.find((w) => w.itemNo === gift.itemNo);
  const maxSelectableQuantity = registryItem
    ? registryItem.availableQuantity + gift.quantity
    : 0;

  const description = useMemo(
    () =>
      getDescription(
        country,
        gift.product?.measurements || [],
        gift.product?.productType
      ),
    [country, gift.product?.measurements, gift.product?.productType]
  );

  return (
    <>
      <GiftItemContainer display="flex">
        <Container flex="1 1 23%">
          {gift.product?.images && (
            <AspectRatioBox ratio="square">
              <Image
                alt={gift.product.images[0].alt || t('productCard.defaultAlt')}
                src={gift.product.images[0].url}
              />
            </AspectRatioBox>
          )}
        </Container>

        <Container width="100%">
          <ProductTitle>{gift.product?.productName}</ProductTitle>
          <ProductDescription2>{description}</ProductDescription2>
          <ProductDescription2>
            {gift.product?.itemNo && toProductNo(gift.product.itemNo, 3)}
          </ProductDescription2>

          <ActionsContainer display="flex" alignItems="center">
            <QuantityStepper
              small
              minVal={1}
              maxVal={maxSelectableQuantity}
              onQuantityChange={onQuantityChange}
              value={gift.quantity}
              ariaDescribedById={`qty-stepper-${gift.itemNo}`}
              ariaLabelIncrease={t('productCard.quantityAriaIncrease')}
              ariaLabelDecrease={t('productCard.quantityAriaDecrease')}
              ariaLabelInput={t('productCard.quantityAriaInput')}
              ariaDescribedBy={t('productCard.quantityAriaDescribedBy')}
            />
            <Body2>
              <Hyperlink
                text={t('remove')}
                button
                subtle
                onClick={() => onDeleteGiftBagItem(gift.itemNo)}
              />
            </Body2>
          </ActionsContainer>
        </Container>
      </GiftItemContainer>
    </>
  );
};

const toProductNo = (itemNo: string, n: number) => {
  return itemNo.match(new RegExp('.{1,' + n + '}', 'g'))?.join('.');
};

const getDescription = (
  country: string,
  measurements: Measurement[] = [],
  productType?: string
) => {
  const imperial = measurements[0]?.imperial || '';
  const metric = measurements[0]?.metric || '';
  const description = [productType];

  if (country === 'ca') {
    description.push(`${imperial}${metric && ` (${metric})`}`.trim());
  } else {
    description.push(metric);
  }

  return description.filter((d) => !!d).join(', ');
};
