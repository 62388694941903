import React, { ReactNode } from 'react';
import { Table, TableBody, TableHeader } from 'src/skapa';
import { useT } from 'src/hooks/intl';
import * as S from './styles';

export const RegistryOrderTable: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const t = useT();

  return (
    <S.StyledCarousel>
      <Table inset fullWidth>
        <TableHeader sticky>
          <tr>
            <th>{t('name')}</th>
            <th>{t('email')}</th>
            <th>{t('order.orderType')}</th>
            <th>{t('order.orderDate')}</th>
            <th>{t('order.giftsCount')}</th>
            <th></th>
          </tr>
        </TableHeader>
        <TableBody striped>{children}</TableBody>
      </Table>
    </S.StyledCarousel>
  );
};
