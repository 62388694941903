import * as CSS from 'csstype';
import { ObjectOrArray, Theme } from 'styled-system';
import { variables, colours } from 'src/skapa';

const rem = (size: number) => (size / 16) * 1;

const filterObject = (
  obj: Record<string, unknown>,
  predicate: (key: string, value: unknown) => boolean
) =>
  Object.keys(obj)
    .filter((key) => predicate(key, obj[key]))
    .reduce((res, key) => Object.assign(res, { [key]: obj[key] }), {});

const objectToArray = (obj: Record<string, string>) =>
  Object.keys(obj).map((key) => obj[key]);

const arrayStringToFloat = (arr: string[]) => arr.map((v) => parseFloat(v));

const addUnit = (arr: unknown[]) => arr.map((item) => `${item}rem`);

const getInShape = (prop: string) =>
  addUnit(
    arrayStringToFloat(
      objectToArray(filterObject(variables, (k, r) => k.includes(prop)))
    ).sort(function (a, b) {
      return a - b;
    })
  );

const breakpoints = ['37.5rem', '56.25rem', '75rem'];
const lineHeights = [rem(18), rem(22), rem(24), rem(26), rem(32), rem(48)];

const missingColors = {
  colourNeutralBlack: '#000000',
  colourAlert: '#4595ff',
} as const;

const space = getInShape('space') as [
  typeof variables.space25,
  typeof variables.space50,
  typeof variables.space75,
  typeof variables.space100,
  typeof variables.space125,
  typeof variables.space150,
  typeof variables.space200,
  typeof variables.space250,
  typeof variables.space300,
  typeof variables.space400,
  typeof variables.space550,
  typeof variables.space750,
  typeof variables.space1000,
  typeof variables.space1350
];

const fontSizes = getInShape('fontSize') as [
  typeof variables.fontSize25,
  typeof variables.fontSize50,
  typeof variables.fontSize75,
  typeof variables.fontSize100,
  typeof variables.fontSize200,
  typeof variables.fontSize300,
  typeof variables.fontSize400,
  typeof variables.fontSize500,
  typeof variables.fontSize600,
  typeof variables.fontSize700,
  typeof variables.fontSize800,
  typeof variables.fontSize900,
  typeof variables.fontSize1000,
  typeof variables.fontSize1100,
  typeof variables.fontSize1200,
  typeof variables.fontSize1300,
  typeof variables.fontSize1400,
  typeof variables.fontSize1500
];

const colors = {
  ...missingColors,
  ...colours,
};

type NeededUnionType<T extends unknown[]> = T[number];

export const main = {
  ...variables,
  ...missingColors,
  ...colours,
  colors,
  breakpoints,
  fontSizes: fontSizes as ObjectOrArray<
    CSS.Property.FontSize<NeededUnionType<typeof fontSizes>>
  >,
  lineHeights,
  space: space as ObjectOrArray<
    CSS.Property.Margin<NeededUnionType<typeof space>>
  >,
};

export type MainTheme = typeof main & Theme;
