export type PossibleTypesResultData = {
  possibleTypes: {
    AuditLogResponse: ['AuditLogPayload', 'NotFoundError', 'PermissionError'];
    BuyingOption: [
      'CashCarryBuyingOption',
      'ClickCollectBuyingOption',
      'HomeDeliveryBuyingOption'
    ];
    ChangeGiftBagItemsQuantityResponse: [
      'BadInputError',
      'ChangeGiftBagItemsQuantityPayload',
      'NotFoundError',
      'PermissionError'
    ];
    CustomerDataResponse: ['CustomerDataPayload'];
    CustomerIdentifierSearchResponse: [
      'CustomerIdentifierSearchResultPayload',
      'PermissionError'
    ];
    DeleteGiftBagResponse: [
      'DeleteGiftBagPayload',
      'NotFoundError',
      'PermissionError'
    ];
    DisableHomeDeliveryResponse: [
      'DisableHomeDeliveryPayload',
      'NotFoundError',
      'PermissionError'
    ];
    Error: [
      'AlreadyOwnerError',
      'BadInputError',
      'CartError',
      'FeatureDisabledError',
      'NotFoundError',
      'NotOwnerError',
      'PageSizeError',
      'PendingPurchaseError',
      'PermissionError',
      'ReservationExpired',
      'ReservationQuantityError',
      'ReservationUnavailableError',
      'SuspendedRegistryError',
      'TooManyMatchingCustomersError',
      'UnavailableError',
      'UpstreamError'
    ];
    HomeDeliveryResponse: ['HomeDeliveryPayload', 'NotFoundError'];
    MarketConfigResponse: ['MarketConfigPayload'];
    PurchasedGiftBagsResponse: [
      'NotFoundError',
      'PermissionError',
      'PurchasedGiftBagsPayload'
    ];
    RegistryLogResponse: [
      'NotFoundError',
      'PermissionError',
      'RegistryLogPayload'
    ];
    RegistryResponse: ['NotFoundError', 'PermissionError', 'RegistryPayload'];
    RegistrySearchResponse: [
      'BadInputError',
      'PermissionError',
      'RegistrySearchResultPayload',
      'TooManyMatchingCustomersError'
    ];
    RegistryStatsResponse: ['PermissionError', 'RegistryStatsPayload'];
    RemoveCoOwnerResponse: [
      'NotFoundError',
      'PermissionError',
      'RemoveCoOwnerPayload'
    ];
    RemoveGiftBagItemsResponse: [
      'NotFoundError',
      'PermissionError',
      'RemoveGiftBagItemsPayload'
    ];
    RemoveRegistryResponse: [
      'NotFoundError',
      'PermissionError',
      'RemoveRegistryPayload'
    ];
    ReopenRegistryResponse: [
      'BadInputError',
      'NotFoundError',
      'PermissionError',
      'ReopenRegistryPayload'
    ];
    SalesLocation: ['AisleAndBin', 'ContactStaff', 'Department'];
    SetGiftGiverNameResponse: [
      'BadInputError',
      'NotFoundError',
      'PermissionError',
      'SetGiftGiverNamePayload'
    ];
    SetGiftMessageResponse: [
      'BadInputError',
      'NotFoundError',
      'PermissionError',
      'SetGiftMessagePayload'
    ];
    SetGiverEmailResponse: [
      'BadInputError',
      'NotFoundError',
      'PermissionError',
      'SetGiverEmailPayload'
    ];
    SuspendRegistryResponse: [
      'BadInputError',
      'NotFoundError',
      'PermissionError',
      'SuspendRegistryPayload'
    ];
    ViewerResponse: ['ViewerPayload'];
  };
};
const result: PossibleTypesResultData = {
  possibleTypes: {
    AuditLogResponse: ['AuditLogPayload', 'NotFoundError', 'PermissionError'],
    BuyingOption: [
      'CashCarryBuyingOption',
      'ClickCollectBuyingOption',
      'HomeDeliveryBuyingOption',
    ],
    ChangeGiftBagItemsQuantityResponse: [
      'BadInputError',
      'ChangeGiftBagItemsQuantityPayload',
      'NotFoundError',
      'PermissionError',
    ],
    CustomerDataResponse: ['CustomerDataPayload'],
    CustomerIdentifierSearchResponse: [
      'CustomerIdentifierSearchResultPayload',
      'PermissionError',
    ],
    DeleteGiftBagResponse: [
      'DeleteGiftBagPayload',
      'NotFoundError',
      'PermissionError',
    ],
    DisableHomeDeliveryResponse: [
      'DisableHomeDeliveryPayload',
      'NotFoundError',
      'PermissionError',
    ],
    Error: [
      'AlreadyOwnerError',
      'BadInputError',
      'CartError',
      'FeatureDisabledError',
      'NotFoundError',
      'NotOwnerError',
      'PageSizeError',
      'PendingPurchaseError',
      'PermissionError',
      'ReservationExpired',
      'ReservationQuantityError',
      'ReservationUnavailableError',
      'SuspendedRegistryError',
      'TooManyMatchingCustomersError',
      'UnavailableError',
      'UpstreamError',
    ],
    HomeDeliveryResponse: ['HomeDeliveryPayload', 'NotFoundError'],
    MarketConfigResponse: ['MarketConfigPayload'],
    PurchasedGiftBagsResponse: [
      'NotFoundError',
      'PermissionError',
      'PurchasedGiftBagsPayload',
    ],
    RegistryLogResponse: [
      'NotFoundError',
      'PermissionError',
      'RegistryLogPayload',
    ],
    RegistryResponse: ['NotFoundError', 'PermissionError', 'RegistryPayload'],
    RegistrySearchResponse: [
      'BadInputError',
      'PermissionError',
      'RegistrySearchResultPayload',
      'TooManyMatchingCustomersError',
    ],
    RegistryStatsResponse: ['PermissionError', 'RegistryStatsPayload'],
    RemoveCoOwnerResponse: [
      'NotFoundError',
      'PermissionError',
      'RemoveCoOwnerPayload',
    ],
    RemoveGiftBagItemsResponse: [
      'NotFoundError',
      'PermissionError',
      'RemoveGiftBagItemsPayload',
    ],
    RemoveRegistryResponse: [
      'NotFoundError',
      'PermissionError',
      'RemoveRegistryPayload',
    ],
    ReopenRegistryResponse: [
      'BadInputError',
      'NotFoundError',
      'PermissionError',
      'ReopenRegistryPayload',
    ],
    SalesLocation: ['AisleAndBin', 'ContactStaff', 'Department'],
    SetGiftGiverNameResponse: [
      'BadInputError',
      'NotFoundError',
      'PermissionError',
      'SetGiftGiverNamePayload',
    ],
    SetGiftMessageResponse: [
      'BadInputError',
      'NotFoundError',
      'PermissionError',
      'SetGiftMessagePayload',
    ],
    SetGiverEmailResponse: [
      'BadInputError',
      'NotFoundError',
      'PermissionError',
      'SetGiverEmailPayload',
    ],
    SuspendRegistryResponse: [
      'BadInputError',
      'NotFoundError',
      'PermissionError',
      'SuspendRegistryPayload',
    ],
    ViewerResponse: ['ViewerPayload'],
  },
};
export default result;
