import { Image } from '../generated/graphql';

export interface IImageResponse {
  srcSet: string;
  lowQ: string;
  src: string;
}
export interface IImageQuery {
  width: number;
  query: string;
}

export const IMAGE_WIDTHS: IImageQuery[] = [
  { width: 1600, query: 'f=g' },
  { width: 1400, query: 'f=sg' },
  { width: 1100, query: 'f=xxxl' },
  { width: 900, query: 'f=xxl' },
  { width: 750, query: 'f=xl' },
  { width: 700, query: 'f=l' },
  { width: 600, query: 'f=m' },
  { width: 500, query: 'f=s' },
  { width: 400, query: 'f=xs' },
  { width: 300, query: 'f=xxs' },
  { width: 160, query: 'f=xxxs' },
  { width: 80, query: 'f=u' },
  { width: 40, query: 'f=xu' },
  { width: 39, query: 'f=xxu' },
];

export const getSrcset = (
  images: Image[],
  variant: string
): IImageResponse | undefined => {
  const image = images.find((m) => m.variant === variant);

  if (!image) {
    return undefined;
  }
  const url = image.url;

  return {
    srcSet: IMAGE_WIDTHS.map((imw) => `${url}?${imw.query} ${imw.width}w`).join(
      ','
    ),
    lowQ: `${url}?f=xxu`,
    src: url.toString(),
  };
};

export const srcSet = (image: Image): string => {
  return IMAGE_WIDTHS.map(
    (imw) => `${image.url}?${imw.query} ${imw.width}w`
  ).join(',');
};
