import React, { useEffect, useMemo, useState } from 'react';
import { Registry, RegistryItem } from 'src/generated/graphql';
import { useIntl, useT } from 'src/hooks/intl';
import styled from 'styled-components';
import { Container } from '../styles';
import { Body2, Body1 } from '../Typography';
import { space250, space50 } from '@ingka/variables/design-tokens';

const countRemainingGifts = (wishlist: RegistryItem[]): number =>
  wishlist.reduce((acc, v) => {
    return acc + v.totalQuantity - v.purchasedQuantity;
  }, 0);

const countPurchasedGifts = (wishlist: RegistryItem[]): number =>
  wishlist.reduce((acc, v) => {
    return acc + v.purchasedQuantity;
  }, 0);

const daysUntil = (to: Date, from: Date): number => {
  let diff = (to.getTime() - from.getTime()) / 1000;
  diff /= 60 * 60 * 24;
  return Math.max(0, Math.round(diff));
};
interface IRegistryOverviewProps {
  registry: Registry;
}

export const RegistryOverview: React.FC<IRegistryOverviewProps> = ({
  registry,
}: IRegistryOverviewProps) => {
  const t = useT();
  const [daysToEvent, setDaysToEvent] = useState<number>(0);
  const { locale } = useIntl();
  const remainingGifts = useMemo(
    () => (registry.wishlist ? countRemainingGifts(registry.wishlist) : 0),
    [registry]
  );

  const purchasedGifts = useMemo(
    () => (registry.wishlist ? countPurchasedGifts(registry.wishlist) : 0),
    [registry]
  );

  const eventDate = registry.eventDate?.toLocaleDateString(locale);

  useEffect(() => {
    if (registry.eventDate) {
      const today = new Date(new Date().setHours(0, 0, 0, 0));
      setDaysToEvent(daysUntil(registry.eventDate, today));
    }
  }, [registry]);

  const registryData = [
    { label: '', value: eventDate || t('registries.emptyDate') },
    { label: t('registry.overview.daysToGo'), value: daysToEvent },
    { label: t('registry.overview.purchasedGifts'), value: purchasedGifts },
    { label: t('registry.overview.remainingGifts'), value: remainingGifts },
  ];

  return (
    <>
      <Description>{registry.description}</Description>
      <Container
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        style={{ gap: space250 }}
      >
        {registryData.map((x, i) => {
          return (
            <Body1 key={i}>
              <b style={{ marginRight: space50 }}>{x.value}</b>
              {x.label}
            </Body1>
          );
        })}
      </Container>
    </>
  );
};

const Description = styled(Body2)`
  white-space: pre-wrap;
  max-width: 75%;
  margin: ${(props) => props.theme.space250} auto;
  text-align: center;
`;
