export function getValidation(
  value: string,
  date: Date
): { isValid: boolean; msgKey?: string } {
  if (!value) {
    return {
      isValid: true,
    };
  }
  if (!date) {
    return {
      isValid: false,
      msgKey: 'calendar.validation.invalidDate',
    };
  }
  const rawDate = new Date(value).getTime();
  if (isNaN(rawDate)) {
    return {
      isValid: false,
      msgKey: 'calendar.validation.wrongFormat',
    };
  }
  if (rawDate === date.getTime()) {
    return { isValid: true };
  }

  return {
    isValid: false,
    msgKey: 'calendar.validation.invalidDate',
  };
}

export function onRegisterLocale(localeName: string) {
  switch (localeName) {
    case 'ca-ES':
      return import('date-fns/locale/ca');
    case 'cs-CZ':
    case 'en-CZ':
      return import('date-fns/locale/cs');
    case 'de-CH':
    case 'de-DE':
      return import('date-fns/locale/de');
    case 'en-AU':
      return import('date-fns/locale/en-AU');
    case 'en-CA':
      return import('date-fns/locale/en-CA');
    case 'en-BE':
    case 'en-CH':
    case 'en-ES':
    case 'en-GB':
    case 'en-IE':
    case 'en-KR':
    case 'en-NL':
    case 'en-PT':
      return import('date-fns/locale/en-GB');
    case 'en-JP':
    case 'en-US':
      return import('date-fns/locale/en-US');
    case 'es-ES':
      return import('date-fns/locale/es');
    case 'eu-ES':
      return import('date-fns/locale/eu');
    case 'gl-ES':
      return import('date-fns/locale/gl');
    case 'hr-HR':
      return import('date-fns/locale/hr');
    case 'hu-HU':
      return import('date-fns/locale/hu');
    case 'it-CH':
    case 'it-IT':
      return import('date-fns/locale/it');
    case 'fi-FI':
      return import('date-fns/locale/fi');
    case 'fr-BE':
      return import('date-fns/locale/fr');
    case 'fr-CA':
      return import('date-fns/locale/fr-CA');
    case 'fr-CH':
      return import('date-fns/locale/fr-CH');
    case 'ja-JP':
      return import('date-fns/locale/ja');
    case 'ko-KR':
      return import('date-fns/locale/ko');
    case 'nl-NL':
      return import('date-fns/locale/nl');
    case 'nl-BE':
      return import('date-fns/locale/nl-BE');
    case 'no-NO':
      return import('date-fns/locale/nb');
    case 'pt-PT':
      return import('date-fns/locale/pt');
    case 'ro-RO':
      return import('date-fns/locale/ro');
    case 'sk-SK':
      return import('date-fns/locale/sk');
    case 'sr-RS':
      return import('date-fns/locale/sr');
    case 'sl-SI':
      return import('date-fns/locale/sl');
    case 'sv-SE':
      return import('date-fns/locale/sv');
    default:
      return null;
  }
}
