import { GiftBag, usePurchasedGiftBagsQuery } from 'src/generated/graphql';
import { useIntl } from 'src/hooks/intl';
import { useRegistry } from 'src/hooks/RegistryProvider';
import { splitPayload } from 'src/utils/handleUnions';

interface UseGiftBagsProps {
  giftBags: GiftBag[];
  error: string | undefined;
  loading: boolean;
}

function useGiftBags(): UseGiftBagsProps {
  const { language } = useIntl();
  const { registry } = useRegistry();

  const { data, loading } = usePurchasedGiftBagsQuery({
    variables: {
      input: {
        registryId: registry.id,
      },
      languageCode: language,
    },
  });

  const [payload, partialError] = splitPayload(
    'PurchasedGiftBagsPayload',
    data?.purchasedGiftBags
  );

  const giftBags = (payload?.giftBags as GiftBag[]) || [];

  return {
    giftBags,
    loading,
    error: partialError,
  };
}

export default useGiftBags;
