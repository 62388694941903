import styled from 'styled-components';
import { Carousel, Hyperlink, Search as SearchComponent } from 'src/skapa';
import { Body2, Label } from '../Typography';

export const Search = styled(SearchComponent)`
  width: 100%;
  margin-bottom: ${(props) => props.theme.space150};
  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    width: 23.5rem;
  }
`;

export const OrdersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

//RegistryOrder

export const GiftItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const OrderNumber = styled(Body2)`
  white-space: nowrap;
  max-width: 12.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Action = styled(Label)`
  font-weight: ${(props) => props.theme.fontWeightBold};
  cursor: pointer;
  border: 0;
  background: none;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledCarousel = styled(Carousel)`
  .igift-admin-carousel-slide {
    width: 100%;
  }
`;

export const StyledHyperLink = styled(Hyperlink)`
  font-weight: ${(props) => props.theme.fontWeightBold};
`;
