import { Banner, Hyperlink } from 'src/skapa';
import styled from 'styled-components';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import { useIntl, useT } from 'src/hooks/intl';
import {
  cmpOrigin,
  customerAdminUrls,
  igiftContactEmail,
} from 'src/utils/settings';

const StyledBanner = styled(Banner)`
  min-height: 0;
`;

const BannerText = styled.span`
  color: ${({ theme }) => theme.colourNeutral6};
`;

const catUrlBase =
  cmpOrigin !== 'https://www.ikea.com'
    ? customerAdminUrls.dev
    : customerAdminUrls.prod;

const NotificationBanner = () => {
  const { country } = useIntl();
  const t = useT();
  const url = `${catUrlBase}/${country}/home`;

  return (
    <StyledBanner
      body={
        <>
          <Hyperlink
            newWindow={true}
            url={url}
            subtle={true}
            text={t('notification.catMigrationDateless')}
          />
          <BannerText>{' | ' + igiftContactEmail}</BannerText>
        </>
      }
      ssrIcon={informationCircle}
      emergency={true}
    />
  );
};

export default NotificationBanner;
