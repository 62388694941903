import { IImageResponse } from './getSrcset';

export type ImageKind = 'Product' | 'Room';

export const getImages = (
  defaultImage: ImageKind,
  imageProduct?: IImageResponse,
  imageRoom?: IImageResponse
): {
  mainImage: IImageResponse | undefined;
  secondaryImage: IImageResponse | undefined;
} => {
  if (!imageProduct || !imageRoom) {
    return {
      mainImage: imageProduct || imageRoom || undefined,
      secondaryImage: undefined,
    };
  }
  if (defaultImage === 'Product') {
    return { mainImage: imageProduct, secondaryImage: imageRoom };
  } else {
    return { mainImage: imageRoom, secondaryImage: imageProduct };
  }
};
