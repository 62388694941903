import React, { ReactNode, useRef } from 'react';
import { SSRIcon } from 'src/skapa';
import chevronRight from '@ingka/ssr-icon/paths/chevron-right';
import chevronDown from '@ingka/ssr-icon/paths/chevron-down';
import * as S from './styles';
import { Container } from '../styles';

interface ISummaryProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  headerContent: string | ReactNode;
  content?: string | ReactNode;
  open?: boolean;
}
export const Summary: React.FC<ISummaryProps> = ({
  open,
  onClick,
  headerContent,
  content,
}: ISummaryProps) => {
  const contentRef = useRef<HTMLDivElement | null>(null);

  if (!headerContent) {
    return null;
  }
  const expanded =
    typeof content !== 'undefined' &&
    typeof open !== 'undefined' &&
    open === true;

  const contentHeight: number | undefined = contentRef.current?.clientHeight;

  return (
    <S.SummaryContainer>
      <S.SummaryHeadingContainer onClick={onClick}>
        <Container width="100%" height="100%">
          {headerContent}
        </Container>
        <S.IconWrapper expanded={expanded}>
          <SSRIcon
            paths={typeof content !== 'undefined' ? chevronDown : chevronRight}
          />
        </S.IconWrapper>
      </S.SummaryHeadingContainer>
      {content && (
        <S.SummaryContentWrapper
          $open={open ? open : false}
          height={contentHeight ? `${contentHeight / 16}rem` : undefined}
        >
          <S.SummaryContent
            ref={(node) => {
              contentRef.current = node;
            }}
          >
            {content}
          </S.SummaryContent>
        </S.SummaryContentWrapper>
      )}
    </S.SummaryContainer>
  );
};
