export const addThousandSeparator = (
  value: string,
  separator: string
): string => {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

export const getPriceSections = (
  value: number
): { integerValue: string; decimalValue: string } => {
  const valueArray = value.toFixed(2).toString().split('.') ?? ['0', null];
  return { integerValue: valueArray[0], decimalValue: valueArray[1] };
};

export const DOUBLE_ZERO = '00';

export const getDecimalFormat = (value: string) => {
  return value !== DOUBLE_ZERO ? value : undefined;
};

export const isValidPrice = (price: number): boolean => {
  try {
    const priceStr = price.toString();

    return !Number.isNaN(Number.parseFloat(priceStr));
  } catch {
    return false;
  }
};
