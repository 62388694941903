import React from 'react';
import { Price as IngkaPrice } from 'src/skapa';
import { PriceProps as IngkaPriceProps } from '@ingka/price';
import { useIntl } from 'src/hooks/intl';

import {
  addThousandSeparator,
  getDecimalFormat,
  getPriceSections,
  DOUBLE_ZERO,
} from 'src/utils/price-formatter/price.util';

import {
  CURRENCY_SYMBOLS,
  DECIMAL_SIGNS,
  THOUSAND_SEPARATORS,
} from 'src/utils/price-formatter/types';

interface IPriceProps {
  price: number;
  currencyCode: string;
}

export const Price: React.FC<IPriceProps> = ({
  price,
  currencyCode = '',
  ...props
}: IPriceProps) => {
  const { country } = useIntl();
  const currencyProps = getCurrencyProps(price, currencyCode, country);
  return <IngkaPrice {...props} {...currencyProps} />;
};

export const getCurrencyProps = (
  price: number,
  currencyCode: string,
  countryCode: string
): Pick<
  IngkaPriceProps,
  | 'currencyLabel'
  | 'currencyPosition'
  | 'decimalSign'
  | 'decimalValue'
  | 'integerValue'
> => {
  const { integerValue, decimalValue } = getPriceSections(price);

  switch (countryCode) {
    case 'AT':
      return {
        currencyLabel: CURRENCY_SYMBOLS.EURO,
        currencyPosition: 'leading',
        integerValue: addThousandSeparator(
          integerValue,
          THOUSAND_SEPARATORS.DOT
        ),
        decimalValue: getDecimalFormat(decimalValue),
        decimalSign: DECIMAL_SIGNS.COMMA,
      };

    case 'AU':
      return {
        currencyLabel: CURRENCY_SYMBOLS.DOLLAR_SIGN,
        currencyPosition: 'leading',
        integerValue: addThousandSeparator(
          integerValue,
          THOUSAND_SEPARATORS.COMMA
        ),
        decimalValue: getDecimalFormat(decimalValue),
        decimalSign: DECIMAL_SIGNS.DOT,
      };

    case 'BE':
      return {
        currencyLabel: CURRENCY_SYMBOLS.EURO,
        currencyPosition: 'leading',
        integerValue: addThousandSeparator(
          integerValue,
          THOUSAND_SEPARATORS.DOT
        ),
        decimalValue: getDecimalFormat(decimalValue),
        decimalSign: DECIMAL_SIGNS.COMMA,
      };

    case 'CA':
      return {
        currencyLabel: CURRENCY_SYMBOLS.DOLLAR_SIGN,
        currencyPosition: 'leading',
        integerValue: addThousandSeparator(
          integerValue,
          THOUSAND_SEPARATORS.COMMA
        ),
        decimalValue: decimalValue ?? DOUBLE_ZERO,
        decimalSign: DECIMAL_SIGNS.DOT,
      };

    case 'CH':
      return {
        currencyLabel: CURRENCY_SYMBOLS.SWISS_FRANC,
        currencyPosition: 'leading',
        integerValue: addThousandSeparator(
          integerValue,
          THOUSAND_SEPARATORS.APOSTROPHE
        ),
        decimalValue: decimalValue ?? DOUBLE_ZERO,
        decimalSign: DECIMAL_SIGNS.COMMA,
      };

    case 'CZ':
      return {
        currencyLabel: CURRENCY_SYMBOLS.CZECH_KORUNA,
        currencyPosition: 'trailing',
        integerValue: addThousandSeparator(
          integerValue,
          THOUSAND_SEPARATORS.SPACE
        ),
        decimalValue: getDecimalFormat(decimalValue),
        decimalSign: DECIMAL_SIGNS.COMMA,
      };

    case 'DE':
      return {
        currencyLabel: CURRENCY_SYMBOLS.EMPTY,
        integerValue: addThousandSeparator(
          integerValue,
          THOUSAND_SEPARATORS.DOT
        ),
        decimalValue: decimalValue ?? DOUBLE_ZERO,
        decimalSign: DECIMAL_SIGNS.DOT,
      };

    case 'DK':
      return {
        currencyLabel: CURRENCY_SYMBOLS.DANISH_KRONA,
        currencyPosition: 'trailing',
        integerValue: addThousandSeparator(
          integerValue,
          THOUSAND_SEPARATORS.DOT
        ),
        decimalValue: getDecimalFormat(decimalValue),
        decimalSign: DECIMAL_SIGNS.COMMA,
      };

    case 'ES':
      return {
        currencyLabel: CURRENCY_SYMBOLS.EURO,
        currencyPosition: 'trailing',
        integerValue: integerValue,
        decimalValue: getDecimalFormat(decimalValue),
        decimalSign: THOUSAND_SEPARATORS.COMMA,
      };

    case 'FI':
      return {
        currencyLabel: '',
        currencyPosition: 'trailing',
        integerValue: addThousandSeparator(
          integerValue,
          THOUSAND_SEPARATORS.SPACE
        ),
        decimalValue: decimalValue,
        decimalSign: DECIMAL_SIGNS.COMMA,
      };

    case 'FR':
      return {
        currencyLabel: CURRENCY_SYMBOLS.EURO,
        currencyPosition: 'trailing',
        integerValue: addThousandSeparator(
          integerValue,
          THOUSAND_SEPARATORS.SPACE
        ),
        decimalValue: getDecimalFormat(decimalValue),
        decimalSign: DECIMAL_SIGNS.COMMA,
      };

    case 'GB':
      return {
        currencyLabel: CURRENCY_SYMBOLS.BRITISH_POUND,
        currencyPosition: 'leading',
        integerValue: addThousandSeparator(
          integerValue,
          THOUSAND_SEPARATORS.COMMA
        ),
        decimalValue: getDecimalFormat(decimalValue),
        decimalSign: DECIMAL_SIGNS.DOT,
      };

    case 'HR':
      return {
        currencyLabel: CURRENCY_SYMBOLS.EURO,
        currencyPosition: 'trailing',
        integerValue: addThousandSeparator(
          integerValue,
          THOUSAND_SEPARATORS.DOT
        ),
        decimalValue: getDecimalFormat(decimalValue),
        decimalSign: DECIMAL_SIGNS.COMMA,
      };

    case 'HU':
      return {
        currencyLabel: CURRENCY_SYMBOLS.HUNGARIAN_FORINT,
        currencyPosition: 'trailing',
        integerValue: addThousandSeparator(
          integerValue,
          THOUSAND_SEPARATORS.SPACE
        ),
        decimalValue: getDecimalFormat(decimalValue),
        decimalSign: DECIMAL_SIGNS.DOT,
      };

    case 'IE':
      return {
        currencyLabel: CURRENCY_SYMBOLS.EURO,
        currencyPosition: 'leading',
        integerValue: addThousandSeparator(
          integerValue,
          THOUSAND_SEPARATORS.COMMA
        ),
        decimalValue: getDecimalFormat(decimalValue),
        decimalSign: DECIMAL_SIGNS.DOT,
      };

    case 'IT':
      return {
        currencyLabel: CURRENCY_SYMBOLS.EURO,
        currencyPosition: 'leading',
        integerValue: addThousandSeparator(
          integerValue,
          THOUSAND_SEPARATORS.DOT
        ),
        decimalValue: getDecimalFormat(decimalValue),
        decimalSign: DECIMAL_SIGNS.COMMA,
      };

    case 'JP':
      return {
        currencyLabel: CURRENCY_SYMBOLS.JAPANESE_YEN,
        currencyPosition: 'leading',
        integerValue: addThousandSeparator(
          integerValue,
          THOUSAND_SEPARATORS.COMMA
        ),
        decimalValue: '',
      };

    case 'KR':
      return {
        currencyLabel: CURRENCY_SYMBOLS.SOUTH_KOREAN_WON,
        currencyPosition: 'leading',
        integerValue: addThousandSeparator(
          integerValue,
          THOUSAND_SEPARATORS.COMMA
        ),
        decimalValue: '',
      };

    case 'NL':
      return {
        currencyLabel: CURRENCY_SYMBOLS.EURO,
        currencyPosition: 'leading',
        integerValue: addThousandSeparator(
          integerValue,
          THOUSAND_SEPARATORS.COMMA
        ),
        decimalValue: getDecimalFormat(decimalValue),
        decimalSign: DECIMAL_SIGNS.DOT,
      };

    case 'NO':
      return {
        currencyLabel: CURRENCY_SYMBOLS.KRONE,
        currencyPosition: 'trailing',
        integerValue: addThousandSeparator(
          integerValue,
          THOUSAND_SEPARATORS.DOT
        ),
        decimalValue: getDecimalFormat(decimalValue),
        decimalSign: DECIMAL_SIGNS.COMMA,
      };

    case 'PL':
      return {
        currencyLabel: CURRENCY_SYMBOLS.KRONE,
        currencyPosition: 'trailing',
        integerValue: integerValue,
        decimalValue: getDecimalFormat(decimalValue),
        decimalSign: DECIMAL_SIGNS.COMMA,
      };

    case 'PT':
      return {
        currencyLabel: CURRENCY_SYMBOLS.EURO,
        currencyPosition: 'trailing',
        integerValue: integerValue,
        decimalValue: getDecimalFormat(decimalValue),
        decimalSign: DECIMAL_SIGNS.COMMA,
      };

    case 'RO':
      return {
        currencyLabel: CURRENCY_SYMBOLS.ROMANIAN_LEU,
        currencyPosition: 'trailing',
        integerValue: addThousandSeparator(
          integerValue,
          THOUSAND_SEPARATORS.DOT
        ),
        decimalValue: getDecimalFormat(decimalValue),
        decimalSign: DECIMAL_SIGNS.COMMA,
      };

    case 'RS':
      return {
        currencyLabel: CURRENCY_SYMBOLS.SERBIAN_DINAR,
        currencyPosition: 'trailing',
        integerValue: addThousandSeparator(
          integerValue,
          THOUSAND_SEPARATORS.DOT
        ),
        decimalValue: '',
      };

    case 'SE':
      return {
        currencyLabel: CURRENCY_SYMBOLS.SWEDISH_KRONA,
        currencyPosition: 'trailing',
        integerValue: addThousandSeparator(
          integerValue,
          THOUSAND_SEPARATORS.SPACE
        ),
        decimalValue: getDecimalFormat(decimalValue),
        decimalSign: DECIMAL_SIGNS.COMMA,
      };

    case 'SI':
      return {
        currencyLabel: CURRENCY_SYMBOLS.EURO,
        currencyPosition: 'trailing',
        integerValue: addThousandSeparator(
          integerValue,
          THOUSAND_SEPARATORS.DOT
        ),
        decimalValue: decimalValue ?? DOUBLE_ZERO,
        decimalSign: DECIMAL_SIGNS.COMMA,
      };

    case 'SK':
      return {
        currencyLabel: CURRENCY_SYMBOLS.EURO,
        currencyPosition: 'leading',
        integerValue: addThousandSeparator(
          integerValue,
          THOUSAND_SEPARATORS.SPACE
        ),
        decimalValue: decimalValue ?? DOUBLE_ZERO,
        decimalSign: DECIMAL_SIGNS.COMMA,
      };

    case 'US':
      return {
        currencyLabel: CURRENCY_SYMBOLS.DOLLAR_SIGN,
        currencyPosition: 'leading',
        integerValue: addThousandSeparator(
          integerValue,
          THOUSAND_SEPARATORS.COMMA
        ),
        decimalValue: decimalValue ?? DOUBLE_ZERO,
        decimalSign: DECIMAL_SIGNS.DOT,
      };

    default:
      return {
        currencyLabel: CURRENCY_SYMBOLS.DOLLAR_SIGN,
        currencyPosition: 'leading',
        integerValue: addThousandSeparator(
          integerValue,
          THOUSAND_SEPARATORS.COMMA
        ),
        decimalValue: decimalValue ?? DOUBLE_ZERO,
        decimalSign: DECIMAL_SIGNS.DOT,
      };
  }
};
