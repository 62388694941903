import React, { useContext } from 'react';

export interface IntlState {
  showKeys: boolean;
  language: string;
  country: string;
  locale: string;
  countries: string[];
  setLanguage: (language: string) => Promise<void>;
  setCountry: (country: string) => Promise<void>;
  setLocale: (country: string, language?: string) => Promise<void>;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const IntlContext = React.createContext<IntlState>(undefined!);

export const useIntl = (): IntlState => useContext(IntlContext);
