import React, { useState, useRef, useMemo } from 'react';
import { Measurement, RegistryItem } from 'src/generated/graphql';
import { useIntl, useT } from 'src/hooks/intl';
import { ImageKind } from 'src/utils/getImages';
import { Price } from '../Price';
import { ProductDescription2, ProductTitle } from '../Typography';
import ProductImage from './ProductImage';
import { Container } from '../styles';
import { space100, space50 } from '@ingka/variables/design-tokens';
import { ProductIdentifier } from 'src/skapa';
import * as S from './styles';

interface IProductCardProps {
  listItem: RegistryItem;
  mainImage: ImageKind;
}

export const ProductCard: React.FC<IProductCardProps> = ({
  listItem,
  mainImage,
}: IProductCardProps) => {
  const { product, purchasedQuantity, totalQuantity, reservedQuantity, price } =
    listItem;
  const cardRef = useRef(null);
  const [active, setActive] = useState<boolean>(false);
  const t = useT();
  const isGifted = totalQuantity <= purchasedQuantity;
  const { country } = useIntl();

  const description = useMemo(
    () =>
      getDescription(
        country,
        product?.measurements || [],
        product?.productType
      ),
    [country, product?.measurements, product?.productType]
  );

  return (
    <S.ProductCardContainer
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      ref={cardRef}
    >
      <ProductImage
        images={product?.images || []}
        defaultImage={mainImage}
        active={active}
        isGifted={isGifted}
        totalQuantity={totalQuantity}
      />

      <ProductTitle>
        {product?.productName || t('productCard.product')}
      </ProductTitle>

      <Container marginBottom={space50}>
        <ProductDescription2>
          {description || t('productCard.description')}
        </ProductDescription2>
      </Container>

      <Container marginBottom={space100}>
        {price ? (
          <Price price={price.inclTax} currencyCode={price.currencyCode} />
        ) : (
          <ProductTitle color="colourNeutralGrey500">
            {t('productCard.unavailable')}
          </ProductTitle>
        )}
      </Container>

      <Container marginTop={space100}>
        <ProductIdentifier
          subtle
          value={t('productCard.remaining', {
            remaining: totalQuantity - purchasedQuantity - reservedQuantity,
            totalQuantity,
          })}
        />
      </Container>
    </S.ProductCardContainer>
  );
};

const getDescription = (
  country: string,
  measurements: Measurement[] = [],
  productType?: string
) => {
  const imperial = measurements[0]?.imperial || '';
  const metric = measurements[0]?.metric || '';
  const description = [productType];

  if (country === 'ca') {
    description.push(`${imperial}${metric && ` (${metric})`}`.trim());
  } else {
    description.push(metric);
  }

  return description.filter((d) => !!d).join(', ');
};
