import React, { useCallback, useEffect, useState } from 'react';

import { space150 } from '@ingka/variables/design-tokens';
import { LoadingBall } from '@ingka/loading';

import { splitPayload } from 'src/utils/handleUnions';
import {
  useRegistryLogQuery,
  EntityType,
  LogFiltering,
} from 'src/generated/graphql';
import { NetworkStatus } from '@apollo/client';
import { useIntl, useT } from 'src/hooks/intl';
import { hasRoles, Role, useUser } from 'src/hooks/auth';
import { Loading, Table, TableBody, TableHeader, Toggle } from 'src/skapa';
import { Errors, useErrors } from '../ErrorMessage';
import EmptyState from '../EmptyState';
import { Label } from '../Typography';
import { Container } from '../styles';
import * as S from './styles';
import { AuditLogResult } from '../SearchResults';

interface IRegistryLogProps {
  registryId: string;
}

export const RegistryLog: React.FC<IRegistryLogProps> = ({
  registryId,
}: IRegistryLogProps) => {
  const t = useT();
  const { language } = useIntl();
  const { roles } = useUser();
  const allowedToSeeUserId = hasRoles([Role.AuditLogRead], roles);
  const { errorsProps, addError } = useErrors();
  const [entityTypeIndex, setEntityTypeIndex] = useState<0 | 1 | 2>(0);

  const { data, loading, variables, networkStatus, fetchMore, refetch } =
    useRegistryLogQuery({
      notifyOnNetworkStatusChange: true,
      variables: {
        input: {
          filtering: {},
          registryId: registryId,
        },
        languageCode: language,
        shouldFetchId: allowedToSeeUserId,
      },
    });

  const [success, error] = splitPayload(
    'RegistryLogPayload',
    data?.registryLog
  );

  useEffect(() => {
    if (error) {
      addError(t(`error.generic.${error}`));
    }
  }, [error, addError, t]);

  const loadMore = useCallback(() => {
    if (variables?.input && success?.nextPageCursor) {
      fetchMore({
        variables: {
          input: {
            ...variables?.input,
            nextPageCursor: success.nextPageCursor,
          },
        },
      });
    } else {
      throw new Error('Cannot load more');
    }
  }, [variables, success, fetchMore]);

  const onChangeFilter = useCallback(
    (filter: Partial<LogFiltering>) => {
      if (variables) {
        refetch({
          ...variables,
          input: {
            ...variables.input,
            filtering: filter,
          },
        });
      }
    },
    [variables, refetch]
  );

  const hasMore = !!success?.nextPageCursor;
  const noResults = !loading && !success?.events.length;
  const showLoadingAnimation =
    (loading && !success?.events) ||
    networkStatus === NetworkStatus.setVariables;

  if (showLoadingAnimation) {
    return (
      <S.LoadingContainer>
        <Loading>
          <LoadingBall />
        </Loading>
      </S.LoadingContainer>
    );
  }

  return (
    <Container display="flex" flexDirection="column">
      {!showLoadingAnimation && (
        <>
          <Container
            marginBottom={space150}
            display="flex"
            style={{ gap: space150 }}
            alignItems="center"
          >
            <Toggle
              buttons={[
                { text: t('log.entityTypes.all') },
                { text: t('log.entityTypes.GIFTBAG') },
                { text: t('log.entityTypes.REGISTRY') },
              ]}
              activeIndex={entityTypeIndex}
              onClick={(_, index: 0 | 1 | 2) => {
                setEntityTypeIndex(index);

                const entities = [
                  undefined,
                  EntityType.Giftbag,
                  EntityType.Registry,
                ];
                const entityType = entities[index];

                onChangeFilter({
                  entityType,
                });
              }}
            />
            <Label>
              {t('search.showing', {
                showing: success?.events?.length || 0,
                total: success?.totalEvents || 0,
              })}
            </Label>
          </Container>
          <Errors {...errorsProps} />

          {!noResults && (
            <S.StyledCarousel>
              <Table inset fullWidth>
                <TableHeader sticky>
                  <tr>
                    <th>
                      <S.TableHeadColumn>
                        {t('log.headings.userType')}
                      </S.TableHeadColumn>
                    </th>
                    {allowedToSeeUserId && (
                      <th style={{ zIndex: 1 }}>
                        <S.TableHeadColumn>
                          {t('log.headings.userId')}
                        </S.TableHeadColumn>
                      </th>
                    )}
                    <th>
                      <S.TableHeadColumn>
                        {t('log.headings.interaction')}
                      </S.TableHeadColumn>
                    </th>
                    <th>
                      <S.TableHeadColumn>
                        {t('log.headings.source')}
                      </S.TableHeadColumn>
                    </th>
                    <th>
                      <S.TableHeadColumn>
                        {t('log.headings.details')}
                      </S.TableHeadColumn>
                    </th>
                    <th>
                      <S.TableHeadColumn>
                        {t('log.headings.time')}
                      </S.TableHeadColumn>
                    </th>
                    <th>
                      <S.TableHeadColumn>
                        {t('log.headings.timeStamp')}
                      </S.TableHeadColumn>
                    </th>
                  </tr>
                </TableHeader>
                <TableBody striped>
                  {!showLoadingAnimation &&
                    success?.events?.map((le, i: number) => (
                      <AuditLogResult
                        key={`${i}_logEvent`}
                        event={le}
                        showRegistry={false}
                        showUserId={allowedToSeeUserId}
                      />
                    ))}
                </TableBody>
              </Table>
            </S.StyledCarousel>
          )}
        </>
      )}

      {noResults && <EmptyState />}
      {((hasMore && !showLoadingAnimation) ||
        (loading && success?.events && !showLoadingAnimation)) && (
        <S.LoadMore
          small
          loading={loading}
          type="secondary"
          text={t('loadMore')}
          onClick={loadMore}
        />
      )}
    </Container>
  );
};
