import React, { ReactNode } from 'react';
import { Navigation } from '../Navigation';

interface IPageContainerProps {
  children: ReactNode;
}

export const PageContainer: React.FC<IPageContainerProps> = ({
  children,
}: IPageContainerProps) => {
  return (
    <main className={'igift-admin-page-container'}>
      <div className={'igift-admin-page-container__inner'}>
        <div className={'igift-admin-page-container__main'}>
          <Navigation />
          {children}
        </div>
        <div className={'igift-admin-page-container__aside'}></div>
      </div>
    </main>
  );
};

export default PageContainer;
