import React from 'react';
import { useT } from 'src/hooks/intl';
import { Role, useUser, hasRoles } from 'src/hooks/auth';
import { FullPageError } from '../../components/HandleErrors/FullPageError';

interface IRoleTemplate {
  children: React.ReactNode;
  approvedRoles: Role[];
  showErrorPage?: boolean;
}

const RoleTemplate: React.FC<IRoleTemplate> = ({
  children,
  approvedRoles,
  showErrorPage,
}: IRoleTemplate) => {
  const { roles, markets } = useUser();
  const t = useT();

  const isApproved = markets.length && hasRoles(approvedRoles, roles);

  if (isApproved) {
    return <>{children}</>;
  }

  const isLoading = roles === undefined;

  if (!isLoading && showErrorPage) {
    return (
      <FullPageError
        title={t('error.generic.title')}
        description={t('error.pageNotFound.description')}
      />
    );
  }

  return null;
};

export default RoleTemplate;
