/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AccountNumber: any;
  BigInt: any;
  Byte: any;
  CountryCode: any;
  Cuid: any;
  Currency: any;
  DID: any;
  Date: string;
  DateTime: Date;
  DateTimeISO: any;
  DeweyDecimal: any;
  Duration: any;
  EmailAddress: string;
  GUID: any;
  HSL: any;
  HSLA: any;
  HexColorCode: any;
  Hexadecimal: any;
  IBAN: any;
  IP: any;
  IPCPatent: any;
  IPv4: any;
  IPv6: any;
  ISBN: any;
  ISO8601Duration: any;
  ItemNo: string;
  JSON: any;
  JSONObject: any;
  JWT: any;
  LCCSubclass: any;
  Latitude: any;
  LocalDate: any;
  LocalDateTime: any;
  LocalEndTime: any;
  LocalTime: any;
  Locale: any;
  Long: any;
  Longitude: any;
  MAC: any;
  NegativeFloat: any;
  NegativeInt: any;
  NonEmptyString: any;
  NonNegativeFloat: any;
  NonNegativeInt: number;
  NonPositiveFloat: any;
  NonPositiveInt: any;
  ObjectID: any;
  PhoneNumber: any;
  Port: any;
  PositiveFloat: any;
  PositiveInt: number;
  PostalCode: any;
  RGB: any;
  RGBA: any;
  RoutingNumber: any;
  SafeInt: any;
  SemVer: any;
  Time: any;
  TimeZone: any;
  Timestamp: any;
  URL: any;
  USCurrency: any;
  UUID: string;
  UnsignedFloat: any;
  UnsignedInt: any;
  UtcOffset: any;
  Void: any;
};

/** The Action that was performed */
export enum Action {
  CheckoutCompleted = 'CHECKOUT_COMPLETED',
  CheckoutStarted = 'CHECKOUT_STARTED',
  Claimed = 'CLAIMED',
  CoOwnerAdded = 'CO_OWNER_ADDED',
  CoOwnerRemoved = 'CO_OWNER_REMOVED',
  Created = 'CREATED',
  Deleted = 'DELETED',
  Edited = 'EDITED',
  HomeDeliveryDisabled = 'HOME_DELIVERY_DISABLED',
  HomeDeliveryEnabled = 'HOME_DELIVERY_ENABLED',
  HomeDeliverySetAddress = 'HOME_DELIVERY_SET_ADDRESS',
  HomeDeliverySetSlots = 'HOME_DELIVERY_SET_SLOTS',
  ItemsAdded = 'ITEMS_ADDED',
  ItemsChangedQuantity = 'ITEMS_CHANGED_QUANTITY',
  ItemsRemoved = 'ITEMS_REMOVED',
  PurchasedOffline = 'PURCHASED_OFFLINE',
  Reopened = 'REOPENED',
  Shared = 'SHARED',
  ShareRenewed = 'SHARE_RENEWED',
  Suspended = 'SUSPENDED',
  Unshared = 'UNSHARED',
  Viewed = 'VIEWED',
}

/** Street address */
export type Address = {
  /** City of street address */
  city?: Maybe<Scalars['String']>;
  /** The country of the street address */
  country?: Maybe<Scalars['String']>;
  /** The postal code of the street address */
  postalCode?: Maybe<Scalars['String']>;
  /** Line 1 of street address */
  streetAddress1?: Maybe<Scalars['String']>;
  /** Line 2 of street address */
  streetAddress2?: Maybe<Scalars['String']>;
};

export type AisleAndBin = SalesLocation & {
  __typename?: 'AisleAndBin';
  aisle: Scalars['String'];
  artNo: Scalars['String'];
  bin: Scalars['String'];
  division: Scalars['String'];
};

/** Error response for when the request can't be performed as the user is already the owner of a required resource */
export type AlreadyOwnerError = Error & {
  __typename?: 'AlreadyOwnerError';
  /** Developer error message providing context to which requested resource was denied */
  message: Scalars['String'];
};

/** An updated field in a registry or gift bag (e.g. title) */
export type AuditField = {
  __typename?: 'AuditField';
  name: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

/** An updated item in a registry or gift bag */
export type AuditItem = {
  __typename?: 'AuditItem';
  itemNo: Scalars['ID'];
  productName?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
};

/** Abstraction of log event */
export type AuditLogEvent = {
  __typename?: 'AuditLogEvent';
  /** The action taken */
  action: Action;
  /** The entity (registry or gift bag) acted on */
  entity: LogEntity;
  /** The fields/properties on a registry or gift bag that have been updated (e.g. title) */
  fields: Array<AuditField>;
  /** The items that were changed (removed or added) */
  items: Array<AuditItem>;
  /** Title of registry at the time of the action, if it was available */
  registryTitle?: Maybe<Scalars['String']>;
  /** The timestamp of the event */
  timestamp: Scalars['DateTime'];
  /** The user that performed the activity */
  user: User;
};

/** Abstraction of log event */
export type AuditLogEventItemsArgs = {
  languageCode: Scalars['String'];
};

/** Identifiers and filtering options to fetch the partial activity log for a specified registry */
export type AuditLogInput = {
  /** Filtering options for when fetching the logs */
  filtering: LogFiltering;
  /** Used to request next page. Use the value received in the last result payload */
  nextPageCursor?: Maybe<Scalars['String']>;
};

/** Payload of the log requested */
export type AuditLogPayload = {
  __typename?: 'AuditLogPayload';
  /** Audit log events matching the query */
  events: Array<AuditLogEvent>;
  /** Used to retrieve next result page */
  nextPageCursor?: Maybe<Scalars['String']>;
  /** Total number of log events matching the search */
  totalEvents: Scalars['Int'];
  /** The result was big and the total number of log events has been truncated. More events can be found by more specific search. */
  truncated: Scalars['Boolean'];
};

export type AuditLogResponse =
  | AuditLogPayload
  | NotFoundError
  | PermissionError;

/** Error response for when a request can't be performed due to incorrect input */
export type BadInputError = Error & {
  __typename?: 'BadInputError';
  /** Developer error message providing context to why the action couldn't be performed */
  message: Scalars['String'];
};

/** Tells if an item can be bought in a specific way */
export type BuyingOption = {
  /** Can the item be bought */
  available: Scalars['Boolean'];
  /** Reason if the item is unavailable */
  reason?: Maybe<Scalars['String']>;
};

/** Cart Error when trying to mutate the cart */
export type CartError = Error & {
  __typename?: 'CartError';
  /** Developer error message */
  message: Scalars['String'];
};

/** Tells if an item can be bought in a specific store */
export type CashCarryBuyingOption = BuyingOption & {
  __typename?: 'CashCarryBuyingOption';
  /** Can the item be bought */
  available: Scalars['Boolean'];
  /** Available quantity */
  quantity?: Maybe<Scalars['Int']>;
  /** Reason if the item is unavailable */
  reason?: Maybe<Scalars['String']>;
  /** Placement(s) in the store for the item */
  salesLocations: Array<SalesLocation>;
};

/** Input abstraction for the changeGiftBagItemsQuantity mutation */
export type ChangeGiftBagItemsQuantityInput = {
  /** The id of the gift bag */
  giftBagId: Scalars['UUID'];
  /** A list of quantity changes */
  items: Array<ChangeGiftBagQuantityItem>;
  /** The id of the registry which the giftbag correlates to */
  registryId: Scalars['UUID'];
};

/** Payload representing result of a request to change quantity of items in a given gift bag */
export type ChangeGiftBagItemsQuantityPayload = {
  __typename?: 'ChangeGiftBagItemsQuantityPayload';
  /** Gift items ItemNo which successfully changed quantity */
  changed: Array<ReservationChange>;
  /** Gift items failed to be changed */
  failed: Array<FailedReservation>;
  /** The gift bag containing any quantity changes made */
  giftBag: GiftBag;
};

export type ChangeGiftBagItemsQuantityResponse =
  | BadInputError
  | ChangeGiftBagItemsQuantityPayload
  | NotFoundError
  | PermissionError;

/** Representation of a quantity change in the giftbag for a given item */
export type ChangeGiftBagQuantityItem = {
  /** The IKEA item number, e.g. '00263850' (always 8 digits) */
  itemNo: Scalars['ID'];
  /** The quantity of items to give (0-999) */
  quantity: Scalars['Int'];
};

/** Filtering type for distinguishing variations of the same product */
export type ClassFilter = {
  __typename?: 'ClassFilter';
  /** Identifier of the filtering type */
  id: Scalars['String'];
  /** Human readable name of the filtering type, e.g. 'Colour' */
  name: Scalars['String'];
  /** The specific values of the filtering type */
  values: Array<FilterValue>;
};

/** Tells if an item can be bought with click & collect */
export type ClickCollectBuyingOption = BuyingOption & {
  __typename?: 'ClickCollectBuyingOption';
  /** Can the item be bought */
  available: Scalars['Boolean'];
  /** Reason if the item is unavailable */
  reason?: Maybe<Scalars['String']>;
};

/** Co-owner information */
export type CoOwner = {
  __typename?: 'CoOwner';
  /** Co-owner id */
  id: Scalars['String'];
  /** Co-owner name */
  name?: Maybe<Scalars['String']>;
};

export type ContactStaff = SalesLocation & {
  __typename?: 'ContactStaff';
  artNo: Scalars['String'];
  division: Scalars['String'];
};

/** The created registries */
export type CreatedRegistries = {
  __typename?: 'CreatedRegistries';
  /** Total amount of created */
  created: Scalars['Int'];
  /** The created types */
  createdByType: CreatedType;
};

/** The event type */
export type CreatedType = {
  __typename?: 'CreatedType';
  /** The amount of BABY_SHOWER event type */
  BABY_SHOWER?: Maybe<Scalars['Int']>;
  /** The amount of BACK_TO_COLLEGE event type */
  BACK_TO_COLLEGE?: Maybe<Scalars['Int']>;
  /** The amount of BIRTHDAY event type */
  BIRTHDAY?: Maybe<Scalars['Int']>;
  /** The amount of GRADUATION event type */
  GRADUATION?: Maybe<Scalars['Int']>;
  /** The amount of HOUSEWARMING event type */
  HOUSEWARMING?: Maybe<Scalars['Int']>;
  /** The amount of OTHER event type */
  OTHER?: Maybe<Scalars['Int']>;
  /** The amount of WEDDING event type */
  WEDDING?: Maybe<Scalars['Int']>;
};

/** Input abstraction for the customerData query */
export type CustomerDataInput = {
  /** The id of the customer */
  partyUId: Scalars['ID'];
};

/** Payload for customer data */
export type CustomerDataPayload = {
  __typename?: 'CustomerDataPayload';
  /** The customer data in text format, null if not found */
  text?: Maybe<Scalars['String']>;
};

export type CustomerDataResponse = CustomerDataPayload;

export type CustomerIdentifierSearchResponse =
  | CustomerIdentifierSearchResultPayload
  | PermissionError;

export type CustomerIdentifierSearchResultPayload = {
  __typename?: 'CustomerIdentifierSearchResultPayload';
  /** Info about the customers matching the query */
  items: Array<CustomerInfo>;
};

/** Basic info from a customer's profile */
export type CustomerInfo = {
  __typename?: 'CustomerInfo';
  /**
   * The birth date name of the customer
   *
   * @deprecated(reason: "Not available in new Customer Master Identifier Search API")
   * @deprecated Not available in new Customer Master Identifier Search API
   */
  birthDate?: Maybe<Scalars['String']>;
  /** The Ikea family card number of the customer */
  cardNumber?: Maybe<Scalars['String']>;
  /** The email of the customer */
  email?: Maybe<Scalars['String']>;
  /** The first name of the customer */
  firstName?: Maybe<Scalars['String']>;
  /** The last name of the customer */
  lastName?: Maybe<Scalars['String']>;
  /** Customer id */
  partyUId?: Maybe<Scalars['String']>;
  /** The mobile phone number of the customer */
  phone?: Maybe<Scalars['String']>;
};

/** A range (possibly open) for events. */
export type DateRange = {
  /** The end date (included) of the date range, if left out all events from start date and onwards */
  endDate?: Maybe<Scalars['DateTime']>;
  /** The start date (included) of the date range, if left out all dates before up until end date */
  startDate?: Maybe<Scalars['DateTime']>;
};

/** Day of week */
export enum Day {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

/** Input for the gift bag you to be deleted */
export type DeleteGiftBagInput = {
  /** The id of the gift bag to delete */
  giftBagId: Scalars['UUID'];
  /** The registryId to which the gift bag belogns to */
  registryId: Scalars['UUID'];
};

/** Payload object for delete gift bag mutation */
export type DeleteGiftBagPayload = {
  __typename?: 'DeleteGiftBagPayload';
  /** Indication if invalidation of a giftbag was successful. */
  success: Scalars['Boolean'];
};

export type DeleteGiftBagResponse =
  | DeleteGiftBagPayload
  | NotFoundError
  | PermissionError;

/** Address and contact info for receiving home deliveries */
export type DeliveryAddress = {
  __typename?: 'DeliveryAddress';
  /** City of street address */
  city: Scalars['String'];
  /** The county */
  county?: Maybe<Scalars['String']>;
  /** Mobile number for delivery notifications */
  mobileNumber: Scalars['String'];
  /** The postal code/zipcode e.g. 90210 */
  postalCode: Scalars['String'];
  /** State code (e.g. CA) */
  stateCode?: Maybe<Scalars['String']>;
  /** Line 1 of street address */
  streetAddress1: Scalars['String'];
  /** Line 2 of street address */
  streetAddress2?: Maybe<Scalars['String']>;
  /** Line 3 of street address */
  streetAddress3?: Maybe<Scalars['String']>;
};

export type Department = SalesLocation & {
  __typename?: 'Department';
  artNo: Scalars['String'];
  division: Scalars['String'];
  name: Scalars['String'];
};

export type DisableHomeDeliveryInput = {
  /** The id of the registry to disable home delivery for */
  registryId: Scalars['UUID'];
};

export type DisableHomeDeliveryPayload = {
  __typename?: 'DisableHomeDeliveryPayload';
  /** The specific home delivery */
  homeDelivery?: Maybe<HomeDelivery>;
};

export type DisableHomeDeliveryResponse =
  | DisableHomeDeliveryPayload
  | NotFoundError
  | PermissionError;

/** Registry or Gift Bag */
export enum EntityType {
  Giftbag = 'GIFTBAG',
  Registry = 'REGISTRY',
}

/** Representation of resolver error meaning that no part of resolver succeeded */
export type Error = {
  /** Developer error message */
  message: Scalars['String'];
};

export enum EventType {
  BabyShower = 'BABY_SHOWER',
  BackToCollege = 'BACK_TO_COLLEGE',
  Birthday = 'BIRTHDAY',
  Graduation = 'GRADUATION',
  Housewarming = 'HOUSEWARMING',
  Other = 'OTHER',
  Wedding = 'WEDDING',
}

/** Partial failure of an item that couldn't be reserved */
export type FailedReservation = {
  __typename?: 'FailedReservation';
  /** The IKEA item number, e.g. '00263850' */
  itemNo: Scalars['ItemNo'];
  /** The quantity that couldn't be reserved */
  quantity: Scalars['PositiveInt'];
};

export type FamilySalesPrice = {
  __typename?: 'FamilySalesPrice';
  /** Calculated delta between the price including and excluding tax */
  indicativeTotalTax?: Maybe<Scalars['Float']>;
  /** Lowest sales price information */
  lowestPreviousSalesPrice?: Maybe<PriceSnapshot>;
  /** The price excluding tax */
  priceExclTax?: Maybe<Scalars['Float']>;
  /** The price including tax */
  priceInclTax?: Maybe<Scalars['Float']>;
};

/** Features that can be configured per market */
export enum Feature {
  /**
   * Registry co-owner feature
   *
   * @deprecated(reason: "Enabled for all markets")
   */
  Coowner = 'COOWNER',
  /** IKEA family membership is required to create a registry or become registry co-owner */
  Family = 'FAMILY',
  /** IKEA family membership and additional iGift consent is required to create a registry or become co-owner */
  FamilyIgiftConsent = 'FAMILY_IGIFT_CONSENT',
  /** New Gift Bag with better Ikea integration enabled */
  GiftBagV2 = 'GIFT_BAG_V2',
  /**
   * In store shopping mode
   *
   * @deprecated(reason: "Enabled for all markets")
   */
  InStoreShopping = 'IN_STORE_SHOPPING',
  /** PII encryption at rest with envelope pattern for access management */
  PiiEncrypt = 'PII_ENCRYPT',
  /** Receiver delivery - Direct delivery to registry address */
  ReceiverDelivery = 'RECEIVER_DELIVERY',
  /** Customers can search for registries that are made publicly available */
  RegistrySearch = 'REGISTRY_SEARCH',
  /** Safer home - Requires confirmation when adding products that can tip and cause injuries */
  SaferHome = 'SAFER_HOME',
  /** Customers can search for registries using the registry share code */
  ShareCodeSearch = 'SHARE_CODE_SEARCH',
}

/** Error response when attempting to use a feature that is not available in the market */
export type FeatureDisabledError = Error & {
  __typename?: 'FeatureDisabledError';
  /** Developer error message */
  message: Scalars['String'];
};

/** Filtering value to distinguish variations of the same product */
export type FilterValue = {
  __typename?: 'FilterValue';
  /** Identifier for specific filter value */
  id: Scalars['String'];
  /** Human readable name of filter value, e.g. 'black' */
  name: Scalars['String'];
};

/** Gift bag for a registry */
export type GiftBag = {
  __typename?: 'GiftBag';
  /** The giver email entered */
  email?: Maybe<Scalars['EmailAddress']>;
  /** Seconds until gift bag reservation expires */
  expiresIn: Scalars['NonNegativeInt'];
  /** The items in the gift bag with product information in the provided languageCode */
  gifts: Array<GiftBagItem>;
  /** Name/alias of the giver (up to 200 characters) */
  giverName?: Maybe<Scalars['String']>;
  /** Unique id for this gift bag */
  id: Scalars['UUID'];
  /** Message provided with the gifts (up to 4000 characters) */
  message?: Maybe<Scalars['String']>;
  /** Order number (only present after purchase) */
  orderNumber?: Maybe<Scalars['String']>;
  /** Date when purchased (only present after purchase) */
  purchaseDate?: Maybe<Scalars['DateTime']>;
  /** The id of the Registry that the gift bag is associated with */
  registryId: Scalars['UUID'];
  /** Bags current state */
  state: GiftBagState;
  /** Set if shopping is done in a physical store */
  store?: Maybe<Store>;
};

/** Gift bag for a registry */
export type GiftBagGiftsArgs = {
  languageCode: Scalars['String'];
};

/** Gift bag item */
export type GiftBagItem = {
  __typename?: 'GiftBagItem';
  /** The IKEA item number, e.g. '00263850' (always 8 digits) */
  itemNo: Scalars['ItemNo'];
  /** Product pricing */
  price?: Maybe<Price>;
  /** Product information */
  product?: Maybe<Product>;
  /** Quantity of given product that the giver is buying (1-999) */
  quantity: Scalars['PositiveInt'];
};

export enum GiftBagState {
  OfflineReserved = 'OFFLINE_RESERVED',
  PendingPurchase = 'PENDING_PURCHASE',
  PurchasedOffline = 'PURCHASED_OFFLINE',
  PurchasedOnline = 'PURCHASED_ONLINE',
  Reserved = 'RESERVED',
  Unreserved = 'UNRESERVED',
}

/** Amount of giftbags purchased */
export type GiftbagPurchased = {
  __typename?: 'GiftbagPurchased';
  purchased: Scalars['Int'];
};

/** Home delivery settings for a registry */
export type HomeDelivery = {
  __typename?: 'HomeDelivery';
  /** The delivery address */
  deliveryAddress?: Maybe<DeliveryAddress>;
  /** Tells if home delivery is enabled */
  enabled: Scalars['Boolean'];
  /** Identifier for the registry */
  registryId: Scalars['UUID'];
  /** The delivery slots */
  slots: Array<HomeDeliverySlot>;
};

/** Tells if an item can be bought with home delivery to specific zip code */
export type HomeDeliveryBuyingOption = BuyingOption & {
  __typename?: 'HomeDeliveryBuyingOption';
  /** Can the item be bought */
  available: Scalars['Boolean'];
  /** The item is limited to few delivery methods, e.g. only available by truck */
  limitedDeliveryMethods?: Maybe<Scalars['Boolean']>;
  /** Reason if the item is unavailable */
  reason?: Maybe<Scalars['String']>;
};

/** Input abstraction for the home delivery query */
export type HomeDeliveryInput = {
  /** RegistryId of the registry to fetch delivery settings for */
  registryId: Scalars['UUID'];
};

/** Payload of the requested home delivery */
export type HomeDeliveryPayload = {
  __typename?: 'HomeDeliveryPayload';
  /** The specific home delivery */
  homeDelivery?: Maybe<HomeDelivery>;
};

export type HomeDeliveryResponse = HomeDeliveryPayload | NotFoundError;

/** Represents a weekly recurring slot for receiving home delivery */
export type HomeDeliverySlot = {
  __typename?: 'HomeDeliverySlot';
  /** Day(s) of week for the slot */
  daysOfWeek: Array<Day>;
  /** Times of day */
  timesOfDay: Array<TimeOfDay>;
};

/** Abstraction of image information */
export type Image = {
  __typename?: 'Image';
  /** Description of item in image */
  alt?: Maybe<Scalars['String']>;
  /** Height of image given in pixels */
  height?: Maybe<Scalars['Int']>;
  /** Url location of Image */
  url: Scalars['String'];
  /** Enum style string of the type of image, e.g. 'INSPIRATIONAL_IMAGE' */
  variant: Scalars['String'];
  /** Height of image given in pixels */
  width?: Maybe<Scalars['Int']>;
};

/** Availabilities for an item */
export type ItemAvailabilities = {
  __typename?: 'ItemAvailabilities';
  /** Cash & Carry option */
  cashCarry?: Maybe<CashCarryBuyingOption>;
  /** Click & Collect option */
  clickCollect?: Maybe<BuyingOption>;
  /** Home delivery option (for giver) */
  homeDelivery?: Maybe<BuyingOption>;
  /** Gift receiver home delivery option */
  receiverDelivery?: Maybe<BuyingOption>;
};

/** The entity the log event references to */
export type LogEntity = {
  __typename?: 'LogEntity';
  /** The id of the giftBag affected */
  giftBagId?: Maybe<Scalars['ID']>;
  /** The id of the registry affected */
  registryId: Scalars['UUID'];
  /** The type of entity acted on */
  type: EntityType;
};

/** Various filtering options to be used to limit the amount of log events fetched */
export type LogFiltering = {
  /** A range of two dates used to filter out excessive events from the search */
  dateRange?: Maybe<DateRange>;
  /** Used to view events only on registry or gift bag */
  entityType?: Maybe<EntityType>;
  /** ID of the user used for filtering */
  userId?: Maybe<Scalars['ID']>;
};

/** Market specific configuration */
export type MarketConfig = {
  __typename?: 'MarketConfig';
  /** Features available in the market */
  features: Array<Feature>;
  /** Configuration for end-user (receiver) registry input */
  registryInput: MarketInputConfig;
};

/** Payload representing the market config */
export type MarketConfigPayload = {
  __typename?: 'MarketConfigPayload';
  marketConfig: MarketConfig;
};

export type MarketConfigResponse = MarketConfigPayload;

/** Market specific configuration for end-user input */
export type MarketInputConfig = {
  __typename?: 'MarketInputConfig';
  /** Allow free text fields */
  allowFreeText: Scalars['Boolean'];
};

export type Measurement = {
  __typename?: 'Measurement';
  /** Imperial system summarization of height width depth */
  imperial?: Maybe<Scalars['String']>;
  /** Metric system summarization of height width depth */
  metric?: Maybe<Scalars['String']>;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please only specify the data fields which you need
 * to consume, otherwise we will waste time and money while we fetch unused resources.
 */
export type Mutation = {
  __typename?: 'Mutation';
  /** Dummy endpoint to make gql generation happy. Please ignore. */
  _?: Maybe<Scalars['Boolean']>;
  /**
   * Update quantity of items in the gift bag
   *
   * @auth(permission: "registry:write")
   */
  changeGiftBagItemsQuantity: ChangeGiftBagItemsQuantityResponse;
  /**
   * Manually marks a gift bag as no longer valid
   *
   * @auth(permission: "registry:write")
   */
  deleteGiftBag: DeleteGiftBagResponse;
  /**
   * Disable home disabled
   *
   * @auth(permission: "registry:write")
   */
  disableHomeDelivery: DisableHomeDeliveryResponse;
  /**
   * Remove co-owner from the registry
   *
   * @auth(permission: "registry:write")
   */
  removeCoOwner: RemoveCoOwnerResponse;
  /**
   * Remove items from the gift bag
   *
   * @auth(permission: "registry:write")
   */
  removeGiftBagItems: RemoveGiftBagItemsResponse;
  /**
   * Removes the registry
   *
   * @auth(permission: "registry:write")
   */
  removeRegistry: RemoveRegistryResponse;
  /**
   * Reopens the registry
   *
   * @auth(permission: "registry:write")
   */
  reopenRegistry: ReopenRegistryResponse;
  /**
   * Sets the gift giver name for a gift bag
   *
   * @auth(permission: "registry:write")
   */
  setGiftGiverName: SetGiftGiverNameResponse;
  /**
   * Sets the personal gift message for a gift bag
   *
   * @auth(permission: "registry:write")
   */
  setGiftMessage: SetGiftMessageResponse;
  /**
   * Sets the gift giver name for a gift bag
   *
   * @auth(permission: "registry:write")
   */
  setGiverEmail: SetGiverEmailResponse;
  /**
   * Suspends the registry
   *
   * @auth(permission: "registry:write")
   */
  suspendRegistry: SuspendRegistryResponse;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please only specify the data fields which you need
 * to consume, otherwise we will waste time and money while we fetch unused resources.
 */
export type MutationChangeGiftBagItemsQuantityArgs = {
  input: ChangeGiftBagItemsQuantityInput;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please only specify the data fields which you need
 * to consume, otherwise we will waste time and money while we fetch unused resources.
 */
export type MutationDeleteGiftBagArgs = {
  input: DeleteGiftBagInput;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please only specify the data fields which you need
 * to consume, otherwise we will waste time and money while we fetch unused resources.
 */
export type MutationDisableHomeDeliveryArgs = {
  input: DisableHomeDeliveryInput;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please only specify the data fields which you need
 * to consume, otherwise we will waste time and money while we fetch unused resources.
 */
export type MutationRemoveCoOwnerArgs = {
  input: RemoveCoOwnerInput;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please only specify the data fields which you need
 * to consume, otherwise we will waste time and money while we fetch unused resources.
 */
export type MutationRemoveGiftBagItemsArgs = {
  input: RemoveGiftBagItemsInput;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please only specify the data fields which you need
 * to consume, otherwise we will waste time and money while we fetch unused resources.
 */
export type MutationRemoveRegistryArgs = {
  input: RemoveRegistryInput;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please only specify the data fields which you need
 * to consume, otherwise we will waste time and money while we fetch unused resources.
 */
export type MutationReopenRegistryArgs = {
  input: ReopenRegistryInput;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please only specify the data fields which you need
 * to consume, otherwise we will waste time and money while we fetch unused resources.
 */
export type MutationSetGiftGiverNameArgs = {
  input: SetGiftGiverNameInput;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please only specify the data fields which you need
 * to consume, otherwise we will waste time and money while we fetch unused resources.
 */
export type MutationSetGiftMessageArgs = {
  input: SetGiftMessageInput;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please only specify the data fields which you need
 * to consume, otherwise we will waste time and money while we fetch unused resources.
 */
export type MutationSetGiverEmailArgs = {
  input: SetGiverEmailInput;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please only specify the data fields which you need
 * to consume, otherwise we will waste time and money while we fetch unused resources.
 */
export type MutationSuspendRegistryArgs = {
  input: SuspendRegistryInput;
};

/** Error response for when a request can't be fulfilled due to a resource not being found */
export type NotFoundError = Error & {
  __typename?: 'NotFoundError';
  /** Developer error message providing context to which required resource couldn't be found */
  message: Scalars['String'];
};

/** Error response for when the request can't be performed as the user is not the owner of a required resource */
export type NotOwnerError = Error & {
  __typename?: 'NotOwnerError';
  /** Developer error message providing context to which requested resource was denied */
  message: Scalars['String'];
};

/** Error response when the page size provided for the search fall outside preset range */
export type PageSizeError = Error & {
  __typename?: 'PageSizeError';
  /** Developer error message providing context to why the action couldn't be performed */
  message: Scalars['String'];
};

/** Error response for attempting to change a gift bag that is pending purchase */
export type PendingPurchaseError = Error & {
  __typename?: 'PendingPurchaseError';
  /** Seconds until the purchase times out */
  expiresIn: Scalars['Int'];
  /** Developer error message providing context to why the action couldn't be performed */
  message: Scalars['String'];
};

/** Reason for permission error */
export enum PermissionDenialReason {
  /** Administrative role required (back-office access) */
  Admin = 'ADMIN',
  /** Login is required (guest user) */
  Authentication = 'AUTHENTICATION',
  /** IKEA Family membership is required */
  Family = 'FAMILY',
  /** IKEA Family membership and iGift consent is required */
  FamilyIgiftConsent = 'FAMILY_IGIFT_CONSENT',
}

/** Error response for when a request can't be performed due to insufficient permissions. */
export type PermissionError = Error & {
  __typename?: 'PermissionError';
  /** Developer error message providing context to the required auth level */
  message: Scalars['String'];
  /** The reason that the permission was denied */
  reason: PermissionDenialReason;
};

/** The price for a product */
export type Price = {
  __typename?: 'Price';
  /** Price change reason */
  changeReason?: Maybe<Scalars['String']>;
  /** The currency code (in ISO-4217 standard?) */
  currencyCode: Scalars['String'];
  /** The price excluding tax */
  exclTax: Scalars['Float'];
  /** Family sales price information */
  familySalesPrice?: Maybe<FamilySalesPrice>;
  /** The price including tax */
  inclTax: Scalars['Float'];
  /** Calculated delta between the price including and excluding tax */
  indicativeTotalTax: Scalars['Float'];
  /** Lowest sales price information */
  lowestPreviousSalesPrice?: Maybe<PriceSnapshot>;
  /** Previous sales price information */
  previousSalesPrice?: Maybe<PriceSnapshot>;
  /** Price offer type */
  priceOfferType?: Maybe<PriceOfferType>;
  /** Product unit price information */
  priceUnit?: Maybe<Array<Maybe<PriceUnit>>>;
  /** Valid from date for new lower price and time restricted offers */
  validFromDate?: Maybe<Scalars['String']>;
  /** Valid to date for time restricted offers */
  validToDate?: Maybe<Scalars['String']>;
};

/** Price offer type */
export enum PriceOfferType {
  /** IKEA Family price offer */
  Family = 'family',
  /** New lower price */
  Nlp = 'nlp',
  /** No current price offer */
  None = 'none',
  /** Time restricted offer */
  Tro = 'tro',
}

export type PriceSnapshot = {
  __typename?: 'PriceSnapshot';
  /** Display flag for omnibus pricing */
  display?: Maybe<Scalars['Boolean']>;
  /** Calculated delta between the price including and excluding tax */
  indicativeTotalTax?: Maybe<Scalars['Float']>;
  /** The price excluding tax */
  priceExclTax?: Maybe<Scalars['Float']>;
  /** The price including tax */
  priceInclTax?: Maybe<Scalars['Float']>;
};

/** Pricing Unit for the product */
export type PriceUnit = {
  __typename?: 'PriceUnit';
  /** The language code */
  languageCode: Scalars['String'];
  /** The unit metric */
  unitMetric: Scalars['String'];
};

/** The product information */
export type Product = {
  __typename?: 'Product';
  /** Filters to distinguish variations of the provided product, e.g. colour */
  classFilters?: Maybe<Array<ClassFilter>>;
  /** Name of the Home Furnishing Business the product belongs to, e.g. "IKEA Food" */
  homeFurnishingBusinessName?: Maybe<Scalars['String']>;
  /** Provided image information to be used to represent the product */
  images: Array<Image>;
  /** Is breath taking item */
  isBreathTakingItem?: Maybe<Scalars['Boolean']>;
  /** Is new item */
  isNew?: Maybe<Scalars['Boolean']>;
  /** The identifier of the product */
  itemNo: Scalars['ID'];
  /** Measurements for the product */
  measurements?: Maybe<Array<Measurement>>;
  /** The regional name of the product */
  productName: Scalars['String'];
  /** The global name of the product */
  productNameGlobal: Scalars['String'];
  /** The type of the product */
  productType: Scalars['String'];
  /** Misc design details, e.g. colour */
  validDesign?: Maybe<Scalars['String']>;
};

/** Input abstraction for the purchased giftBag query */
export type PurchasedGiftBagsInput = {
  /** The registryId to fetch purchased gift bags for */
  registryId: Scalars['UUID'];
};

/** Payload object for purchased gift bags query */
export type PurchasedGiftBagsPayload = {
  __typename?: 'PurchasedGiftBagsPayload';
  /** Currently purchased gift bags */
  giftBags: Array<GiftBag>;
};

export type PurchasedGiftBagsResponse =
  | NotFoundError
  | PermissionError
  | PurchasedGiftBagsPayload;

/**
 * The query type defines GraphQL operations that retrieve data from the server.
 *
 * Note! Please only specify the data fields which you need to consume, otherwise we
 * will waste time and money while we fetch unused resources.
 */
export type Query = {
  __typename?: 'Query';
  /** Dummy endpoint to make gql generation happy. Please ignore. */
  _?: Maybe<Scalars['Boolean']>;
  /**
   * Retrieve audit events for registries and/or gift bags
   *
   * @auth(permission: "audit:read")
   */
  auditLog: AuditLogResponse;
  /** Lookup information stored for a customer in iGift */
  customerData: CustomerDataResponse;
  /**
   * Search for matching customer profiles. Identifier can be mobile, email address, IKEA family number or customer Id
   *
   * @auth(permission: "registry:read")
   */
  customerIdentifierSearch: CustomerIdentifierSearchResponse;
  /** List features enabled for the market */
  enabledFeatures: Array<Feature>;
  /** Fetch home delivery settings for a specific registry by provided registryId */
  homeDelivery: HomeDeliveryResponse;
  /** Fetch market config for the current country */
  marketConfig: MarketConfigResponse;
  /**
   * Fetch all purchased gift bags for the provided registryId
   *
   * @auth(permission: "registry:read")
   */
  purchasedGiftBags: PurchasedGiftBagsResponse;
  /**
   * Fetch a specific registry by provided registryId
   *
   * @auth(permission: "registry:read")
   */
  registry: RegistryResponse;
  /**
   * Fetch audit events for a specific registry
   *
   * @auth(permission: "registry:read")
   */
  registryLog: RegistryLogResponse;
  /**
   * Search for all matching registries
   *
   * @auth(permission: "registry:read")
   */
  registrySearch: RegistrySearchResponse;
  /**
   * Fetch stats of all registry
   *
   * @auth(permission: "registry:read")
   */
  registryStats: RegistryStatsResponse;
  /** Lookup which context you have */
  viewer: ViewerResponse;
};

/**
 * The query type defines GraphQL operations that retrieve data from the server.
 *
 * Note! Please only specify the data fields which you need to consume, otherwise we
 * will waste time and money while we fetch unused resources.
 */
export type QueryAuditLogArgs = {
  input: AuditLogInput;
};

/**
 * The query type defines GraphQL operations that retrieve data from the server.
 *
 * Note! Please only specify the data fields which you need to consume, otherwise we
 * will waste time and money while we fetch unused resources.
 */
export type QueryCustomerDataArgs = {
  input: CustomerDataInput;
};

/**
 * The query type defines GraphQL operations that retrieve data from the server.
 *
 * Note! Please only specify the data fields which you need to consume, otherwise we
 * will waste time and money while we fetch unused resources.
 */
export type QueryCustomerIdentifierSearchArgs = {
  identifier: Scalars['String'];
};

/**
 * The query type defines GraphQL operations that retrieve data from the server.
 *
 * Note! Please only specify the data fields which you need to consume, otherwise we
 * will waste time and money while we fetch unused resources.
 */
export type QueryHomeDeliveryArgs = {
  input: HomeDeliveryInput;
};

/**
 * The query type defines GraphQL operations that retrieve data from the server.
 *
 * Note! Please only specify the data fields which you need to consume, otherwise we
 * will waste time and money while we fetch unused resources.
 */
export type QueryPurchasedGiftBagsArgs = {
  input: PurchasedGiftBagsInput;
};

/**
 * The query type defines GraphQL operations that retrieve data from the server.
 *
 * Note! Please only specify the data fields which you need to consume, otherwise we
 * will waste time and money while we fetch unused resources.
 */
export type QueryRegistryArgs = {
  input: RegistryInput;
};

/**
 * The query type defines GraphQL operations that retrieve data from the server.
 *
 * Note! Please only specify the data fields which you need to consume, otherwise we
 * will waste time and money while we fetch unused resources.
 */
export type QueryRegistryLogArgs = {
  input: RegistryLogInput;
};

/**
 * The query type defines GraphQL operations that retrieve data from the server.
 *
 * Note! Please only specify the data fields which you need to consume, otherwise we
 * will waste time and money while we fetch unused resources.
 */
export type QueryRegistrySearchArgs = {
  input: RegistrySearchInput;
};

/** Gift Registry */
export type Registry = {
  __typename?: 'Registry';
  /** A boolean value that indicate if the registry is the currently active registry */
  active: Scalars['Boolean'];
  /** Co-owners of the registry */
  coOwners: Array<CoOwner>;
  /** Description for the registry event (up to 4000 characters) */
  description?: Maybe<Scalars['String']>;
  /** Date for the event */
  eventDate?: Maybe<Scalars['DateTime']>;
  /** The type of event the registry is used for */
  eventType: EventType;
  /** If its a guest registry */
  guest: Scalars['Boolean'];
  /** Boolean that indicates if gifts can be delivered to the registry owner's home */
  homeDeliveryEnabled: Scalars['Boolean'];
  /** Identifier for the registry */
  id: Scalars['UUID'];
  /** Owner of the registry */
  owner: Scalars['ID'];
  /** Tells if the registry can be found using public registry search */
  publiclySearchable: Scalars['Boolean'];
  /** Code used by givers to access fetch the registry */
  shareCode?: Maybe<Scalars['String']>;
  /** Identifier used by givers to access fetch the registry */
  shareId?: Maybe<Scalars['String']>;
  /** Boolean that indicates if the registry is currently available for givers */
  shared: Scalars['Boolean'];
  /** Selected stock image */
  stockImage: Scalars['String'];
  /** Boolean that indicates if the registry is currently suspended for suspicious behavior */
  suspended: Scalars['Boolean'];
  /** The time of suspension */
  suspensionDate?: Maybe<Scalars['DateTime']>;
  /** Title describing the registry event (up to 200 characters) */
  title?: Maybe<Scalars['String']>;
  /** Date of last registry update */
  updated?: Maybe<Scalars['DateTime']>;
  /** The wished for items with product information in the provided languageCode */
  wishlist: Array<RegistryItem>;
};

/** Gift Registry */
export type RegistryWishlistArgs = {
  languageCode: Scalars['String'];
};

/** Info about matching Gift Registry */
export type RegistryInfo = {
  __typename?: 'RegistryInfo';
  /** Date for the event */
  eventDate?: Maybe<Scalars['DateTime']>;
  /** The type of event */
  eventType: EventType;
  /** Identifier for the registry */
  id: Scalars['UUID'];
  /** Number of items in the wish list */
  nbrItems: Scalars['Int'];
  /** Title describing the registry event (up to 200 characters) */
  title?: Maybe<Scalars['String']>;
};

/** Input abstraction for the registry query */
export type RegistryInput = {
  /** RegistryId of the registry you want to fetch */
  registryId: Scalars['UUID'];
};

/** Registry item */
export type RegistryItem = {
  __typename?: 'RegistryItem';
  /** Availabilities for an item, can be retrieved if zipCode and/or store has been specified */
  availabilities?: Maybe<ItemAvailabilities>;
  /** Quantity available for purchase */
  availableQuantity: Scalars['NonNegativeInt'];
  /** The IKEA item number, e.g. '00263850' (always 8 digits). */
  itemNo: Scalars['ItemNo'];
  /** A flag that allows the registry owner to mark the item as most desired */
  mostDesired: Scalars['Boolean'];
  /** Product pricing */
  price?: Maybe<Price>;
  /** Product information */
  product?: Maybe<Product>;
  /** Quantity bought by givers (0-999) */
  purchasedQuantity: Scalars['NonNegativeInt'];
  /** Quantity reserved by givers */
  reservedQuantity: Scalars['NonNegativeInt'];
  /** Safer home (currently only US) */
  saferHome?: Maybe<SaferHome>;
  /** Quantity wished for (1-999) */
  totalQuantity: Scalars['PositiveInt'];
};

/** Identifiers and filtering options to fetch the partial activity log for a specified registry */
export type RegistryLogInput = {
  /** Filtering options for when fetching the logs */
  filtering: LogFiltering;
  /** Used to request next page. Use the value received in the last result payload */
  nextPageCursor?: Maybe<Scalars['String']>;
  /** RegistryId of the registry you want the log for */
  registryId: Scalars['UUID'];
};

/** Payload of the log requested */
export type RegistryLogPayload = {
  __typename?: 'RegistryLogPayload';
  /** Activity log for specified registry */
  events: Array<AuditLogEvent>;
  /** Used to retrieve next result page */
  nextPageCursor?: Maybe<Scalars['String']>;
  /** Total number of log events matching the search */
  totalEvents: Scalars['Int'];
  /** The result was too big and the total number of log events has been truncated. */
  truncated: Scalars['Boolean'];
};

export type RegistryLogResponse =
  | NotFoundError
  | PermissionError
  | RegistryLogPayload;

/** Payload of the registry requested */
export type RegistryPayload = {
  __typename?: 'RegistryPayload';
  /** The specific registry requested */
  registry?: Maybe<Registry>;
};

export type RegistryResponse =
  | NotFoundError
  | PermissionError
  | RegistryPayload;

/** Input used to find registries matching all fields */
export type RegistrySearchInput = {
  /** The date of the event */
  eventDate?: Maybe<Scalars['DateTime']>;
  /** The type of event */
  eventType?: Maybe<EventType>;
  /** Used to request next page. Use the value received in the last search result payload */
  nextPageCursor?: Maybe<Scalars['String']>;
  /** Id of registry owner, partyUId or guest id */
  owner?: Maybe<Scalars['String']>;
  /** Number of results to be returned */
  pageSize?: Maybe<Scalars['Int']>;
  /** Title to search for, all words are required to match */
  title?: Maybe<Scalars['String']>;
};

export type RegistrySearchResponse =
  | BadInputError
  | PermissionError
  | RegistrySearchResultPayload
  | TooManyMatchingCustomersError;

/** Payload representing the search result */
export type RegistrySearchResultPayload = {
  __typename?: 'RegistrySearchResultPayload';
  /** Info about the registries matching the query */
  items: Array<RegistryInfo>;
  /** Used to fetch next page of search result */
  nextPageCursor?: Maybe<Scalars['String']>;
};

/** Payload representing the stats result */
export type RegistryStatsPayload = {
  __typename?: 'RegistryStatsPayload';
  /** Amount of created registries last thirty days */
  statsLast30: Stats;
  /** Total amount of created registries */
  statsTotal: Stats;
};

export type RegistryStatsResponse = PermissionError | RegistryStatsPayload;

/** Input abstraction for the removeCoOwner mutation */
export type RemoveCoOwnerInput = {
  /** Co-owner id */
  coOwnerId: Scalars['ID'];
  /** Id of the registry */
  registryId: Scalars['UUID'];
};

/** Payload representing result of a request to remove co-owner from a given registry */
export type RemoveCoOwnerPayload = {
  __typename?: 'RemoveCoOwnerPayload';
  /** The registry without the co-owner */
  registry: Registry;
};

export type RemoveCoOwnerResponse =
  | NotFoundError
  | PermissionError
  | RemoveCoOwnerPayload;

/** Input abstraction for the removeGiftBagItems mutation */
export type RemoveGiftBagItemsInput = {
  /** The gift bag id */
  giftBagId: Scalars['UUID'];
  /** A list of item numbers to remove */
  itemNos: Array<Scalars['ID']>;
  /** The registry id */
  registryId: Scalars['UUID'];
};

/** Payload representing result of a request to remove items from a gift bag */
export type RemoveGiftBagItemsPayload = {
  __typename?: 'RemoveGiftBagItemsPayload';
  /** The gift bag containing any alterations made */
  giftBag: GiftBag;
};

export type RemoveGiftBagItemsResponse =
  | NotFoundError
  | PermissionError
  | RemoveGiftBagItemsPayload;

/** Input abstraction for the removeRegistry mutation */
export type RemoveRegistryInput = {
  /** The registryId of the registry you want to remove */
  registryId: Scalars['UUID'];
};

/** Payload representing result of removing request */
export type RemoveRegistryPayload = {
  __typename?: 'RemoveRegistryPayload';
  /** Indicates success of registry removal */
  success: Scalars['Boolean'];
};

export type RemoveRegistryResponse =
  | NotFoundError
  | PermissionError
  | RemoveRegistryPayload;

/** Input abstraction for the reopenRegistry mutation */
export type ReopenRegistryInput = {
  /** The registryId of the registry you want to reopen */
  registryId: Scalars['ID'];
};

/** Payload representing result of reopening request */
export type ReopenRegistryPayload = {
  __typename?: 'ReopenRegistryPayload';
  /** The registry */
  registry: Registry;
};

export type ReopenRegistryResponse =
  | BadInputError
  | NotFoundError
  | PermissionError
  | ReopenRegistryPayload;

/** Partial success of an item which reservation changed */
export type ReservationChange = {
  __typename?: 'ReservationChange';
  /** The IKEA item number, e.g. '00263850' */
  itemNo: Scalars['ItemNo'];
  /** The quantity change */
  quantity: Scalars['Int'];
};

/** Response indicating that the giftbag reservation has expired and needs to be refreshed. */
export type ReservationExpired = Error & {
  __typename?: 'ReservationExpired';
  /** Developer message informing about refresh requirements */
  message: Scalars['String'];
};

/** Error response for when a reservation can't be fulfilled */
export type ReservationQuantityError = Error & {
  __typename?: 'ReservationQuantityError';
  /** The IKEA item number, e.g. '00263850' */
  itemNo: Scalars['ItemNo'];
  /** Developer error message providing context to which required resource couldn't be found */
  message: Scalars['String'];
};

/** Error response for when a reservation can't be fulfilled */
export type ReservationUnavailableError = Error & {
  __typename?: 'ReservationUnavailableError';
  /** The IKEA item number, e.g. '00263850' */
  itemNo: Scalars['ItemNo'];
  /** Developer error message providing context to which required resource couldn't be found */
  message: Scalars['String'];
};

/** Safer home setting for the product */
export type SaferHome = {
  __typename?: 'SaferHome';
  /** The registry owner has confirmed that he/she understands the product needs to be attached to the wall */
  confirmedAttachToWall: Scalars['Boolean'];
};

export type SalesLocation = {
  artNo: Scalars['String'];
  division: Scalars['String'];
};

/** Input abstraction for the setGiftGiverName mutation */
export type SetGiftGiverNameInput = {
  /** The id of the gift bag */
  giftBagId: Scalars['UUID'];
  /** The name (one line up to 200 characters) to be set as the giver of the giftbag */
  giverName: Scalars['String'];
  /** The id of the registry which the giftbag correlates to */
  registryId: Scalars['UUID'];
};

/** Payload representing result of a request to set the gift giver name in a gift bag */
export type SetGiftGiverNamePayload = {
  __typename?: 'SetGiftGiverNamePayload';
  /** The gift bag containing any updates made to the gift giver name */
  giftBag: GiftBag;
};

export type SetGiftGiverNameResponse =
  | BadInputError
  | NotFoundError
  | PermissionError
  | SetGiftGiverNamePayload;

/** Input abstraction for the setGiftMessageInput mutation */
export type SetGiftMessageInput = {
  /** The id of the gift bag */
  giftBagId: Scalars['UUID'];
  /** The message to be set to the giftbag (up to 4000 characters) */
  message: Scalars['String'];
  /** The id of the registry which the giftbag correlates to */
  registryId: Scalars['UUID'];
};

/** Payload representing result of a request to set the gift message of a gift bag */
export type SetGiftMessagePayload = {
  __typename?: 'SetGiftMessagePayload';
  /** The gift bag containing any updates made to the gift message */
  giftBag: GiftBag;
};

export type SetGiftMessageResponse =
  | BadInputError
  | NotFoundError
  | PermissionError
  | SetGiftMessagePayload;

/** Input abstraction for the setGiverEmail mutation */
export type SetGiverEmailInput = {
  /** Email for the giver  */
  email: Scalars['EmailAddress'];
  /** The id of the gift bag */
  giftBagId: Scalars['UUID'];
  /** The id of the registry which the giftbag correlates to */
  registryId: Scalars['UUID'];
};

/** Payload representing result of a request to set the gift giver name in a gift bag */
export type SetGiverEmailPayload = {
  __typename?: 'SetGiverEmailPayload';
  /** The gift bag containing any updates made to the gift giver name */
  giftBag: GiftBag;
};

export type SetGiverEmailResponse =
  | BadInputError
  | NotFoundError
  | PermissionError
  | SetGiverEmailPayload;

/** The statistics of the registries */
export type Stats = {
  __typename?: 'Stats';
  /** Contains amount of giftbags purchased */
  giftBags: GiftbagPurchased;
  /** Last update of the statistics */
  lastUpdated: Scalars['DateTime'];
  /** The types of created with amount of created registries */
  registries: CreatedRegistries;
};

/** Information about a physical store */
export type Store = {
  __typename?: 'Store';
  /** Name used to describe the store's geographical location (e.g. Stockholm Kungens Kurva) */
  displayName: Scalars['String'];
  /** Id of store */
  id: Scalars['String'];
};

/** Input abstraction for the suspendRegistry mutation */
export type SuspendRegistryInput = {
  /** The registryId of the registry you want to suspend */
  registryId: Scalars['UUID'];
};

/** Payload representing result of suspension request */
export type SuspendRegistryPayload = {
  __typename?: 'SuspendRegistryPayload';
  /** The registry */
  registry: Registry;
};

export type SuspendRegistryResponse =
  | BadInputError
  | NotFoundError
  | PermissionError
  | SuspendRegistryPayload;

/** Response indicating that the action is blocked due to the affected registry currently being suspended. */
export type SuspendedRegistryError = Error & {
  __typename?: 'SuspendedRegistryError';
  /** Developer message informing why a suspended registry can't perform specified action */
  message: Scalars['String'];
  /** The time of suspension */
  suspensionDate: Scalars['DateTime'];
};

/** Time of day */
export enum TimeOfDay {
  Afternoon = 'AFTERNOON',
  Evening = 'EVENING',
  Morning = 'MORNING',
}

/** Error response for when a search result matches too many customers */
export type TooManyMatchingCustomersError = Error & {
  __typename?: 'TooManyMatchingCustomersError';
  /** Developer error message */
  message: Scalars['String'];
};

/** Error response for when a request can't be performed due to availability tied to business logic */
export type UnavailableError = Error & {
  __typename?: 'UnavailableError';
  /** Developer error message providing context to why the action couldn't be performed */
  message: Scalars['String'];
};

/** Error response for when a request can't be performed due to issues when performing requests to a third party */
export type UpstreamError = Error & {
  __typename?: 'UpstreamError';
  /** Developer error message providing context to why the action couldn't be performed */
  message: Scalars['String'];
};

/** User details */
export type User = {
  __typename?: 'User';
  /**
   * The id of the user
   *
   * @auth(permission: "audit:read")
   */
  id?: Maybe<Scalars['ID']>;
  /** The type of user */
  type: UserType;
};

/** The type of user that performed the action */
export enum UserType {
  Customer = 'CUSTOMER',
  Personnel = 'PERSONNEL',
}

/** Payload of current viewer */
export type ViewerPayload = {
  __typename?: 'ViewerPayload';
  /** The email address of the viewer */
  email: Scalars['String'];
  /** The user id */
  id: Scalars['ID'];
  /** The api permissions of the viewer */
  permissions: Array<Scalars['String']>;
};

export type ViewerResponse = ViewerPayload;

export type ProductFragment = {
  __typename?: 'Product';
  itemNo: string;
  productNameGlobal: string;
  productName: string;
  productType: string;
  classFilters?: Maybe<
    Array<{
      __typename?: 'ClassFilter';
      id: string;
      name: string;
      values: Array<{ __typename?: 'FilterValue'; id: string; name: string }>;
    }>
  >;
  measurements?: Maybe<
    Array<{
      __typename?: 'Measurement';
      metric?: Maybe<string>;
      imperial?: Maybe<string>;
    }>
  >;
  images: Array<{ __typename?: 'Image' } & ImageFragment>;
};

export type ImageFragment = {
  __typename?: 'Image';
  alt?: Maybe<string>;
  variant: string;
  height?: Maybe<number>;
  width?: Maybe<number>;
  url: string;
};

export type PriceFragment = {
  __typename?: 'Price';
  currencyCode: string;
  exclTax: number;
  inclTax: number;
  indicativeTotalTax: number;
};

export type RegistryFragment = {
  __typename?: 'Registry';
  id: string;
  eventType: EventType;
  title?: Maybe<string>;
  description?: Maybe<string>;
  eventDate?: Maybe<Date>;
  shared: boolean;
  shareId?: Maybe<string>;
  suspended: boolean;
  stockImage: string;
  owner: string;
  coOwners: Array<{ __typename?: 'CoOwner'; id: string; name?: Maybe<string> }>;
} & RegistryWishlistFragment;

export type RegistryWishlistFragment = {
  __typename?: 'Registry';
  wishlist: Array<{
    __typename?: 'RegistryItem';
    itemNo: string;
    totalQuantity: number;
    purchasedQuantity: number;
    reservedQuantity: number;
    availableQuantity: number;
    price?: Maybe<{ __typename?: 'Price' } & PriceFragment>;
    product?: Maybe<{ __typename?: 'Product' } & ProductFragment>;
  }>;
};

export type RegistryNoProductFragment = {
  __typename?: 'Registry';
  id: string;
  eventType: EventType;
  title?: Maybe<string>;
  description?: Maybe<string>;
  eventDate?: Maybe<Date>;
  shared: boolean;
  shareId?: Maybe<string>;
  stockImage: string;
  wishlist: Array<{
    __typename?: 'RegistryItem';
    itemNo: string;
    totalQuantity: number;
    purchasedQuantity: number;
    reservedQuantity: number;
    availableQuantity: number;
  }>;
};

export type AuditLogEventFragment = {
  __typename?: 'AuditLogEvent';
  action: Action;
  timestamp: Date;
  registryTitle?: Maybe<string>;
  fields: Array<{
    __typename?: 'AuditField';
    name: string;
    value?: Maybe<string>;
  }>;
  items: Array<{
    __typename?: 'AuditItem';
    itemNo: string;
    quantity?: Maybe<number>;
    productName?: Maybe<string>;
  }>;
  entity: {
    __typename?: 'LogEntity';
    type: EntityType;
    registryId: string;
    giftBagId?: Maybe<string>;
  };
  user: { __typename?: 'User'; type: UserType; id?: Maybe<string> };
};

export type RegistryInfoFragment = {
  __typename?: 'RegistryInfo';
  eventDate?: Maybe<Date>;
  eventType: EventType;
  id: string;
  nbrItems: number;
  title?: Maybe<string>;
};

export type CustomerInfoFragment = {
  __typename?: 'CustomerInfo';
  partyUId?: Maybe<string>;
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
  birthDate?: Maybe<string>;
  email?: Maybe<string>;
  phone?: Maybe<string>;
  cardNumber?: Maybe<string>;
};

export type StatsFragment = {
  __typename?: 'Stats';
  lastUpdated: Date;
  registries: {
    __typename?: 'CreatedRegistries';
    created: number;
    createdByType: {
      __typename?: 'CreatedType';
      BABY_SHOWER?: Maybe<number>;
      BIRTHDAY?: Maybe<number>;
      GRADUATION?: Maybe<number>;
      HOUSEWARMING?: Maybe<number>;
      BACK_TO_COLLEGE?: Maybe<number>;
      OTHER?: Maybe<number>;
      WEDDING?: Maybe<number>;
    };
  };
  giftBags: { __typename?: 'GiftbagPurchased'; purchased: number };
};

export type AuditLogQueryVariables = Exact<{
  input: AuditLogInput;
  languageCode: Scalars['String'];
  shouldFetchId: Scalars['Boolean'];
}>;

export type AuditLogQuery = {
  auditLog:
    | {
        __typename: 'AuditLogPayload';
        nextPageCursor?: Maybe<string>;
        totalEvents: number;
        truncated: boolean;
        events: Array<{ __typename?: 'AuditLogEvent' } & AuditLogEventFragment>;
      }
    | { __typename: 'NotFoundError'; message: string }
    | { __typename: 'PermissionError'; message: string };
};

export type RegistrySearchQueryVariables = Exact<{
  input: RegistrySearchInput;
}>;

export type RegistrySearchQuery = {
  registrySearch:
    | { __typename: 'BadInputError' }
    | { __typename: 'PermissionError' }
    | {
        __typename: 'RegistrySearchResultPayload';
        nextPageCursor?: Maybe<string>;
        items: Array<{ __typename?: 'RegistryInfo' } & RegistryInfoFragment>;
      }
    | { __typename: 'TooManyMatchingCustomersError' };
};

export type RegistryStatsQueryVariables = Exact<{ [key: string]: never }>;

export type RegistryStatsQuery = {
  registryStats:
    | { __typename: 'PermissionError' }
    | {
        __typename: 'RegistryStatsPayload';
        statsTotal: { __typename?: 'Stats' } & StatsFragment;
        statsLast30: { __typename?: 'Stats' } & StatsFragment;
      };
};

export type GetRegistryQueryVariables = Exact<{
  input: RegistryInput;
  languageCode: Scalars['String'];
}>;

export type GetRegistryQuery = {
  registry:
    | { __typename: 'NotFoundError'; message: string }
    | { __typename: 'PermissionError'; message: string }
    | {
        __typename: 'RegistryPayload';
        registry?: Maybe<{ __typename?: 'Registry' } & RegistryFragment>;
      };
};

export type PurchasedGiftBagsQueryVariables = Exact<{
  input: PurchasedGiftBagsInput;
  languageCode: Scalars['String'];
}>;

export type PurchasedGiftBagsQuery = {
  purchasedGiftBags:
    | { __typename: 'NotFoundError' }
    | { __typename: 'PermissionError' }
    | {
        __typename: 'PurchasedGiftBagsPayload';
        giftBags: Array<{ __typename?: 'GiftBag' } & GiftBagFragment>;
      };
};

export type RegistryLogQueryVariables = Exact<{
  input: RegistryLogInput;
  languageCode: Scalars['String'];
  shouldFetchId: Scalars['Boolean'];
}>;

export type RegistryLogQuery = {
  registryLog:
    | { __typename: 'NotFoundError'; message: string }
    | { __typename: 'PermissionError'; message: string }
    | {
        __typename: 'RegistryLogPayload';
        nextPageCursor?: Maybe<string>;
        totalEvents: number;
        truncated: boolean;
        events: Array<{ __typename?: 'AuditLogEvent' } & AuditLogEventFragment>;
      };
};

export type RemoveRegistryMutationVariables = Exact<{
  input: RemoveRegistryInput;
}>;

export type RemoveRegistryMutation = {
  removeRegistry:
    | { __typename: 'NotFoundError'; message: string }
    | { __typename: 'PermissionError'; message: string }
    | { __typename: 'RemoveRegistryPayload'; success: boolean };
};

export type SuspendRegistryMutationVariables = Exact<{
  input: SuspendRegistryInput;
  languageCode: Scalars['String'];
}>;

export type SuspendRegistryMutation = {
  suspendRegistry:
    | { __typename: 'BadInputError'; message: string }
    | { __typename: 'NotFoundError'; message: string }
    | { __typename: 'PermissionError'; message: string }
    | {
        __typename: 'SuspendRegistryPayload';
        registry: { __typename?: 'Registry' } & RegistryFragment;
      };
};

export type ReopenRegistryMutationVariables = Exact<{
  input: ReopenRegistryInput;
  languageCode: Scalars['String'];
}>;

export type ReopenRegistryMutation = {
  reopenRegistry:
    | { __typename: 'BadInputError'; message: string }
    | { __typename: 'NotFoundError'; message: string }
    | { __typename: 'PermissionError'; message: string }
    | {
        __typename: 'ReopenRegistryPayload';
        registry: { __typename?: 'Registry' } & RegistryFragment;
      };
};

export type GiftBagFragment = {
  __typename?: 'GiftBag';
  id: string;
  registryId: string;
  message?: Maybe<string>;
  giverName?: Maybe<string>;
  state: GiftBagState;
  email?: Maybe<string>;
  orderNumber?: Maybe<string>;
  purchaseDate?: Maybe<Date>;
} & GiftBagGiftsFragment;

export type GiftBagGiftsFragment = {
  __typename?: 'GiftBag';
  gifts: Array<{
    __typename?: 'GiftBagItem';
    itemNo: string;
    quantity: number;
    product?: Maybe<{ __typename?: 'Product' } & ProductFragment>;
  }>;
};

export type ChangeGiftBagItemsQuantityMutationVariables = Exact<{
  changeGiftBagItemsQuantityInput: ChangeGiftBagItemsQuantityInput;
  languageCode: Scalars['String'];
}>;

export type ChangeGiftBagItemsQuantityMutation = {
  changeGiftBagItemsQuantity:
    | { __typename: 'BadInputError'; message: string }
    | {
        __typename: 'ChangeGiftBagItemsQuantityPayload';
        giftBag: { __typename?: 'GiftBag' } & GiftBagFragment;
        changed: Array<{
          __typename?: 'ReservationChange';
          itemNo: string;
          quantity: number;
        }>;
        failed: Array<{
          __typename?: 'FailedReservation';
          itemNo: string;
          quantity: number;
        }>;
      }
    | { __typename: 'NotFoundError'; message: string }
    | { __typename: 'PermissionError'; message: string };
};

export type RemoveGiftBagItemsMutationVariables = Exact<{
  removeGiftBagItemsInput: RemoveGiftBagItemsInput;
  languageCode: Scalars['String'];
}>;

export type RemoveGiftBagItemsMutation = {
  removeGiftBagItems:
    | { __typename: 'NotFoundError'; message: string }
    | { __typename: 'PermissionError'; message: string }
    | {
        __typename: 'RemoveGiftBagItemsPayload';
        giftBag: { __typename?: 'GiftBag' } & GiftBagFragment;
      };
};

export type DeleteGiftBagMutationVariables = Exact<{
  deleteGiftBagInput: DeleteGiftBagInput;
}>;

export type DeleteGiftBagMutation = {
  deleteGiftBag:
    | { __typename: 'DeleteGiftBagPayload' }
    | { __typename: 'NotFoundError'; message: string }
    | { __typename: 'PermissionError'; message: string };
};

export type SetGiverEmailMutationVariables = Exact<{
  setGiverEmailInput: SetGiverEmailInput;
  languageCode: Scalars['String'];
}>;

export type SetGiverEmailMutation = {
  setGiverEmail:
    | { __typename: 'BadInputError'; message: string }
    | { __typename: 'NotFoundError'; message: string }
    | { __typename: 'PermissionError'; message: string }
    | {
        __typename: 'SetGiverEmailPayload';
        giftBag: { __typename?: 'GiftBag' } & GiftBagFragment;
      };
};

export type MarketConfigQueryVariables = Exact<{ [key: string]: never }>;

export type MarketConfigQuery = {
  marketConfig: {
    __typename: 'MarketConfigPayload';
    marketConfig: {
      __typename?: 'MarketConfig';
      features: Array<Feature>;
      registryInput: {
        __typename?: 'MarketInputConfig';
        allowFreeText: boolean;
      };
    };
  };
};

export type RemoveCoOwnerMutationVariables = Exact<{
  removeCoOwnerInput: RemoveCoOwnerInput;
  languageCode: Scalars['String'];
}>;

export type RemoveCoOwnerMutation = {
  removeCoOwner:
    | { __typename: 'NotFoundError'; message: string }
    | { __typename: 'PermissionError'; message: string }
    | {
        __typename: 'RemoveCoOwnerPayload';
        registry: { __typename?: 'Registry' } & RegistryFragment;
      };
};

export type HomeDeliveryFragment = {
  __typename?: 'HomeDelivery';
  registryId: string;
  enabled: boolean;
  slots: Array<{
    __typename?: 'HomeDeliverySlot';
    timesOfDay: Array<TimeOfDay>;
    daysOfWeek: Array<Day>;
  }>;
  deliveryAddress?: Maybe<{
    __typename?: 'DeliveryAddress';
    mobileNumber: string;
    streetAddress1: string;
    streetAddress2?: Maybe<string>;
    streetAddress3?: Maybe<string>;
    city: string;
    postalCode: string;
    stateCode?: Maybe<string>;
    county?: Maybe<string>;
  }>;
};

export type HomeDeliveryQueryVariables = Exact<{
  homeDeliveryInput: HomeDeliveryInput;
}>;

export type HomeDeliveryQuery = {
  homeDelivery:
    | {
        __typename: 'HomeDeliveryPayload';
        homeDelivery?: Maybe<
          { __typename?: 'HomeDelivery' } & HomeDeliveryFragment
        >;
      }
    | { __typename: 'NotFoundError' };
};

export type DisableHomeDeliveryMutationVariables = Exact<{
  disableHomeDeliveryInput: DisableHomeDeliveryInput;
}>;

export type DisableHomeDeliveryMutation = {
  disableHomeDelivery:
    | {
        __typename: 'DisableHomeDeliveryPayload';
        homeDelivery?: Maybe<
          { __typename?: 'HomeDelivery' } & HomeDeliveryFragment
        >;
      }
    | { __typename: 'NotFoundError'; message: string }
    | { __typename: 'PermissionError'; message: string };
};

export type CustomerIdentifierSearchQueryVariables = Exact<{
  identifier: Scalars['String'];
}>;

export type CustomerIdentifierSearchQuery = {
  customerIdentifierSearch:
    | {
        __typename: 'CustomerIdentifierSearchResultPayload';
        items: Array<{
          __typename?: 'CustomerInfo';
          partyUId?: Maybe<string>;
          email?: Maybe<string>;
          cardNumber?: Maybe<string>;
          firstName?: Maybe<string>;
          lastName?: Maybe<string>;
        }>;
      }
    | { __typename: 'PermissionError' };
};

export type CustomerDataQueryVariables = Exact<{
  customerDataInput: CustomerDataInput;
}>;

export type CustomerDataQuery = {
  customerData: { __typename: 'CustomerDataPayload'; text?: Maybe<string> };
};

export const PriceFragmentDoc = gql`
  fragment price on Price {
    currencyCode
    exclTax
    inclTax
    indicativeTotalTax
  }
`;
export const ImageFragmentDoc = gql`
  fragment image on Image {
    alt
    variant
    height
    width
    url
  }
`;
export const ProductFragmentDoc = gql`
  fragment product on Product {
    itemNo
    productNameGlobal
    productName
    productType
    classFilters {
      id
      name
      values {
        id
        name
      }
    }
    measurements {
      metric
      imperial
    }
    images {
      ...image
    }
  }
  ${ImageFragmentDoc}
`;
export const RegistryWishlistFragmentDoc = gql`
  fragment registryWishlist on Registry {
    wishlist(languageCode: $languageCode) {
      itemNo
      totalQuantity
      purchasedQuantity
      reservedQuantity
      availableQuantity
      price {
        ...price
      }
      product {
        ...product
      }
    }
  }
  ${PriceFragmentDoc}
  ${ProductFragmentDoc}
`;
export const RegistryFragmentDoc = gql`
  fragment registry on Registry {
    id
    eventType
    title
    description
    eventDate
    shared
    shareId
    suspended
    stockImage
    owner
    coOwners {
      id
      name
    }
    ...registryWishlist
  }
  ${RegistryWishlistFragmentDoc}
`;
export const RegistryNoProductFragmentDoc = gql`
  fragment registryNoProduct on Registry {
    id
    eventType
    title
    description
    eventDate
    shared
    shareId
    stockImage
    wishlist(languageCode: $languageCode) {
      itemNo
      totalQuantity
      purchasedQuantity
      reservedQuantity
      availableQuantity
    }
  }
`;
export const AuditLogEventFragmentDoc = gql`
  fragment auditLogEvent on AuditLogEvent {
    action
    fields {
      name
      value
    }
    items(languageCode: $languageCode) {
      itemNo
      quantity
      productName
    }
    entity {
      type
      registryId
      giftBagId
    }
    user {
      type
      id @include(if: $shouldFetchId)
    }
    timestamp
    registryTitle
  }
`;
export const RegistryInfoFragmentDoc = gql`
  fragment registryInfo on RegistryInfo {
    eventDate
    eventType
    id
    nbrItems
    title
  }
`;
export const CustomerInfoFragmentDoc = gql`
  fragment customerInfo on CustomerInfo {
    partyUId
    firstName
    lastName
    birthDate
    email
    phone
    cardNumber
  }
`;
export const StatsFragmentDoc = gql`
  fragment stats on Stats {
    registries {
      created
      createdByType {
        BABY_SHOWER
        BIRTHDAY
        GRADUATION
        HOUSEWARMING
        BACK_TO_COLLEGE
        OTHER
        WEDDING
      }
    }
    giftBags {
      purchased
    }
    lastUpdated
  }
`;
export const GiftBagGiftsFragmentDoc = gql`
  fragment giftBagGifts on GiftBag {
    gifts(languageCode: $languageCode) {
      itemNo
      quantity
      product {
        ...product
      }
    }
  }
  ${ProductFragmentDoc}
`;
export const GiftBagFragmentDoc = gql`
  fragment giftBag on GiftBag {
    id
    registryId
    message
    giverName
    state
    email
    orderNumber
    purchaseDate
    ...giftBagGifts
  }
  ${GiftBagGiftsFragmentDoc}
`;
export const HomeDeliveryFragmentDoc = gql`
  fragment homeDelivery on HomeDelivery {
    registryId
    enabled
    slots {
      timesOfDay
      daysOfWeek
    }
    deliveryAddress {
      mobileNumber
      streetAddress1
      streetAddress2
      streetAddress3
      city
      postalCode
      stateCode
      county
    }
  }
`;
export const AuditLogDocument = gql`
  query auditLog(
    $input: AuditLogInput!
    $languageCode: String!
    $shouldFetchId: Boolean!
  ) {
    auditLog(input: $input) {
      __typename
      ... on Error {
        message
      }
      ... on AuditLogPayload {
        events {
          ...auditLogEvent
        }
        nextPageCursor
        totalEvents
        truncated
      }
    }
  }
  ${AuditLogEventFragmentDoc}
`;

/**
 * __useAuditLogQuery__
 *
 * To run a query within a React component, call `useAuditLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuditLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuditLogQuery({
 *   variables: {
 *      input: // value for 'input'
 *      languageCode: // value for 'languageCode'
 *      shouldFetchId: // value for 'shouldFetchId'
 *   },
 * });
 */
export function useAuditLogQuery(
  baseOptions: Apollo.QueryHookOptions<AuditLogQuery, AuditLogQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuditLogQuery, AuditLogQueryVariables>(
    AuditLogDocument,
    options
  );
}
export function useAuditLogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuditLogQuery,
    AuditLogQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuditLogQuery, AuditLogQueryVariables>(
    AuditLogDocument,
    options
  );
}
export type AuditLogQueryHookResult = ReturnType<typeof useAuditLogQuery>;
export type AuditLogLazyQueryHookResult = ReturnType<
  typeof useAuditLogLazyQuery
>;
export type AuditLogQueryResult = Apollo.QueryResult<
  AuditLogQuery,
  AuditLogQueryVariables
>;
export const RegistrySearchDocument = gql`
  query registrySearch($input: RegistrySearchInput!) {
    registrySearch(input: $input) {
      __typename
      ... on RegistrySearchResultPayload {
        items {
          ...registryInfo
        }
        nextPageCursor
      }
    }
  }
  ${RegistryInfoFragmentDoc}
`;

/**
 * __useRegistrySearchQuery__
 *
 * To run a query within a React component, call `useRegistrySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrySearchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegistrySearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegistrySearchQuery,
    RegistrySearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RegistrySearchQuery, RegistrySearchQueryVariables>(
    RegistrySearchDocument,
    options
  );
}
export function useRegistrySearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegistrySearchQuery,
    RegistrySearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RegistrySearchQuery, RegistrySearchQueryVariables>(
    RegistrySearchDocument,
    options
  );
}
export type RegistrySearchQueryHookResult = ReturnType<
  typeof useRegistrySearchQuery
>;
export type RegistrySearchLazyQueryHookResult = ReturnType<
  typeof useRegistrySearchLazyQuery
>;
export type RegistrySearchQueryResult = Apollo.QueryResult<
  RegistrySearchQuery,
  RegistrySearchQueryVariables
>;
export const RegistryStatsDocument = gql`
  query registryStats {
    registryStats {
      __typename
      ... on RegistryStatsPayload {
        statsTotal {
          ...stats
        }
        statsLast30 {
          ...stats
        }
      }
    }
  }
  ${StatsFragmentDoc}
`;

/**
 * __useRegistryStatsQuery__
 *
 * To run a query within a React component, call `useRegistryStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistryStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistryStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegistryStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RegistryStatsQuery,
    RegistryStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RegistryStatsQuery, RegistryStatsQueryVariables>(
    RegistryStatsDocument,
    options
  );
}
export function useRegistryStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegistryStatsQuery,
    RegistryStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RegistryStatsQuery, RegistryStatsQueryVariables>(
    RegistryStatsDocument,
    options
  );
}
export type RegistryStatsQueryHookResult = ReturnType<
  typeof useRegistryStatsQuery
>;
export type RegistryStatsLazyQueryHookResult = ReturnType<
  typeof useRegistryStatsLazyQuery
>;
export type RegistryStatsQueryResult = Apollo.QueryResult<
  RegistryStatsQuery,
  RegistryStatsQueryVariables
>;
export const GetRegistryDocument = gql`
  query getRegistry($input: RegistryInput!, $languageCode: String!) {
    registry(input: $input) {
      __typename
      ... on Error {
        message
      }
      ... on RegistryPayload {
        registry {
          ...registry
        }
      }
    }
  }
  ${RegistryFragmentDoc}
`;

/**
 * __useGetRegistryQuery__
 *
 * To run a query within a React component, call `useGetRegistryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegistryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegistryQuery({
 *   variables: {
 *      input: // value for 'input'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useGetRegistryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRegistryQuery,
    GetRegistryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRegistryQuery, GetRegistryQueryVariables>(
    GetRegistryDocument,
    options
  );
}
export function useGetRegistryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRegistryQuery,
    GetRegistryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRegistryQuery, GetRegistryQueryVariables>(
    GetRegistryDocument,
    options
  );
}
export type GetRegistryQueryHookResult = ReturnType<typeof useGetRegistryQuery>;
export type GetRegistryLazyQueryHookResult = ReturnType<
  typeof useGetRegistryLazyQuery
>;
export type GetRegistryQueryResult = Apollo.QueryResult<
  GetRegistryQuery,
  GetRegistryQueryVariables
>;
export const PurchasedGiftBagsDocument = gql`
  query purchasedGiftBags(
    $input: PurchasedGiftBagsInput!
    $languageCode: String!
  ) {
    purchasedGiftBags(input: $input) {
      __typename
      ... on PurchasedGiftBagsPayload {
        giftBags {
          ...giftBag
        }
      }
    }
  }
  ${GiftBagFragmentDoc}
`;

/**
 * __usePurchasedGiftBagsQuery__
 *
 * To run a query within a React component, call `usePurchasedGiftBagsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchasedGiftBagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchasedGiftBagsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function usePurchasedGiftBagsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PurchasedGiftBagsQuery,
    PurchasedGiftBagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PurchasedGiftBagsQuery,
    PurchasedGiftBagsQueryVariables
  >(PurchasedGiftBagsDocument, options);
}
export function usePurchasedGiftBagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PurchasedGiftBagsQuery,
    PurchasedGiftBagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PurchasedGiftBagsQuery,
    PurchasedGiftBagsQueryVariables
  >(PurchasedGiftBagsDocument, options);
}
export type PurchasedGiftBagsQueryHookResult = ReturnType<
  typeof usePurchasedGiftBagsQuery
>;
export type PurchasedGiftBagsLazyQueryHookResult = ReturnType<
  typeof usePurchasedGiftBagsLazyQuery
>;
export type PurchasedGiftBagsQueryResult = Apollo.QueryResult<
  PurchasedGiftBagsQuery,
  PurchasedGiftBagsQueryVariables
>;
export const RegistryLogDocument = gql`
  query registryLog(
    $input: RegistryLogInput!
    $languageCode: String!
    $shouldFetchId: Boolean!
  ) {
    registryLog(input: $input) {
      __typename
      ... on Error {
        message
      }
      ... on RegistryLogPayload {
        events {
          ...auditLogEvent
        }
        nextPageCursor
        totalEvents
        truncated
      }
    }
  }
  ${AuditLogEventFragmentDoc}
`;

/**
 * __useRegistryLogQuery__
 *
 * To run a query within a React component, call `useRegistryLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistryLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistryLogQuery({
 *   variables: {
 *      input: // value for 'input'
 *      languageCode: // value for 'languageCode'
 *      shouldFetchId: // value for 'shouldFetchId'
 *   },
 * });
 */
export function useRegistryLogQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegistryLogQuery,
    RegistryLogQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RegistryLogQuery, RegistryLogQueryVariables>(
    RegistryLogDocument,
    options
  );
}
export function useRegistryLogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegistryLogQuery,
    RegistryLogQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RegistryLogQuery, RegistryLogQueryVariables>(
    RegistryLogDocument,
    options
  );
}
export type RegistryLogQueryHookResult = ReturnType<typeof useRegistryLogQuery>;
export type RegistryLogLazyQueryHookResult = ReturnType<
  typeof useRegistryLogLazyQuery
>;
export type RegistryLogQueryResult = Apollo.QueryResult<
  RegistryLogQuery,
  RegistryLogQueryVariables
>;
export const RemoveRegistryDocument = gql`
  mutation removeRegistry($input: RemoveRegistryInput!) {
    removeRegistry(input: $input) {
      __typename
      ... on Error {
        message
      }
      ... on RemoveRegistryPayload {
        success
      }
    }
  }
`;
export type RemoveRegistryMutationFn = Apollo.MutationFunction<
  RemoveRegistryMutation,
  RemoveRegistryMutationVariables
>;

/**
 * __useRemoveRegistryMutation__
 *
 * To run a mutation, you first call `useRemoveRegistryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRegistryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRegistryMutation, { data, loading, error }] = useRemoveRegistryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveRegistryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveRegistryMutation,
    RemoveRegistryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveRegistryMutation,
    RemoveRegistryMutationVariables
  >(RemoveRegistryDocument, options);
}
export type RemoveRegistryMutationHookResult = ReturnType<
  typeof useRemoveRegistryMutation
>;
export type RemoveRegistryMutationResult =
  Apollo.MutationResult<RemoveRegistryMutation>;
export type RemoveRegistryMutationOptions = Apollo.BaseMutationOptions<
  RemoveRegistryMutation,
  RemoveRegistryMutationVariables
>;
export const SuspendRegistryDocument = gql`
  mutation suspendRegistry(
    $input: SuspendRegistryInput!
    $languageCode: String!
  ) {
    suspendRegistry(input: $input) {
      __typename
      ... on Error {
        message
      }
      ... on SuspendRegistryPayload {
        registry {
          ...registry
        }
      }
    }
  }
  ${RegistryFragmentDoc}
`;
export type SuspendRegistryMutationFn = Apollo.MutationFunction<
  SuspendRegistryMutation,
  SuspendRegistryMutationVariables
>;

/**
 * __useSuspendRegistryMutation__
 *
 * To run a mutation, you first call `useSuspendRegistryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuspendRegistryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suspendRegistryMutation, { data, loading, error }] = useSuspendRegistryMutation({
 *   variables: {
 *      input: // value for 'input'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useSuspendRegistryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SuspendRegistryMutation,
    SuspendRegistryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SuspendRegistryMutation,
    SuspendRegistryMutationVariables
  >(SuspendRegistryDocument, options);
}
export type SuspendRegistryMutationHookResult = ReturnType<
  typeof useSuspendRegistryMutation
>;
export type SuspendRegistryMutationResult =
  Apollo.MutationResult<SuspendRegistryMutation>;
export type SuspendRegistryMutationOptions = Apollo.BaseMutationOptions<
  SuspendRegistryMutation,
  SuspendRegistryMutationVariables
>;
export const ReopenRegistryDocument = gql`
  mutation reopenRegistry(
    $input: ReopenRegistryInput!
    $languageCode: String!
  ) {
    reopenRegistry(input: $input) {
      __typename
      ... on Error {
        message
      }
      ... on ReopenRegistryPayload {
        registry {
          ...registry
        }
      }
    }
  }
  ${RegistryFragmentDoc}
`;
export type ReopenRegistryMutationFn = Apollo.MutationFunction<
  ReopenRegistryMutation,
  ReopenRegistryMutationVariables
>;

/**
 * __useReopenRegistryMutation__
 *
 * To run a mutation, you first call `useReopenRegistryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReopenRegistryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reopenRegistryMutation, { data, loading, error }] = useReopenRegistryMutation({
 *   variables: {
 *      input: // value for 'input'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useReopenRegistryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReopenRegistryMutation,
    ReopenRegistryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReopenRegistryMutation,
    ReopenRegistryMutationVariables
  >(ReopenRegistryDocument, options);
}
export type ReopenRegistryMutationHookResult = ReturnType<
  typeof useReopenRegistryMutation
>;
export type ReopenRegistryMutationResult =
  Apollo.MutationResult<ReopenRegistryMutation>;
export type ReopenRegistryMutationOptions = Apollo.BaseMutationOptions<
  ReopenRegistryMutation,
  ReopenRegistryMutationVariables
>;
export const ChangeGiftBagItemsQuantityDocument = gql`
  mutation changeGiftBagItemsQuantity(
    $changeGiftBagItemsQuantityInput: ChangeGiftBagItemsQuantityInput!
    $languageCode: String!
  ) {
    changeGiftBagItemsQuantity(input: $changeGiftBagItemsQuantityInput) {
      __typename
      ... on Error {
        message
      }
      ... on ChangeGiftBagItemsQuantityPayload {
        giftBag {
          ...giftBag
        }
        changed {
          itemNo
          quantity
        }
        failed {
          itemNo
          quantity
        }
      }
    }
  }
  ${GiftBagFragmentDoc}
`;
export type ChangeGiftBagItemsQuantityMutationFn = Apollo.MutationFunction<
  ChangeGiftBagItemsQuantityMutation,
  ChangeGiftBagItemsQuantityMutationVariables
>;

/**
 * __useChangeGiftBagItemsQuantityMutation__
 *
 * To run a mutation, you first call `useChangeGiftBagItemsQuantityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeGiftBagItemsQuantityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeGiftBagItemsQuantityMutation, { data, loading, error }] = useChangeGiftBagItemsQuantityMutation({
 *   variables: {
 *      changeGiftBagItemsQuantityInput: // value for 'changeGiftBagItemsQuantityInput'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useChangeGiftBagItemsQuantityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeGiftBagItemsQuantityMutation,
    ChangeGiftBagItemsQuantityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeGiftBagItemsQuantityMutation,
    ChangeGiftBagItemsQuantityMutationVariables
  >(ChangeGiftBagItemsQuantityDocument, options);
}
export type ChangeGiftBagItemsQuantityMutationHookResult = ReturnType<
  typeof useChangeGiftBagItemsQuantityMutation
>;
export type ChangeGiftBagItemsQuantityMutationResult =
  Apollo.MutationResult<ChangeGiftBagItemsQuantityMutation>;
export type ChangeGiftBagItemsQuantityMutationOptions =
  Apollo.BaseMutationOptions<
    ChangeGiftBagItemsQuantityMutation,
    ChangeGiftBagItemsQuantityMutationVariables
  >;
export const RemoveGiftBagItemsDocument = gql`
  mutation removeGiftBagItems(
    $removeGiftBagItemsInput: RemoveGiftBagItemsInput!
    $languageCode: String!
  ) {
    removeGiftBagItems(input: $removeGiftBagItemsInput) {
      __typename
      ... on Error {
        message
      }
      ... on RemoveGiftBagItemsPayload {
        giftBag {
          ...giftBag
        }
      }
    }
  }
  ${GiftBagFragmentDoc}
`;
export type RemoveGiftBagItemsMutationFn = Apollo.MutationFunction<
  RemoveGiftBagItemsMutation,
  RemoveGiftBagItemsMutationVariables
>;

/**
 * __useRemoveGiftBagItemsMutation__
 *
 * To run a mutation, you first call `useRemoveGiftBagItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGiftBagItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGiftBagItemsMutation, { data, loading, error }] = useRemoveGiftBagItemsMutation({
 *   variables: {
 *      removeGiftBagItemsInput: // value for 'removeGiftBagItemsInput'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useRemoveGiftBagItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveGiftBagItemsMutation,
    RemoveGiftBagItemsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveGiftBagItemsMutation,
    RemoveGiftBagItemsMutationVariables
  >(RemoveGiftBagItemsDocument, options);
}
export type RemoveGiftBagItemsMutationHookResult = ReturnType<
  typeof useRemoveGiftBagItemsMutation
>;
export type RemoveGiftBagItemsMutationResult =
  Apollo.MutationResult<RemoveGiftBagItemsMutation>;
export type RemoveGiftBagItemsMutationOptions = Apollo.BaseMutationOptions<
  RemoveGiftBagItemsMutation,
  RemoveGiftBagItemsMutationVariables
>;
export const DeleteGiftBagDocument = gql`
  mutation deleteGiftBag($deleteGiftBagInput: DeleteGiftBagInput!) {
    deleteGiftBag(input: $deleteGiftBagInput) {
      __typename
      ... on Error {
        message
      }
    }
  }
`;
export type DeleteGiftBagMutationFn = Apollo.MutationFunction<
  DeleteGiftBagMutation,
  DeleteGiftBagMutationVariables
>;

/**
 * __useDeleteGiftBagMutation__
 *
 * To run a mutation, you first call `useDeleteGiftBagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGiftBagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGiftBagMutation, { data, loading, error }] = useDeleteGiftBagMutation({
 *   variables: {
 *      deleteGiftBagInput: // value for 'deleteGiftBagInput'
 *   },
 * });
 */
export function useDeleteGiftBagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGiftBagMutation,
    DeleteGiftBagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteGiftBagMutation,
    DeleteGiftBagMutationVariables
  >(DeleteGiftBagDocument, options);
}
export type DeleteGiftBagMutationHookResult = ReturnType<
  typeof useDeleteGiftBagMutation
>;
export type DeleteGiftBagMutationResult =
  Apollo.MutationResult<DeleteGiftBagMutation>;
export type DeleteGiftBagMutationOptions = Apollo.BaseMutationOptions<
  DeleteGiftBagMutation,
  DeleteGiftBagMutationVariables
>;
export const SetGiverEmailDocument = gql`
  mutation setGiverEmail(
    $setGiverEmailInput: SetGiverEmailInput!
    $languageCode: String!
  ) {
    setGiverEmail(input: $setGiverEmailInput) {
      __typename
      ... on Error {
        message
      }
      ... on SetGiverEmailPayload {
        giftBag {
          ...giftBag
        }
      }
    }
  }
  ${GiftBagFragmentDoc}
`;
export type SetGiverEmailMutationFn = Apollo.MutationFunction<
  SetGiverEmailMutation,
  SetGiverEmailMutationVariables
>;

/**
 * __useSetGiverEmailMutation__
 *
 * To run a mutation, you first call `useSetGiverEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetGiverEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setGiverEmailMutation, { data, loading, error }] = useSetGiverEmailMutation({
 *   variables: {
 *      setGiverEmailInput: // value for 'setGiverEmailInput'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useSetGiverEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetGiverEmailMutation,
    SetGiverEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetGiverEmailMutation,
    SetGiverEmailMutationVariables
  >(SetGiverEmailDocument, options);
}
export type SetGiverEmailMutationHookResult = ReturnType<
  typeof useSetGiverEmailMutation
>;
export type SetGiverEmailMutationResult =
  Apollo.MutationResult<SetGiverEmailMutation>;
export type SetGiverEmailMutationOptions = Apollo.BaseMutationOptions<
  SetGiverEmailMutation,
  SetGiverEmailMutationVariables
>;
export const MarketConfigDocument = gql`
  query marketConfig {
    marketConfig {
      __typename
      ... on MarketConfigPayload {
        marketConfig {
          registryInput {
            allowFreeText
          }
          features
        }
      }
    }
  }
`;

/**
 * __useMarketConfigQuery__
 *
 * To run a query within a React component, call `useMarketConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useMarketConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MarketConfigQuery,
    MarketConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MarketConfigQuery, MarketConfigQueryVariables>(
    MarketConfigDocument,
    options
  );
}
export function useMarketConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MarketConfigQuery,
    MarketConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MarketConfigQuery, MarketConfigQueryVariables>(
    MarketConfigDocument,
    options
  );
}
export type MarketConfigQueryHookResult = ReturnType<
  typeof useMarketConfigQuery
>;
export type MarketConfigLazyQueryHookResult = ReturnType<
  typeof useMarketConfigLazyQuery
>;
export type MarketConfigQueryResult = Apollo.QueryResult<
  MarketConfigQuery,
  MarketConfigQueryVariables
>;
export const RemoveCoOwnerDocument = gql`
  mutation removeCoOwner(
    $removeCoOwnerInput: RemoveCoOwnerInput!
    $languageCode: String!
  ) {
    removeCoOwner(input: $removeCoOwnerInput) {
      __typename
      ... on RemoveCoOwnerPayload {
        registry {
          ...registry
        }
      }
      ... on Error {
        message
      }
    }
  }
  ${RegistryFragmentDoc}
`;
export type RemoveCoOwnerMutationFn = Apollo.MutationFunction<
  RemoveCoOwnerMutation,
  RemoveCoOwnerMutationVariables
>;

/**
 * __useRemoveCoOwnerMutation__
 *
 * To run a mutation, you first call `useRemoveCoOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCoOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCoOwnerMutation, { data, loading, error }] = useRemoveCoOwnerMutation({
 *   variables: {
 *      removeCoOwnerInput: // value for 'removeCoOwnerInput'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useRemoveCoOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveCoOwnerMutation,
    RemoveCoOwnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveCoOwnerMutation,
    RemoveCoOwnerMutationVariables
  >(RemoveCoOwnerDocument, options);
}
export type RemoveCoOwnerMutationHookResult = ReturnType<
  typeof useRemoveCoOwnerMutation
>;
export type RemoveCoOwnerMutationResult =
  Apollo.MutationResult<RemoveCoOwnerMutation>;
export type RemoveCoOwnerMutationOptions = Apollo.BaseMutationOptions<
  RemoveCoOwnerMutation,
  RemoveCoOwnerMutationVariables
>;
export const HomeDeliveryDocument = gql`
  query homeDelivery($homeDeliveryInput: HomeDeliveryInput!) {
    homeDelivery(input: $homeDeliveryInput) {
      __typename
      ... on HomeDeliveryPayload {
        homeDelivery {
          ...homeDelivery
        }
      }
    }
  }
  ${HomeDeliveryFragmentDoc}
`;

/**
 * __useHomeDeliveryQuery__
 *
 * To run a query within a React component, call `useHomeDeliveryQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeDeliveryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeDeliveryQuery({
 *   variables: {
 *      homeDeliveryInput: // value for 'homeDeliveryInput'
 *   },
 * });
 */
export function useHomeDeliveryQuery(
  baseOptions: Apollo.QueryHookOptions<
    HomeDeliveryQuery,
    HomeDeliveryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeDeliveryQuery, HomeDeliveryQueryVariables>(
    HomeDeliveryDocument,
    options
  );
}
export function useHomeDeliveryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeDeliveryQuery,
    HomeDeliveryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomeDeliveryQuery, HomeDeliveryQueryVariables>(
    HomeDeliveryDocument,
    options
  );
}
export type HomeDeliveryQueryHookResult = ReturnType<
  typeof useHomeDeliveryQuery
>;
export type HomeDeliveryLazyQueryHookResult = ReturnType<
  typeof useHomeDeliveryLazyQuery
>;
export type HomeDeliveryQueryResult = Apollo.QueryResult<
  HomeDeliveryQuery,
  HomeDeliveryQueryVariables
>;
export const DisableHomeDeliveryDocument = gql`
  mutation disableHomeDelivery(
    $disableHomeDeliveryInput: DisableHomeDeliveryInput!
  ) {
    disableHomeDelivery(input: $disableHomeDeliveryInput) {
      __typename
      ... on Error {
        message
      }
      ... on DisableHomeDeliveryPayload {
        homeDelivery {
          ...homeDelivery
        }
      }
    }
  }
  ${HomeDeliveryFragmentDoc}
`;
export type DisableHomeDeliveryMutationFn = Apollo.MutationFunction<
  DisableHomeDeliveryMutation,
  DisableHomeDeliveryMutationVariables
>;

/**
 * __useDisableHomeDeliveryMutation__
 *
 * To run a mutation, you first call `useDisableHomeDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableHomeDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableHomeDeliveryMutation, { data, loading, error }] = useDisableHomeDeliveryMutation({
 *   variables: {
 *      disableHomeDeliveryInput: // value for 'disableHomeDeliveryInput'
 *   },
 * });
 */
export function useDisableHomeDeliveryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisableHomeDeliveryMutation,
    DisableHomeDeliveryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisableHomeDeliveryMutation,
    DisableHomeDeliveryMutationVariables
  >(DisableHomeDeliveryDocument, options);
}
export type DisableHomeDeliveryMutationHookResult = ReturnType<
  typeof useDisableHomeDeliveryMutation
>;
export type DisableHomeDeliveryMutationResult =
  Apollo.MutationResult<DisableHomeDeliveryMutation>;
export type DisableHomeDeliveryMutationOptions = Apollo.BaseMutationOptions<
  DisableHomeDeliveryMutation,
  DisableHomeDeliveryMutationVariables
>;
export const CustomerIdentifierSearchDocument = gql`
  query customerIdentifierSearch($identifier: String!) {
    customerIdentifierSearch(identifier: $identifier) {
      __typename
      ... on CustomerIdentifierSearchResultPayload {
        items {
          partyUId
          email
          cardNumber
          firstName
          lastName
        }
      }
    }
  }
`;

/**
 * __useCustomerIdentifierSearchQuery__
 *
 * To run a query within a React component, call `useCustomerIdentifierSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerIdentifierSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerIdentifierSearchQuery({
 *   variables: {
 *      identifier: // value for 'identifier'
 *   },
 * });
 */
export function useCustomerIdentifierSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    CustomerIdentifierSearchQuery,
    CustomerIdentifierSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CustomerIdentifierSearchQuery,
    CustomerIdentifierSearchQueryVariables
  >(CustomerIdentifierSearchDocument, options);
}
export function useCustomerIdentifierSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomerIdentifierSearchQuery,
    CustomerIdentifierSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CustomerIdentifierSearchQuery,
    CustomerIdentifierSearchQueryVariables
  >(CustomerIdentifierSearchDocument, options);
}
export type CustomerIdentifierSearchQueryHookResult = ReturnType<
  typeof useCustomerIdentifierSearchQuery
>;
export type CustomerIdentifierSearchLazyQueryHookResult = ReturnType<
  typeof useCustomerIdentifierSearchLazyQuery
>;
export type CustomerIdentifierSearchQueryResult = Apollo.QueryResult<
  CustomerIdentifierSearchQuery,
  CustomerIdentifierSearchQueryVariables
>;
export const CustomerDataDocument = gql`
  query customerData($customerDataInput: CustomerDataInput!) {
    customerData(input: $customerDataInput) {
      __typename
      ... on CustomerDataPayload {
        text
      }
    }
  }
`;

/**
 * __useCustomerDataQuery__
 *
 * To run a query within a React component, call `useCustomerDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerDataQuery({
 *   variables: {
 *      customerDataInput: // value for 'customerDataInput'
 *   },
 * });
 */
export function useCustomerDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    CustomerDataQuery,
    CustomerDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomerDataQuery, CustomerDataQueryVariables>(
    CustomerDataDocument,
    options
  );
}
export function useCustomerDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomerDataQuery,
    CustomerDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomerDataQuery, CustomerDataQueryVariables>(
    CustomerDataDocument,
    options
  );
}
export type CustomerDataQueryHookResult = ReturnType<
  typeof useCustomerDataQuery
>;
export type CustomerDataLazyQueryHookResult = ReturnType<
  typeof useCustomerDataLazyQuery
>;
export type CustomerDataQueryResult = Apollo.QueryResult<
  CustomerDataQuery,
  CustomerDataQueryVariables
>;
