import React, { useState } from 'react';
import iconPerson from '@ingka/ssr-icon/paths/person';
import iconTrash from '@ingka/ssr-icon/paths/trash-can';
import { Registry, useRemoveCoOwnerMutation } from 'src/generated/graphql';
import { useIntl, useT } from 'src/hooks/intl';
import {
  Button,
  Modal,
  ModalHeader,
  Sheets,
  space250,
  SSRIcon,
} from 'src/skapa';
import { splitPayload } from 'src/utils/handleUnions';
import { Errors, useErrors } from './ErrorMessage';
import { Body2, Heading } from './Typography';
import { space100 } from '@ingka/variables/design-tokens';

import styled from 'styled-components';

import { Container, Title } from './styles';

type CoRegistrantsModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  registry: Registry;
};

const CoWorkerContainer = styled(Container)`
  border-bottom: 1px solid ${(props) => props.theme.colourNeutral3};
  padding: ${(props) => props.theme.space150} 0;
`;

export const CoRegistrantsModal: React.FC<CoRegistrantsModalProps> = ({
  setOpen,
  open,
  registry,
}: CoRegistrantsModalProps) => {
  const t = useT();
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const { addError, clearErrors, errorsProps } = useErrors();
  const { language } = useIntl();

  const [removeCoOwnerMutation, { loading }] = useRemoveCoOwnerMutation({
    onCompleted: (res) => {
      clearErrors();
      const [, errorKind] = splitPayload(
        'RemoveCoOwnerPayload',
        res?.removeCoOwner
      );
      if (errorKind) {
        addError(t(`error.generic.${errorKind}`));
      }
    },
  });

  return (
    <Modal visible={open} escapable handleCloseBtn={() => setOpen(false)}>
      <Sheets footer={null} header={<ModalHeader />}>
        <Title component="h2" variant="h2" marginBottom={space250}>
          {t('registry.settings.coRegistrants')}
        </Title>
        <Errors {...errorsProps} />

        {registry?.coOwners?.length > 0 ? (
          registry?.coOwners.map((coOwner) => {
            return (
              <CoWorkerContainer
                key={coOwner.id}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Container display="flex" style={{ gap: space100 }}>
                  <Heading component="h2" variant="h4">
                    <SSRIcon paths={iconPerson} />
                  </Heading>
                  <Heading component="h2" variant="h4">
                    {coOwner.name}
                  </Heading>
                </Container>
                <Container display="flex" style={{ gap: space100 }}>
                  {showDelete ? (
                    <>
                      <Button
                        small
                        text={t('cancel')}
                        type="secondary"
                        onClick={() => setShowDelete(false)}
                      />
                      <Button
                        loading={loading}
                        small
                        text={t('registry.settings.remove')}
                        type="primary"
                        onClick={() => {
                          removeCoOwnerMutation({
                            variables: {
                              removeCoOwnerInput: {
                                coOwnerId: coOwner.id,
                                registryId: registry.id,
                              },
                              languageCode: language,
                            },
                          });
                        }}
                      />
                    </>
                  ) : (
                    <Button
                      iconOnly
                      ssrIcon={iconTrash}
                      type="tertiary"
                      small
                      onClick={() => setShowDelete(true)}
                    />
                  )}
                </Container>
              </CoWorkerContainer>
            );
          })
        ) : (
          <Body2>{t('registry.coRegistrants.empty')}</Body2>
        )}
      </Sheets>
    </Modal>
  );
};
